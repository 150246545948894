import React, { useEffect } from "react";
import "../Work/Work.css";
import triangle from "../../assets/triangle.png";
import evolving from "../../assets/evolving-circle.png";
import line from "../../assets/Line.png";
import { Helmet } from "react-helmet";

export default function Work() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="IT Solutions Company for Healthcare Industry in USA & India - Dahooks" /><meta property="og:description" content="Dahooks provides healthcare IT services and solutions in india & USA. We offers best-in-class healthcare software solutions to various healthcare startups, companies, and hospitals. Get a free quote now!" /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="IT Solutions Company for Healthcare Industry in USA & India - Dahooks" /><meta name="twitter:description" content="Dahooks provides healthcare IT services and solutions in india & USA. We offers best-in-class healthcare software solutions to various healthcare startups, companies, and hospitals. Get a free quote now!" /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
        <title>Engagement Models for Software Development - Dahooks</title>
        <link rel="canonical" href="https://dahooks.com/work" />
      </Helmet>
      <div className="work-page">
        <div className="container pt-5">
          <div className="row ">
            <div className="col-12 mt-5 py-4">
              <div className="contact-banner-heading p-lg-4  mt-5">
                <h1 className="mb-0">Flexible Engagement Models</h1>
                <p>For Faster Time To Market</p>
              </div>
            </div>
          </div>
        </div>
        <div className="background pb-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="contact-banner-heading p-lg-4">
                  <h1>How do we Engage?</h1>
                  <p>Each project is unique, and therefore requires special care and attention. Along with the essential aspects of scope, cost, and time, the working relationship between the client and agency is also crucial in achieving a balance. As a result, our internal models are both flexible and client-focused, while also being robust and easy to understand.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12 my-auto">
                <div className="background-image1">
                  <img src={triangle} />
                  <h1 style={{ color: "white" }}>Fixed Time Fixed Cost</h1>
                  <p style={{ color: "white" }}>
                    We can provide you with a fixed price and estimated timeline if you have a clear project scope and story prepared.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12 my-auto">
                <div className="background-image2">
                  <img src={evolving} />
                  <h1 style={{ color: "white" }}>Effort based pricing or Time & Material</h1>
                  <p style={{ color: "white" }}>
                    Ideal for ongoing projects that require constant improvements and iterations, we assemble a highly skilled team to collaborate with you virtually as your own.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=''>
          <div className='container py-5'>
            <div className='row'>
              <div className="col-lg-5 col-12 offset-lg-1">
                <div className="service-content text-center text-lg-end">
                  <h3 style={{ color: "#7F92A9" }}>When to choose</h3>
                  <h1 >Fixed Time Fixed <br />Cost</h1>
                  <p >
                    The team and project manager are from Taction.<br /><br />
                    We oversee the project's life cycle and bear accountability for its quality and deliverables.<br /><br />
                    When the project's requirements, schedule, and scope are well-defined and are not anticipated to change during the development period, this engagement model works well.<br /><br />
                    We provide our customers with a low-risk and economical solution through our fixed Time/fixed Cost strategy. This methodology ensures projects are completed on schedule and under budget.
                  </p>
                </div>
              </div>
              <div className="col-lg-1 d-none d-lg-block my-auto pt-5">
                <img className="mt-5 pt-2" src={line} width="100%" />
                <img src={line} width="100%" />
                {/* <img src={line} width="100%" />  */}
                <img src={line} width="100%" />
              </div>
              <div className='col-lg-5 col-12'>
                <div className="service-content text-center text-lg-start">
                  <h3 style={{ color: "#7F92A9" }}>When to choose</h3>
                  <h1 >Effort based pricing or<br /> Time & Material</h1>
                  <p >
                    The effort-based pricing approach bases a project's cost on how long it takes to finish and what resources are used along the way.
                    <br /><br />
                    This method works especially well for complicated projects where specification and design modifications are anticipated to occur while the project is being carried out.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=''>
          <div className='container py-5'>
            <div className='row'>
              <div className="col-lg-5 col-12 offset-lg-1">
                <div className="service-content text-center text-lg-end">
                  <h3 style={{ color: "#7F92A9" }}>When to choose</h3>
                  <h1 >Offshore Staff<br /> Augmentation</h1>
                  <p >
                    An electronic extension of the client's workspace<br /><br />
                    Utilize our knowledge of open sources to make sure your projects are successful.<br /><br />
                    Provide the client with the necessary space, hardware, software, and communication infrastructure as well as qualified labor.<br /><br />
                    Clients oversee the project's whole life cycle and are in charge of the service's quality and deliverables.
                  </p>
                </div>
              </div>
              <div className="col-lg-1 d-none d-lg-block my-auto pt-5">
                <img className="mt-5 pt-2" src={line} width="100%" />
                <img src={line} width="100%" />
                {/* <img src={line} width="100%" />  */}
                <img src={line} width="100%" />
              </div>
              <div className='col-lg-5 col-12'>
                <div className="service-content text-center text-lg-start">
                  <h3 style={{ color: "#7F92A9" }}>When to choose</h3>
                  <h1 >Onsite Staff Augmentation (Distributed Development)</h1>
                  <p >
                    Customer engagement in project management and execution<br /><br />
                    Project life cycle shared by teams working on-site and offshore
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="background pb-5">
          <div className="container ">
            <div className="row ">
              <div className="col-lg-12 my-auto py-4">
                <div className="contact-banner-heading">
                  <h1>Development Workflow</h1>
                  <p>
                    Our system is built with flexibility at its core, allowing for seamless coordination and synchronization between different processes and individuals
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="workflow">
                  <div className="discover text-center" style={{ display: "flex", justifyContent: "center" }}>
                    <div className="my-auto">
                      <h3 style={{ color: "#DC5EF5" }}>Discover</h3>
                      <p>Brainstorming
                        <br />Hypothesis
                        <br />Conceptualization
                        <br />Assumptions
                        <br />Constraints</p>
                    </div>
                  </div>

                  <div className="define text-center" style={{ display: "flex", justifyContent: "center" }}>
                    <div className="my-auto">
                      <h3 style={{ color: "#01BBE4" }}>Define</h3>
                      <p>Req. Definition
                        <br />SRS Document
                        <br />Project Planning
                        <br />Dev. Planning
                        <br />Milestones</p>
                    </div>
                  </div>
                  <div className="design text-center" style={{ display: "flex", justifyContent: "center" }}>
                    <div className="my-auto">
                      <h3 style={{ color: "#11C361" }}>Design</h3>
                      <p>System Design
                        <br />Wireframing
                        <br />Prototyping
                        <br />UI / UX Design
                        <br />Architecture</p>
                    </div>
                  </div>

                  <div className="develop text-center" style={{ display: "flex", justifyContent: "center" }}>
                    <div className="my-auto">
                      <h3 style={{ color: "#FF9000" }}>Develop</h3>
                      <p>Coding
                        <br />APIs
                        <br />Testing / QA
                        <br />Debug
                        <br />Change Mgmt</p>
                    </div>
                  </div>
                  <div className="deliver text-center" style={{ display: "flex", justifyContent: "center" }}>
                    <div className="my-auto">
                      <h3 style={{ color: "#FF5777" }}>Deliver</h3>
                      <p>Environment
                        <br />Deployment
                        <br />Migration
                        <br />Support
                        <br />Review</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
