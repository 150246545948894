import React from 'react'
import CountUp from 'react-countup'

export default function Count() {
  return (
    <>
    <div className='container'>
        <div className='heading-text py-5 text-center'>
            Don't let your business fall behind, join the success league and let us help you too!
        </div>
        <div className='row text-center pb-5 mb-5'>
            <div className='col-md-6 col-lg-3 col-12 '>
                <div className='d-flex justify-content-center'><CountUp end={5} style={{fontSize:80,fontWeight:800}}/><div className='my-auto' style={{fontSize:80,fontWeight:800}}>+</div></div>
                <div style={{fontSize:18}}>Countries served</div>
            </div>
            <div className='col-md-6 col-lg-3 col-12 '>
                <div className='d-flex justify-content-center'><CountUp end={12} style={{fontSize:80,fontWeight:800}}/><div className='my-auto' style={{fontSize:80,fontWeight:800}}>+</div></div>
                <div style={{fontSize:18}}>Awards Won</div>
            </div>
            <div className='col-md-6 col-lg-3 col-12 '>
                <div className='d-flex justify-content-center'><CountUp end={170} style={{fontSize:80,fontWeight:800}}/><div className='my-auto' style={{fontSize:80,fontWeight:800}}>+</div></div>
                <div style={{fontSize:18}}>Satisfied Clients</div>
            </div>
            <div className='col-md-6 col-lg-3 col-12 '>
                <div className='d-flex justify-content-center'><CountUp end={400} style={{fontSize:80,fontWeight:800}}/><div className='my-auto' style={{fontSize:80,fontWeight:800}}>+</div></div>
                <div style={{fontSize:18}}>Projects</div>
            </div>
        </div>
    </div>
    </>
  )
}
