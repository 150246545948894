import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import PHPB from '../../../assets/PHP Based Healthcare System With Formulary Check Using Allscripts API.jpg'

export default function PHPBasedHealthcare() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>PHP Based Healthcare System With Formulary Check Using Allscripts API</strong></div>
                    <p className='mt-2 text-center'>The client is a Florida (USA) based Healthcare Information Technology service provider who has built a patient engagement web platform that acts as a hub for the patient and physician to communicate healthcare history, prescription drug history with active prescribing ability, and health care finances. The tools available as part of the web platform easily integrate patient and provider healthcare systems for ease of use in the complicated and ever-changing electronic medical records market.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>The client wanted to design and developed an application that checks the patient prescription, validates their drugs against insurance plan with the formulary check and suggests the nearest pharmacy with minimal input from the patient. The system was also to be capable of displaying patient demographic details with their allergy, vitals, diagnosis and medication history. It was also designed to build communication channel between patient and doctor to discuss post appointment procedures.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>PHP, ZEND Framework (ZF2), MySQL</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={PHPB} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We proposed a solution that requires minimal key-in from patients and maximum retrieval of information out of the Intelligent Information Management System. Dahooks designed and developed a solution based on PHP/ MySQL using Zend Framework (ZF2) and integrated with third-party applications and database through APIs from Allscripts, GoodRx, PokitDok etc.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The technology platform has improved the Doctors/ Providers and Patients collaboration, effective medication and patient care and patient/ doctor/ pharmacy collaborations for better prescription dispensing and filling/ refilling.</p>
                </div>
            </div>
        </div>
    </>
  )
}
