import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import DataA from '../../../assets/Data-Analytics.jpg'

export default function DataAnalytics() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Data Analytics and Trending through Tableau Dashboard Creation</strong></div>
                    <p className='mt-2 text-center'>The client delivers energy independence for households and small businesses by supplying and implementing the system for clean, reliable electricity through Solar PV system.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>The client was looking for services to create Tableau Dashboard for the domain Installation Pending report, DP SKU report, Portfolio Health Status Report for making informed customer-centric business decisions.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>Tableau</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={DataA} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We created the Tableau Dashboards by integrating MySQL and PostgreSQL into a single workbook/dashboard to get a better data analysis. Previously the client was using pivot tables to display the target data which was altogether a time consuming and tedious process and we built appropriate data scripts which reduced the reporting and decision-making time drastically.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The solution offered the sales and service team better visibility of meaning full data and predictability into schedules and actions. It also helped the client in saving time by obliterating duplicate data entry points and manual report generation process.</p>
                </div>
            </div>
        </div>
    </>
  )
}
