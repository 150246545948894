import React from 'react'
import '../speak-about/speak.css'
import CardImg from '../../assets/card-image.jpg'
import Coming from '../../assets/coming-soon.jpg'

export default function Speak() {
  return (
    <>
    {/* <div className='container pt-5'>
        <div className='row'>
            <div className='case-heading'>Projects that speak about our<br className='d-none d-lg-block'/>versatility</div>
            <div className='case-text pt-4 pb-5'>We’re passionate about what we do! Our portfolio is a sheer presentation about 20+ years of industry <br className='d-none d-lg-block'/>experience, collaboration, expertise, and quality deliverables.</div>
        </div>
        <div className='row'>
            <div className='d-flex justify-content-between case-tabs border-bottom'>
                <ul className='d-flex p-0 mb-0'>
                    <li className='case-link text-center py-2' style={{paddingLeft:0}}><a href='#'>Popular Brands</a></li>
                    <li className='case-link text-center py-2'><a href='#/case'>Case Studies</a></li>
                    <li className='case-link text-center py-2 border-btm'><a href='#'>Portfolio</a></li>
                </ul>
                <ul className='d-flex p-0 mb-0'>
                    <li className='case-link pt-1'>
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Type
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className=' pt-md-1'>
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Technology
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div className='row pt-2 pt-md-5'>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='row pt-2 pt-md-5'>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='row pt-2 pt-md-5'>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='row pt-2 pt-md-5 mb-5'>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CardImg} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>ECOMMERCE</div>
                        <h5 className="card-title">HomzMart</h5>
                        <p className="card-text">HomzMart is an Arabic marketplace website and cross-platform mobile app.......</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>Magento</div>
                            <div className='py-1 px-2 disble-btn'>My SQL</div>
                            <div className='py-1 px-2 disble-btn'>PHP</div>
                            <div className='py-1 px-2 disble-btn'>React Native</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-12 mt-5 pt-3 px-0'>
                <img src={Coming} width={"100%"}/>
            </div>
        </div>
    </div>
    </>
  )
}
