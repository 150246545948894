import React,{useEffect} from "react";
import "../digital-commerce/digital-commerce.css";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Technology_Integration from "../../assets/Technology Integration.jpg";
import Improved_Efficiency from "../../assets/Improved Efficiency and Productivity.png";
import Enhanced_Customer from "../../assets/Enhanced Customer Experience.png";
import Cost_Savings from "../../assets/Cost Savings.jpg";
import Teamwork from "../../assets/teamwork.png";
import Flexibility from "../../assets/flexibility.png";
import Decision_Making from "../../assets/decision-making.png";
import crm from "../../assets/CRM_IMG.jpg";
import Markting_Automation from "../../assets/Markting Automation.jpg";
import Payment_Gateway from "../../assets/Payment gateway.jpg";
import HRM from "../../assets/HRM.jpg";
import Business_Automation from "../../assets/Business-Automation.jpg";
import Cloud_Infrastructure from "../../assets/Cloud Infrastructure.jpg";
import Social_Media from "../../assets/Social Media.jpg";
import IOT from "../../assets/IOt.jpg";
import ERP_IMG from "../../assets/ERP_IMG.jpg";
import SCM_IMG from "../../assets/SCM_IMG.jpg";
import Health from "../../assets/Healthcare.png";
import Legal from "../../assets/Legal.png";
import Education from "../../assets/Education.png";
import Finance from "../../assets/Finance.png";
import MobInsurance from "../../assets/MobInsurance.png";
import CRM_Education from "../../assets/CRM_Education.png";
import CRM_Finance from "../../assets/CRM_Finance.png";
import Dot_Net from '../../assets/Dot_Net.png'
import CRM_Health from '../../assets/CRM_Health.png'
import CRM_Publication from '../../assets/CRM_INV.png'
import CRM_ETL from '../../assets/CRM_ETL.png'
import CRM_Finance1 from '../../assets/CRM_Finance1.png'
import CRM_Inventory from '../../assets/CRM_Inventory.png'
import {Helmet} from "react-helmet";

// Define the AccordionItem component
const AccordionItem = ({ title, content, index, openIndex, setOpenIndex }) => {
  const isOpen = index === openIndex;

  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index);
  };
  return (
    <div className="accordion-item">
      <div
        className="accordion-title"
        type="text"
        onClick={toggleAccordion}
        style={{ fontSize: "18px", cursor: "pointer" }}
      >
        <strong>{isOpen ? "- " : "+ "}</strong> <strong>{title}</strong>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
};



export default function Digital_Commerce() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const [openIndex, setOpenIndex] = React.useState(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="IT System Integration Services | Technology Integration - Dahooks" /><meta property="og:description" content="Integration Services for Information Technology by Dahooks transform your business with technology integration and lead confidently. Connect Now to Get the Potential of IT System Integration Services." /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="IT System Integration Services | Technology Integration - Dahooks" /><meta name="twitter:description" content="Integration Services for Information Technology by Dahooks transform your business with technology integration and lead confidently. Connect Now to Get the Potential of IT System Integration Services." /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
        <title>IT System Integration Services | Technology Integration - Dahooks</title>
        <link rel="canonical" href="https://dahooks.com/Technology-Integration" />
      </Helmet>
      <div className="container pt-5">
        <div className="row ">
          <div className="col-lg-12 mt-5 py-4">
            <div className="contact-banner-heading p-lg-4  mt-5">
              <h1 style={{ color: "#f4990b" }}>
                Integration Services for Information Technology
              </h1>
              <div className="headings" style={{ fontSize: "28px",textAlign: "center",lineHeight: "70px",}}>
                Transform your business with technology integration and lead confidently.
              </div>
              <p>
                It is the process of linking two or more technologies so that they may interact and exchange information between systems. 
                It entails the creation of software and middleware to bridge the gap between disparate technologies, such as old and newer, more modern systems.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <img src={Technology_Integration} width="100%" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 my-auto py-4">
            <div className="contact-banner-heading  p-lg-4  mt-4 ">
              <div className="row">
                <h1>Why is technology integration important.</h1>
                <p>In today's world, organizations employ several systems of varying sizes, but they work in silos and lack business context from other systems used by the same business. Improved efficiency.</p>
              </div>
              <div className="row mt-5">
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Improved_Efficiency} width="60px"/>
                  <h3 className="mt-2">
                    Enhanced Productivity.
                  </h3>
                  <div>
                    <p style={{ textAlign: "left",textAlign:"justify" }}>
                      Businesses can boost productivity by automating operations and removing manual involvement through the integration of numerous systems.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Enhanced_Customer} width="60px"/>
                  <h3 className="mt-2">
                    Reduced errors
                  </h3>
                  <div>
                    <p style={{ textAlign: "left",textAlign:"justify" }}>
                      Technology integration decreases the likelihood of errors caused by manual intervention or data re-entry, improving accuracy.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Teamwork} width="60px"/>
                  <h3 className="mt-2">
                    Improved Cooperation
                  </h3>
                  <div>
                    <p style={{ textAlign: "left",textAlign:"justify" }}>
                      Technology integration enables diverse systems to communicate and exchange information in a smooth manner, resulting in improved collaboration among various departments or teams within a company.
                    </p>
                  </div>
                </div>
                
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                <img src={Cost_Savings} width="60px"/>
                  <h3 className="mt-2">
                    Cost Savings
                  </h3>
                  <div>
                    <p style={{ textAlign: "left",textAlign:"justify" }}>
                      Technology integration can reduce the need for manual labor and streamline processes, resulting in cost savings.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                <img src={Flexibility} width="60px"/>
                  <h3 className="mt-2">
                    Increased flexibility
                  </h3>
                  <div>
                    <p style={{ textAlign: "left",textAlign:"justify" }}>
                      Integrated systems are more easily adjusted and modified, allowing firms to respond to changing market conditions and client demands.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                <img src={Decision_Making} width="60px"/>
                  <h3 className="mt-2">
                   Better Ability to Make Decisions
                  </h3>
                  <div>
                    <p style={{ textAlign: "left",textAlign:"justify" }}>
                      Integrated systems provide a more complete and accurate picture of business activities, enabling improved decision-making and strategic planning.
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

        <div className='container py-5'>
            <div className='row'>
                <div className="col-lg-6 col-12"  >
                      <div className="tagline">
                        <p style={{color:"#f4990b"}}>INTEGRATION STRATEGY</p>
                       <h1> <strong>Key steps that a corporation should consider while planning and designing an integration strategy.</strong></h1>
                        </div>
                 
                </div>
                <div className='col-lg-6 col-12'>
                <div className="service-content" >
                <p><strong>Establish Business Goals</strong></p>
                <p>Define specific business objectives and targets that the integration should help achieve.</p>

                <p><strong>Determine the Sources of the Data.</strong></p>
                <p>Identify all data sources, applications, and the type and format of data to be shared.</p>

                <p><strong>Evaluate the Needs for Integration.</strong></p>
                <p>Evaluate integration requirements, such as security, scalability, and performance.</p>

                <p><strong>Choose the Integration Approach.</strong></p>
                <p>Based on the needs, select the best integration strategy, such as point-to-point, ESB, or API.</p>

                
                <p><strong>Develop Integration Plan</strong></p>
                <p>Create a clear integration plan with deadlines, resources, and milestones.</p>

                <p><strong>Implement Integration.</strong></p>
                <p>Implement the integration strategy, which includes any required development, testing, and deployment.</p>

                <p><strong>Monitor and maintain integration.</strong></p>
                <p>Monitor the integration for any problems or faults, and keep it running to ensure it meets business objectives</p>

                <p><strong>Evaluate integration success.</strong></p>
                <p>Analyze the integration's performance using metrics like ROI and user feedback, and make any required modifications.</p>                       
                    </div>
                </div>
            </div>
        </div>
        <div className="container py-5">
          <div className="row">
          <div className="col-lg-12 my-auto py-4">
            <div className="contact-banner-heading p-lg-4  mt-4">
              <p style={{ color: "#f4990b" }}>
                USE CASES
              </p>
              <h1>
                Examining the Advantages and Applications of Technology and Business Integration
              </h1>
            </div>
          </div>
          {/* web */}
          <div className="row d-none d-lg-block">
          <Slider {...settings3}>
            <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={crm} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Customer Relationship Management (CRM)</h3>
              <div className="service-points">
                <ul>
                  <li>Allows for a seamless movement of customer data between systems.</li>
                  <li>Helps to improve the consumer experience and decision-making.</li>
                  <li>Personalizes marketing tactics and boosts customer engagement.</li>
                  <li>Streamlines customer service and shortens response times.</li>
                </ul>
              </div>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={ERP_IMG} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Enterprise Resource Planning (ERP)</h3>
              <div className="service-points">
                <ul>
                  <li>Allows data to move seamlessly between systems including accounting, inventories, sales, and human resources.</li>
                  <li>Helps to streamline corporate operations and increase efficiency.</li>
                  <li>Provides real-time visibility into business operations and decision-making.</li>
                  <li>Increases data accuracy and minimizes human data entry errors.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={SCM_IMG} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Supply Chain Management (SCM)</h3>
              <div className="service-points">
                <ul>
                  <li>permits the smooth exchange of data between manufacturers, suppliers, and retailers.</li>
                  <li>enhances the responsiveness and visibility of the supply chain</li>
                  <li>improves demand forecasts and inventory management</li>
                  <li>aids in cutting lead times and raising the caliber of products</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Markting_Automation} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Marketing Automation</h3>
              <div className="service-points">
                <ul>
                  <li>Allows for smooth integration of data between marketing and sales platforms</li>
                  <li>Helps improve lead generation and nurturing.</li>
                  <li>Enables personalized and targeted marketing initiatives.</li>
                  <li>Enables personalized and targeted marketing initiatives.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Payment_Gateway} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Payment Gateway Integration</h3>
              <div className="service-points">
                <ul>
                  <li>Allows for simple integration of payment gateways into e-commerce websites.</li>
                  <li>Helps to improve the client checkout experience.</li>
                  <li>Enables faster and more secure payment processing.</li>
                  <li>Helps to reduce cart abandonment rates.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={HRM} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Human Resource Management (HRM)</h3>
              <div className="service-points">
                <ul>
                  <li>permits the smooth exchange of data between manufacturers, suppliers, and retailers.</li>
                  <li>enhances employee retention and engagement.</li>
                  <li>Facilitates improved workforce planning and management.</li>
                  <li>Increases conformity with regulatory regulations.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Business_Automation} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Business Intelligence (BI)</h3>
              <div className="service-points">
                <ul>
                  <li>makes it possible for data to be seamlessly integrated into a central data warehouse from multiple sources.</li>
                  <li>enhances the reporting and analysis of data.</li>
                  <li>allows for the real-time tracking of KPIs and business performance.</li>
                  <li>facilitates the use of data to make judgments.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
            <img src={Cloud_Infrastructure} alt ='' width="100%"/>
            <h3 className="mt-2" style={{textAlign:"left"}}>Cloud Infrastructure</h3>
              <div className="service-points">
                <ul>
                  <li>Enables the smooth integration of cloud-based systems and applications.</li>
                  <li>Helps to reduce IT infrastructure costs.</li>
                  <li>Facilitates easy scaling of IT systems.</li>
                  <li>Enhances data security and catastrophe recovery.</li>
                </ul>
              </div>
         </div>
          </div>
          <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
          <div className="slide" style={{width:"18rem"}}> 
            <img src={Social_Media} alt ='' width="100%"/>
            <h3 className="mt-2" style={{textAlign:"left"}}>Social Media</h3>
              <div className="service-points">
                <ul>
                  <li>It enables the seamless integration of social media networks with marketing and CRM systems.</li>
                  <li>Helps to increase social media engagement and brand recognition.</li>
                  <li>It enables targeted social media advertising.</li>
                  <li>Enhances social media analytics and reporting.</li>
                </ul>
              </div>
         </div>
          </div>
          <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
          <div className="slide" style={{width:"18rem"}}>
            <img src={IOT} alt ='' width="100%"/>
            <h3 className="mt-2" style={{textAlign:"left"}}>Internet of Things (IoT)</h3>
              <div className="service-points">
                <ul>
                  <li>Allows for smooth integration of IoT devices with business systems and applications.</li>
                  <li>Helps to improve asset management and predictive maintenance.</li>
                  <li>Provides real-time monitoring of business operations and performance.</li>
                  <li>Helps to reduce downtime and improve product quality.</li>
                </ul>
              </div>
         </div>
          </div>
            </Slider>
          </div>

          <div className="row d-none d-md-block d-lg-none">
          <Slider {...settings}>
          <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={crm} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Customer Relationship Management (CRM)</h3>
              <div className="service-points">
                <ul>
                  <li>Allows for a seamless movement of customer data between systems.</li>
                  <li>Helps to improve the consumer experience and decision-making.</li>
                  <li>Personalizes marketing tactics and boosts customer engagement.</li>
                  <li>Streamlines customer service and shortens response times.</li>
                </ul>
              </div>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={ERP_IMG} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Enterprise Resource Planning (ERP)</h3>
              <div className="service-points">
                <ul>
                  <li>Allows data to move seamlessly between systems including accounting, inventories, sales, and human resources.</li>
                  <li>Helps to streamline corporate operations and increase efficiency.</li>
                  <li>Provides real-time visibility into business operations and decision-making.</li>
                  <li>Increases data accuracy and minimizes human data entry errors.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={SCM_IMG} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Supply Chain Management (SCM)</h3>
              <div className="service-points">
                <ul>
                  <li>permits the smooth exchange of data between manufacturers, suppliers, and retailers.</li>
                  <li>enhances the responsiveness and visibility of the supply chain</li>
                  <li>improves demand forecasts and inventory management</li>
                  <li>aids in cutting lead times and raising the caliber of products</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Markting_Automation} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Marketing Automation</h3>
              <div className="service-points">
                <ul>
                  <li>Allows for smooth integration of data between marketing and sales platforms</li>
                  <li>Helps improve lead generation and nurturing.</li>
                  <li>Enables personalized and targeted marketing initiatives.</li>
                  <li>Enables personalized and targeted marketing initiatives.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Payment_Gateway} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Payment Gateway Integration</h3>
              <div className="service-points">
                <ul>
                  <li>Allows for simple integration of payment gateways into e-commerce websites.</li>
                  <li>Helps to improve the client checkout experience.</li>
                  <li>Enables faster and more secure payment processing.</li>
                  <li>Helps to reduce cart abandonment rates.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={HRM} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Human Resource Management (HRM)</h3>
              <div className="service-points">
                <ul>
                  <li>permits the smooth exchange of data between manufacturers, suppliers, and retailers.</li>
                  <li>enhances employee retention and engagement.</li>
                  <li>Facilitates improved workforce planning and management.</li>
                  <li>Increases conformity with regulatory regulations.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Business_Automation} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Business Intelligence (BI)</h3>
              <div className="service-points">
                <ul>
                  <li>makes it possible for data to be seamlessly integrated into a central data warehouse from multiple sources.</li>
                  <li>enhances the reporting and analysis of data.</li>
                  <li>allows for the real-time tracking of KPIs and business performance.</li>
                  <li>facilitates the use of data to make judgments.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
            <img src={Cloud_Infrastructure} alt ='' width="100%"/>
            <h3 className="mt-2" style={{textAlign:"left"}}>Cloud Infrastructure</h3>
              <div className="service-points">
                <ul>
                  <li>Enables the smooth integration of cloud-based systems and applications.</li>
                  <li>Helps to reduce IT infrastructure costs.</li>
                  <li>Facilitates easy scaling of IT systems.</li>
                  <li>Enhances data security and catastrophe recovery.</li>
                </ul>
              </div>
         </div>
          </div>
          <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
          <div className="slide" style={{width:"18rem"}}> 
            <img src={Social_Media} alt ='' width="100%"/>
            <h3 className="mt-2" style={{textAlign:"left"}}>Social Media</h3>
              <div className="service-points">
                <ul>
                  <li>It enables the seamless integration of social media networks with marketing and CRM systems.</li>
                  <li>Helps to increase social media engagement and brand recognition.</li>
                  <li>It enables targeted social media advertising.</li>
                  <li>Enhances social media analytics and reporting.</li>
                </ul>
              </div>
         </div>
          </div>
          <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
          <div className="slide" style={{width:"18rem"}}>
            <img src={IOT} alt ='' width="100%"/>
            <h3 className="mt-2" style={{textAlign:"left"}}>Internet of Things (IoT)</h3>
              <div className="service-points">
                <ul>
                  <li>Allows for smooth integration of IoT devices with business systems and applications.</li>
                  <li>Helps to improve asset management and predictive maintenance.</li>
                  <li>Provides real-time monitoring of business operations and performance.</li>
                  <li>Helps to reduce downtime and improve product quality.</li>
                </ul>
              </div>
         </div>
          </div>
            </Slider>
          </div>

          {/* mobile */}
          <div className="row d-md-none">
          <Slider {...settings2}>
          <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={crm} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Customer Relationship Management (CRM)</h3>
              <div className="service-points">
                <ul>
                  <li>Allows for a seamless movement of customer data between systems.</li>
                  <li>Helps to improve the consumer experience and decision-making.</li>
                  <li>Personalizes marketing tactics and boosts customer engagement.</li>
                  <li>Streamlines customer service and shortens response times.</li>
                </ul>
              </div>
              </div>
            </div>
            <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={ERP_IMG} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Enterprise Resource Planning (ERP)</h3>
              <div className="service-points">
                <ul>
                  <li>Allows data to move seamlessly between systems including accounting, inventories, sales, and human resources.</li>
                  <li>Helps to streamline corporate operations and increase efficiency.</li>
                  <li>Provides real-time visibility into business operations and decision-making.</li>
                  <li>Increases data accuracy and minimizes human data entry errors.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={SCM_IMG} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Supply Chain Management (SCM)</h3>
              <div className="service-points">
                <ul>
                  <li>permits the smooth exchange of data between manufacturers, suppliers, and retailers.</li>
                  <li>enhances the responsiveness and visibility of the supply chain</li>
                  <li>improves demand forecasts and inventory management</li>
                  <li>aids in cutting lead times and raising the caliber of products</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Markting_Automation} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Marketing Automation</h3>
              <div className="service-points">
                <ul>
                  <li>Allows for smooth integration of data between marketing and sales platforms</li>
                  <li>Helps improve lead generation and nurturing.</li>
                  <li>Enables personalized and targeted marketing initiatives.</li>
                  <li>Enables personalized and targeted marketing initiatives.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Payment_Gateway} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Payment Gateway Integration</h3>
              <div className="service-points">
                <ul>
                  <li>Allows for simple integration of payment gateways into e-commerce websites.</li>
                  <li>Helps to improve the client checkout experience.</li>
                  <li>Enables faster and more secure payment processing.</li>
                  <li>Helps to reduce cart abandonment rates.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={HRM} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Human Resource Management (HRM)</h3>
              <div className="service-points">
                <ul>
                  <li>permits the smooth exchange of data between manufacturers, suppliers, and retailers.</li>
                  <li>enhances employee retention and engagement.</li>
                  <li>Facilitates improved workforce planning and management.</li>
                  <li>Increases conformity with regulatory regulations.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
              <img src={Business_Automation} alt ='' width="100%"/>
              <h3 className="mt-2" style={{textAlign:"left"}}>Business Intelligence (BI)</h3>
              <div className="service-points">
                <ul>
                  <li>makes it possible for data to be seamlessly integrated into a central data warehouse from multiple sources.</li>
                  <li>enhances the reporting and analysis of data.</li>
                  <li>allows for the real-time tracking of KPIs and business performance.</li>
                  <li>facilitates the use of data to make judgments.</li>
                </ul>
              </div>
           </div>
            </div>
            <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
            <div className="slide" style={{width:"18rem"}}>
            <img src={Cloud_Infrastructure} alt ='' width="100%"/>
            <h3 className="mt-2" style={{textAlign:"left"}}>Cloud Infrastructure</h3>
              <div className="service-points">
                <ul>
                  <li>Enables the smooth integration of cloud-based systems and applications.</li>
                  <li>Helps to reduce IT infrastructure costs.</li>
                  <li>Facilitates easy scaling of IT systems.</li>
                  <li>Enhances data security and catastrophe recovery.</li>
                </ul>
              </div>
         </div>
          </div>
          <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
          <div className="slide" style={{width:"18rem"}}> 
            <img src={Social_Media} alt ='' width="100%"/>
            <h3 className="mt-2" style={{textAlign:"left"}}>Social Media</h3>
              <div className="service-points">
                <ul>
                  <li>It enables the seamless integration of social media networks with marketing and CRM systems.</li>
                  <li>Helps to increase social media engagement and brand recognition.</li>
                  <li>It enables targeted social media advertising.</li>
                  <li>Enhances social media analytics and reporting.</li>
                </ul>
              </div>
         </div>
          </div>
          <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
          <div className="slide" style={{width:"18rem"}}>
            <img src={IOT} alt ='' width="100%"/>
            <h3 className="mt-2" style={{textAlign:"left"}}>Internet of Things (IoT)</h3>
              <div className="service-points">
                <ul>
                  <li>Allows for smooth integration of IoT devices with business systems and applications.</li>
                  <li>Helps to improve asset management and predictive maintenance.</li>
                  <li>Provides real-time monitoring of business operations and performance.</li>
                  <li>Helps to reduce downtime and improve product quality.</li>
                </ul>
              </div>
         </div>
          </div>
            </Slider>
          </div>
          </div>
        </div>

        <div className="background py-5">
          <div className="container-fluid">
        <div className="head" style={{textAlign:"center"}}>
        <p style={{color:"#f4990b"}}>IT FOR BUSINESS</p>
        <h1>Information technology domains specific to industries.</h1>
           
        </div>
        <div className="row">
        <Slider className="d-none d-md-block d-lg-none" {...settings}>
        <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={Health} className="card-img-top card-image " alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Healthcare</div>
                              <div className="card-text">Modern technology has changed the financial industry dramatically. This has changed the way people invest, manage their money and behave financially. Exciting innovations such as algorithmic trading</div>
                              <div className='read-more pt-2'><Link to='/Healthcare'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={Legal} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Legal</div>
                              <div className="card-text">Adopting contemporary solutions is crucial to staying ahead of the issues the legal business faces, which include expanding complexity, rising prices, and the demand for quicker and more efficient</div>
                              <div className='read-more pt-2 '><Link to='/Legal'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                          <img src={Education} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Education</div>
                              <div className="card-text">The educational system needs to use cutting-edge approaches like distance learning, adaptive technologies, and individualized learning to satisfy the varied demands of today's pupils.</div>
                              <div className='read-more pt-2 '><Link to='/Education'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services ui-card" style={{width: "18rem"}}>
                          <img src={Finance} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Finance</div>
                              <div className="card-text">The finance business has seen tremendous transformation as a result of modern technology, which has completely changed how people invest, manage their money, and carry out financial transactions.</div>
                              <div className='read-more pt-2 '><Link to='/Finance'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={MobInsurance} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Insurance</div>
                              <div className="card-text">To stay ahead of the competition and meet the evolving requirements of their customers in today's fast-paced world, insurance companies must employ cutting-edge solutions.</div>
                              <div className='read-more pt-2 '><Link to='/Insurance'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>

                  {/* <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={CRM} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Wellness</div>
                              <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                          <img src={Mobile_Application} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Banking</div>
                              <div className="card-text">We build intuitive and engaging Android, iOS and cross-platform apps for businesses, consumers and enterprises that end users love and adapt to them very quickly.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services ui-card" style={{width: "18rem"}}>
                          <img src={Custom_Software_Solution} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Hospitality</div>
                              <div className="card-text">Starting from concept, information architecture, visual identity and UI/UX design, our team delivers dazzling experiences for maximum user engagement.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>
              
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={ETL} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">E-Commerce</div>
                              <div className="card-text">Create your own team of developers for your software development project on short term, long term or permanent basis with guaranteed project delivery at affordable prices.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={Salesforce} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Logistics</div>
                              <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div> */}
        </Slider>

            <Slider className="d-none d-lg-block" {...settings1}>
            <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={Health} className="card-img-top card-image " alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Healthcare</div>
                              <div className="card-text">Modern technology has changed the financial industry dramatically. This has changed the way people invest, manage their money and behave financially. Exciting innovations such as algorithmic trading</div>
                              <div className='read-more pt-2'><Link to='/Healthcare'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={Legal} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Legal</div>
                              <div className="card-text">Adopting contemporary solutions is crucial to staying ahead of the issues the legal business faces, which include expanding complexity, rising prices, and the demand for quicker and more efficient</div>
                              <div className='read-more pt-2 '><Link to='/Legal'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                          <img src={Education} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Education</div>
                              <div className="card-text">The educational system needs to use cutting-edge approaches like distance learning, adaptive technologies, and individualized learning to satisfy the varied demands of today's pupils.</div>
                              <div className='read-more pt-2 '><Link to='/Education'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services ui-card" style={{width: "18rem"}}>
                          <img src={Finance} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Finance</div>
                              <div className="card-text">The finance business has seen tremendous transformation as a result of modern technology, which has completely changed how people invest, manage their money, and carry out financial transactions.</div>
                              <div className='read-more pt-2 '><Link to='/Finance'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={MobInsurance} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Insurance</div>
                              <div className="card-text">To stay ahead of the competition and meet the evolving requirements of their customers in today's fast-paced world, insurance companies must employ cutting-edge solutions.</div>
                              <div className='read-more pt-2 '><Link to='/Insurance'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>

                  {/* <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={CRM} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Wellness</div>
                              <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                          <img src={Mobile_Application} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Banking</div>
                              <div className="card-text">We build intuitive and engaging Android, iOS and cross-platform apps for businesses, consumers and enterprises that end users love and adapt to them very quickly.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services ui-card" style={{width: "18rem"}}>
                          <img src={Custom_Software_Solution} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Hospitality</div>
                              <div className="card-text">Starting from concept, information architecture, visual identity and UI/UX design, our team delivers dazzling experiences for maximum user engagement.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>
              
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={ETL} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">E-Commerce</div>
                              <div className="card-text">Create your own team of developers for your software development project on short term, long term or permanent basis with guaranteed project delivery at affordable prices.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={Salesforce} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Logistics</div>
                              <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div> */}
            </Slider>

            <Slider className="d-md-none" {...settings2}>
            <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={Health} className="card-img-top card-image " alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Healthcare</div>
                              <div className="card-text">Modern technology has changed the financial industry dramatically. This has changed the way people invest, manage their money and behave financially. Exciting innovations such as algorithmic trading</div>
                              <div className='read-more pt-2'><Link to='/Healthcare'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={Legal} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Legal</div>
                              <div className="card-text">Adopting contemporary solutions is crucial to staying ahead of the issues the legal business faces, which include expanding complexity, rising prices, and the demand for quicker and more efficient</div>
                              <div className='read-more pt-2 '><Link to='/Legal'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                          <img src={Education} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Education</div>
                              <div className="card-text">The educational system needs to use cutting-edge approaches like distance learning, adaptive technologies, and individualized learning to satisfy the varied demands of today's pupils.</div>
                              <div className='read-more pt-2 '><Link to='/Education'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services ui-card" style={{width: "18rem"}}>
                          <img src={Finance} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Finance</div>
                              <div className="card-text">The finance business has seen tremendous transformation as a result of modern technology, which has completely changed how people invest, manage their money, and carry out financial transactions.</div>
                              <div className='read-more pt-2 '><Link to='/Finance'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>
                
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={MobInsurance} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Insurance</div>
                              <div className="card-text">To stay ahead of the competition and meet the evolving requirements of their customers in today's fast-paced world, insurance companies must employ cutting-edge solutions.</div>
                              <div className='read-more pt-2 '><Link to='/Insurance'>Read More-</Link></div>
                          </div>
                      </div>
                  </div>

                  {/* <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={CRM} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Wellness</div>
                              <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                          <img src={Mobile_Application} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Banking</div>
                              <div className="card-text">We build intuitive and engaging Android, iOS and cross-platform apps for businesses, consumers and enterprises that end users love and adapt to them very quickly.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>

                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services ui-card" style={{width: "18rem"}}>
                          <img src={Custom_Software_Solution} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Hospitality</div>
                              <div className="card-text">Starting from concept, information architecture, visual identity and UI/UX design, our team delivers dazzling experiences for maximum user engagement.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>
              
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services AI" style={{width: "18rem"}}>
                          <img src={ETL} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">E-Commerce</div>
                              <div className="card-text">Create your own team of developers for your software development project on short term, long term or permanent basis with guaranteed project delivery at affordable prices.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                      <div className="card core-services web-card" style={{width: "18rem"}}>
                          <img src={Salesforce} className="card-img-top card-image" alt="..."/>
                          <div className="card-body">
                              <div className="card-title">Logistics</div>
                              <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                              <div className='read-more pt-2 '><a href='#/speak'>Read More-</a></div>
                          </div>
                      </div>
                  </div> */}
            </Slider>
            </div>
            </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="contact-banner-heading p-lg-4  mt-4">
              {" "}
              <p style={{color:"#f4990b"}}>TECHNOLOGY INTEGRATION</p>
              <h1>Important ancilla of integration.</h1>
             
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
              <div className=" bg-white  p-3">
                <h3>Platform for Integration.</h3>
                <div className="service">
                  <p>The middleware and software that links various systems and permits data interchange.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
            <div className=" bg-white  p-3">
                <h3>Mapping Data.</h3>
                <div className="service">
                  <p>The procedure for specifying and transferring data fields across several systems.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
            <div
                className=" bg-white  p-3"
                
              >
                <h3>API Creation</h3>
                <div className="service">
                  <p>The creation of unique APIs to facilitate system-to-system communication.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
            <div
                className=" bg-white  p-3"
                
              >
                <h3>Validation and Testing.</h3>
                <div className="service">
                  <p>Thorough testing of the connected systems to guarantee system functionality and data accuracy</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
            <div
                className=" bg-white  p-3"
                
              >
                <h3>Security</h3>
                <div className="service">
                  <p>Implementation of security procedures and mechanisms to protect the data sent between systems.</p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
            <div
                className=" bg-white  p-3"
                
              >
                <h3>Maintenance and Support</h3>
                <div className="service">
                  <p>Ongoing maintenance and support to guarantee that the integrated systems perform effectively and satisfy the changing needs of the business.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="background py-5">
          <div className="container-fluid">
        <div className="row ">
        <div className="col-lg-12 my-auto py-4">
          <div className="contact-banner-heading p-lg-4  mt-4">
          
            <p
              className="headings"
              style={{ textAlign: "center",color:"#f4990b"}}
            >
             CASE STUDIES
            </p>

          <h1>
          Look carefully at our solutions for the EAS.
            </h1>
          </div>
        </div>
      </div>
      <div className="row">
      <Slider className="d-none d-lg-block" {...settings1}>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={Dot_Net} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>.NET / INFORMATION TECHNOLOGY</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">.NET Framework based Vehicle Speed Enforcing Application</p>
                          <div className='d-flex'>
                              
                              <div className='py-1 px-2 disble-btn mt-4'>.NET</div>
                              <div className='py-1 px-2 disble-btn mt-4'>INFORMATION TECHNOLOGY</div>
                              {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                              <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Health} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / HEALTHCARE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">SuiteCRM Contivio Integrationfor Patient Care Management System</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>HEALTHCARE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Education} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / EDUCATION / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Sales Process Automation using SuiteCRM for Education Bussiness</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>EDUCATION</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_ETL} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / ETL / PUBLICATION / SUGARCRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Order Management and Contract Management Automation using Customized SugarCRM</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn '>CRM</div>
                              <div className='py-1 px-2 disble-btn '>ETL</div>
                              <div className='py-1 px-2 disble-btn '>PUBLICATION</div>
                              <div className='py-1 px-2 disble-btn '>SUGARCRM</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CRM_Publication} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / PUBLICATION / SUITECRM</div>
                        {/* <h5 className="card-title">HomzMart</h5> */}
                        <p className="card-text">Order Management, Invoice Management and Contract Management Automation using SuiteCRM</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>CRM</div>
                            <div className='py-1 px-2 disble-btn'>PUBLICATION</div>
                            <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                            {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                        </div>
                    </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Finance} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">GoldMine to SuiteCRM Migration</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-5'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-5'>FINANCE</div>
                              <div className='py-1 px-2 disble-btn mt-5'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Finance1} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">SuiteCRM Asterisk Telephony Integration</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>FINANCE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Inventory} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / SERVICE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Inventory Management Using SuiteCRM</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SERVICE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
            </Slider>

            <Slider className="d-none d-md-block d-lg-none" {...settings}>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={Dot_Net} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>.NET / INFORMATION TECHNOLOGY</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">.NET Framework based Vehicle Speed Enforcing Application</p>
                          <div className='d-flex'>
                              
                              <div className='py-1 px-2 disble-btn mt-4'>.NET</div>
                              <div className='py-1 px-2 disble-btn mt-4'>INFORMATION TECHNOLOGY</div>
                              {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                              <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Health} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / HEALTHCARE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">SuiteCRM Contivio Integrationfor Patient Care Management System</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>HEALTHCARE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Education} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / EDUCATION / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Sales Process Automation using SuiteCRM for Education Bussiness</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>EDUCATION</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_ETL} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / ETL / PUBLICATION / SUGARCRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Order Management and Contract Management Automation using Customized SugarCRM</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn '>CRM</div>
                              <div className='py-1 px-2 disble-btn '>ETL</div>
                              <div className='py-1 px-2 disble-btn '>PUBLICATION</div>
                              <div className='py-1 px-2 disble-btn '>SUGARCRM</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CRM_Publication} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / PUBLICATION / SUITECRM</div>
                        {/* <h5 className="card-title">HomzMart</h5> */}
                        <p className="card-text">Order Management, Invoice Management and Contract Management Automation using SuiteCRM</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>CRM</div>
                            <div className='py-1 px-2 disble-btn'>PUBLICATION</div>
                            <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                            {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                        </div>
                    </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Finance} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">GoldMine to SuiteCRM Migration</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-5'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-5'>FINANCE</div>
                              <div className='py-1 px-2 disble-btn mt-5'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Finance1} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">SuiteCRM Asterisk Telephony Integration</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>FINANCE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Inventory} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / SERVICE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Inventory Management Using SuiteCRM</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SERVICE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
            </Slider>

            <Slider className="d-md-none" {...settings2}>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={Dot_Net} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>.NET / INFORMATION TECHNOLOGY</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">.NET Framework based Vehicle Speed Enforcing Application</p>
                          <div className='d-flex'>
                              
                              <div className='py-1 px-2 disble-btn mt-4'>.NET</div>
                              <div className='py-1 px-2 disble-btn mt-4'>INFORMATION TECHNOLOGY</div>
                              {/* <div className='py-1 px-2 disble-btn'>PHP</div>
                              <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Health} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / HEALTHCARE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">SuiteCRM Contivio Integrationfor Patient Care Management System</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>HEALTHCARE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Education} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / EDUCATION / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Sales Process Automation using SuiteCRM for Education Bussiness</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>EDUCATION</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_ETL} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / ETL / PUBLICATION / SUGARCRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Order Management and Contract Management Automation using Customized SugarCRM</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn '>CRM</div>
                              <div className='py-1 px-2 disble-btn '>ETL</div>
                              <div className='py-1 px-2 disble-btn '>PUBLICATION</div>
                              <div className='py-1 px-2 disble-btn '>SUGARCRM</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                    <img src={CRM_Publication} className="card-img-top" alt="..."/>
                    <div className="card-body">
                        <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / PUBLICATION / SUITECRM</div>
                        {/* <h5 className="card-title">HomzMart</h5> */}
                        <p className="card-text">Order Management, Invoice Management and Contract Management Automation using SuiteCRM</p>
                        <div className='d-flex'>
                            <div className='py-1 px-2 disble-btn'>CRM</div>
                            <div className='py-1 px-2 disble-btn'>PUBLICATION</div>
                            <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                            {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                        </div>
                    </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Finance} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">GoldMine to SuiteCRM Migration</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-5'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-5'>FINANCE</div>
                              <div className='py-1 px-2 disble-btn mt-5'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Finance1} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / FINANCE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">SuiteCRM Asterisk Telephony Integration</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>FINANCE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                  <div className="card mb-2 mb-lg-0" style={{width: "16rem"}}>
                      <img src={CRM_Inventory} className="card-img-top" alt="..."/>
                      <div className="card-body">
                          <div className='py-2' style={{fontSize:12,color:'#BEBEBE'}}>CRM / SERVICE / SUITECRM</div>
                          {/* <h5 className="card-title">HomzMart</h5> */}
                          <p className="card-text">Inventory Management Using SuiteCRM</p>
                          <div className='d-flex'>
                              <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SERVICE</div>
                              <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                              {/* <div className='py-1 px-2 disble-btn'>React Native</div> */}
                          </div>
                      </div>
                  </div>
              </div>
            </Slider>
            

      </div>
      </div>
        </div>
    </>
  );
}
