import Email from '../../assets/email.png'
import { Link, useLocation } from "react-router-dom";
import '../footer/footer.css'

const Footer = (props) => (
  <div  style={{backgroundColor: "#000000"}}>
    <footer id="footer">
      <div className="footer-top pt-5 px-5" style={{color: "#77808B"}}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 px-0 col-md-6 col-12 center-al py-2">
              {/* <div className='mb-2'><img className='footer-logo' src={Logo} width={180}/></div> */}
              <div className=''><strong>INDIA</strong></div>
              <div className='d-flex icon-text pt-2 justify-content-center justify-content-md-start'><div className='tac-text'>A-83 Sector 63 Noida (UP) 201301</div></div>
              <div className='d-flex icon-text pt-2 pb-3 justify-content-center justify-content-md-start'><div className='tac-text'>+91-7827150289</div></div>
              <div className='mt-2'><strong>USA</strong></div>
              <div className='d-flex icon-text pt-2 justify-content-center justify-content-md-start'><div className='tac-text'> Suite D800 25420 Kuykendahl Rd, Tomball, TX 77375, United States</div></div>
              <div className='d-flex icon-text pt-2 pb-3 justify-content-center justify-content-md-start'><div className='tac-text'> +1 302-219-0001</div></div>
              <div className='d-flex icon-text pt-2 pb-2 justify-content-center justify-content-md-start'><div className='my-auto'><img src={Email} height={"4%"}/></div><div className='tac-text'>info@dahooks.com</div></div>
            </div>
            <div className="col-lg-2 col-md-3 col-12 offset-lg-1 center-al py-2 mb-4">
              <div className=' footer-heading'>Services</div>
              <div className=' pt-2'><Link to='/web-dev'>Web Development</Link></div>
              <div className=''><Link to='/mobile-apps'>Mobile Apps</Link></div>
              <div className=''><Link to='/AI'>AI / ML</Link></div>
              <div className=''><Link to='/ui-ux'>UI / UX</Link></div>
              <div className=''><Link to='/DevOps'>DevOps</Link></div>
              <div className=''><Link to='/Qa'>QA</Link></div>
              <div className=''><Link to='/iot'>IOT</Link></div>
              <div className=''><Link to='/Marketing'>Marketing</Link></div>
            </div>
            <div className="col-lg-2 col-md-3 col-12  center-al py-2 mb-4">
              <div className=' footer-heading'>Industries</div>
              <div className=' pt-2'><Link to='/Healthcare'>Healthcare</Link></div>
              <div className=''><Link to='/Legal'>Legal</Link></div>
              <div className=''><Link to='/Education'>Education</Link></div>
              <div className=''><Link to='/Finance'>Finance</Link></div>
              <div className=''><Link to='/Insurance'>Insurance</Link></div>
            </div>
            <div className="col-lg-2 col-md-6 col-12  center-al py-2 mb-4">
              <div className=' footer-heading'>Company</div>
              <div className=' pt-2'><Link to='/Services'>Services</Link></div>
              <div className=''><Link to='/Team'>Team</Link></div>
              <div className=''><Link to='/case'>Case Study</Link></div>
              <div className=''><Link to='/contact-us'>Contact Us</Link></div>
              <div className=''><Link to='/Privacy'>Privacy Policy</Link></div>
            </div>
            <div className="col-lg-2 col-md-6 col-12  center-al py-2 mb-4">
              <div className=' footer-heading'>Our Social Media</div>
              <div className=' pt-2'><a href='https://www.linkedin.com/company/dahooks-technologies/' target='blank'>linkedin</a></div>
              <div className=''><a href='https://www.instagram.com/dahookstechnologies/' target='blank'>Instagram</a></div>
              <div className=''><a href='https://www.facebook.com/dahookstechnologies' target='blank'>Facebook</a></div>
              <div className=''><a href="https://twitter.com/dahookstech" target="blank">Twitter</a></div>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-4">
        <div className="text-center ">
          <div className="copyright">
            © 2024,Copyright Dahooks
          </div>
        </div>
        {/* <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="#" className="twitter">
            <i className="bx bxl-twitter" />
          </a>
          <a href="https://www.facebook.com/TactionSoftwareLLCTexas" className="facebook">
            <i className="bx bxl-facebook" />
          </a>
          <a href="#" className="instagram">
            <i className="bx bxl-instagram" />
          </a>
          <a href="#" className="google-plus">
            <i className="bx bxl-skype" />
          </a>
          <a href="#" className="linkedin">
            <i className="bx bxl-linkedin" />
          </a>
          <iconify-icon icon="ri:hourglass-fill"></iconify-icon>
        </div> */}
      </div>
    </footer>
  </div>
);
export default Footer;