import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import SalesA from '../../../assets/SalesProcessAutomation.jpg'

export default function SalesAuto() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Sales Process Automation Using On Demand SugarCRM Pro</strong></div>
                    <p className='mt-2 text-center'>A young boutique style photography studio offering services including wedding photography, portrait photography, specialist new-born photography and wedding cinematography in Donegal, Tyrone, Ireland, etc.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>The client decided to automate their sales processes using on-demand SugarCRM 7 Pro edition. The Client was having difficulties in sales and workflow automation effectively with the options possible through the SugarCRM studio.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>SugarCRM 7 Pro (On Demand)</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={SalesA} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We configured and customized SugarCRM 7 Pro and developed a custom panel to automate their sales processes meaningfully as per their process expectations. We also built the number of workflows to automate various notifications and data creation processes.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The effective configuration, optimal customization, and workflow creation helped the client in managing their sales processes better generating higher sales.</p>
                </div>
            </div>
        </div>
    </>
  )
}
