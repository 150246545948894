import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import InventoryM from '../../../assets/inventry-management.jpg'

export default function Inventory() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Inventory Management Using SuiteCRM</strong></div>
                    <p className='mt-2 text-center'>A licensed sanitisation company from Dubai, UAE offering disinfection service that significantly reduces pathogens and toxins in the air and on surfaces. Their disinfection solution disperses a specialised eco-friendly bio-sanitiser agent that eradicates airborne and surface-based bacteria in cars, buses, residential, offices, hotels, restaurants, health clubs and schools.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>The Client was looking for a cost effective solution to automate their Uniform Inventory Management and Employee Uniform Allocation processes.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>SuiteCRM</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={InventoryM} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We designed and developed a solution for them by leveraging the power of SuiteCRM automating the processes like uniform stock management, stock barcoding, allocation and write-off of uniforms along with Employee Information Management.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The SuiteCRM based system had a very high positive impact on effective Employee Information Management and uniform distribution and tracking processes.</p>
                </div>
            </div>
        </div>
    </>
  )
}
