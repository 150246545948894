import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import PHPB from '../../../assets/custom healthcare crm.jpg'

export default function PharmacyUsing() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Pharmacy Management Using HL7 Messaging Covering</strong></div>
                    <p className='mt-2 text-center'>The Client offers a wide range of offshore pharmacy management using HL7 messaging covering a wide range of management activities including pharmacy management, patient management. They deliver high-quality offshore software for customized pharmacy management applications to a healthcare organization.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Problem Statement / Challenges:</div>
                    <p className='mt-4'>The Client wanted to design and develop a process to get information from the physician’s order sheet, transform this information and insert this information into a client-defined database after various client-defined validation and crosswalks. All these processes must follow HL7Standards.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>Mirth Connect, JavaScript, java, HL7 Messages.</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={PHPB} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>Dahooks Designed and Developed multiple HL7-based channels using Mirth Connect to achieve the challenge. The inbound information was ADT01 and RDE011 HL7 messages. We developed transformation logic upon these inbound messages using java, and JavaScript to grab and insert good quality data in the client-defined database. Based on received messages we also generated daily reports after all processing and mail these reports back to the client in an automated manner in order to analyze the data.</p>
                </div>
            </div>
        </div>
    </>
  )
}
