import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import ContivioImg from '../../../assets/Contivio.jpg'

export default function Contivio() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>SuiteCRM Contivio Integration for Patient Care Management System</strong></div>
                    <p className='mt-2 text-center'>A US based startup Healthcare organization designed for Primary Care Physician’s to strengthening their patient’s overall wellness and relationship through “Patient Relationship Management” and “Care Management” services.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>Client was looking for cost effective solutions for physician, patient and clinic relationship management system. They wanted a cloud based telephony system to be integrated with CRM for better collaboration between the Doctors and Patients.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>SuiteCRM, Contivio</p>
                </div>
                <div className='col-lg-6 col-12 order-1 order-lg-2'>
                    <div><img src={ContivioImg} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We have built a portal that allows patients to interact and communicate with their healthcare service providers, such as doctors and hospitals/clinics using SuiteCRM Integration. The backend platform has been developed by leveraging SuiteCRM to maintain the relationship between Doctors and Patients, Doctor and Clinics and Patients and Clinics so as to manage the details of clinic wise Doctor and Patient collaboration. We have integrated Contivio, a cloud based telephony system, for contact centers automation.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The healthcare system automated number of workflows to avoid unnecessary delays and manual intervention for better effectiveness. It also offers a Doctor-Patient online communication channel through a tight integration with Contivio, a cloud-based Contact Center & Telephony software, for effective communication among the Doctors, Patients and the clinics. The SuiteCRM implementation has improved the Doctor-Patient relationship a lot by consolidation of every details into one platform instead of spreading over in multiple repositories in different forms.</p>
                </div>
            </div>
        </div>
    </>
  )
}
