import React,{useEffect} from 'react'
import Hero from '../../hero/hero-sec'
import Hire from '../../Hire/hire-sec'
import Customer from '../../customer/customer-sec'
import Count from '../../count-sec/count-sec'
import Tabs from '../../tabs-sec/tabs'
import OurWork from '../../our-work-sec/our-work'
import Award from '../../award-sec/award'
import OurClients from "../../our-clients/our-clients"
import Core from "../../core-services-sec/core-service"
import {Helmet} from "react-helmet";

export default function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <React.Fragment>
        <Helmet>
          <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Custom Software, Web & App Development Company in India, USA - Dahooks" /><meta property="og:description" content="Dahooks is the best worldwide website and software development Company in India, USA offers top-notch software, mobile apps and web apps Development Services to companies of all sizes across different industries." /><meta property="og:url" content="https://dahooks.com/" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Custom Software, Web & App Development Company in India, USA - Dahooks" /><meta name="twitter:description" content="Dahooks is the best worldwide website and software development Company in India, USA offers top-notch software, mobile apps and web apps Development Services to companies of all sizes across different industries." /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
          <title>Custom Software, Web & App Development Company in India, USA - Dahooks</title>
          <link rel="canonical" href="https://dahooks.com/" />
        </Helmet>
        <Hero/>
         <Hire/>
         <Customer/>
         <Count/>
         <Core/>
         <Tabs/>
         <OurWork/>
         <OurClients/>
         <Award/> 
    </React.Fragment>
  )
}
