import React, { useEffect } from 'react'
import '../customer/customer.css'
import Edumcomp from '../../assets/educomp.png'
import Yennes from '../../assets/yennes.png'
import FNB from '../../assets/FNB.png'
import Ookla from '../../assets/ookla.png'
import Packsys from '../../assets/packsys.png'
import Safe from '../../assets/safe.png'
import Sani from '../../assets/sani.png'
import tata from '../../assets/tata-logo.png'
import SBM from '../../assets/SBM_logo.png'

export default function Customer() {
  return(
  <>
  <div className='container'>
    <div className='row mx-2'>
      <div className='col-12'>
      <div className="slider mt-5">
    <div className="slide-track">
      <div className="slide my-auto">
        <img
          src={tata}
          height={20}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={SBM}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Sani}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Safe}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Packsys}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Ookla}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={FNB}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Yennes}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Edumcomp}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide my-auto">
        <img
          src={tata}
          height={20}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={SBM}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Sani}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Safe}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Packsys}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Ookla}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={FNB}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Yennes}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Edumcomp}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide my-auto">
        <img
          src={tata}
          height={20}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={SBM}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Sani}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Safe}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Packsys}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Ookla}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={FNB}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Yennes}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Edumcomp}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide my-auto">
        <img
          src={tata}
          height={20}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={SBM}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Sani}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Safe}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Packsys}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Ookla}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={FNB}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Yennes}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Edumcomp}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide my-auto">
        <img
          src={tata}
          height={20}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={SBM}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Sani}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Safe}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Packsys}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Ookla}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={FNB}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Yennes}
          height={40}
          width={100}
          alt=""
        />
      </div>
      <div className="slide">
        <img
          src={Edumcomp}
          height={40}
          width={100}
          alt=""
        />
      </div>
    </div>
  </div>
      </div>
    </div>
  </div>
    

  </>
)
}
