import React from 'react';
import { Link } from 'react-router-dom';
import TataAIA_inside from '../../../assets/TATA_inside.jpg';

export default function TataAIA() {
    return (
        <>
            <div className='container pt-4'>
                <div className='row pt-5'>
                    <div className='col-12 mt-5 pt-5'>
                        <div className='mx-5 heading-text text-center my-3'><strong>Taction's Calculator Solutions for TATA AIA</strong></div>
                        <p className='mt-2 text-center'>TATA AIA, a leading insurance company in India, sought a solution to improve its underwriting process. Taction developed a calculator tool to help agents calculate accurate policy premiums, resulting in increased customer satisfaction and streamlined workflows.</p>
                    </div>
                </div>

                {/* Problem Statement */}
                <div className='row mt-5'>
                    <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                        <div className='Vehicle-text mt-0'>Challenges:</div>
                        <p className='mt-4'>TATA AIA's agents faced challenges in delivering accurate financial advice due to reliance on manual calculations, leading to inefficiencies and errors in policy premium calculations.</p>
                        <div className='Vehicle-text'>Technology:</div>
                        <p className='mt-4'>React JS, Node JS, MySQL</p>
                    </div>
                    <div className='col-lg-6 order-1 order-lg-2'>
                        <div><img src={TataAIA_inside} width={"100%"} alt="Tata AIA calculator solution" /></div>
                    </div>
                </div>

                {/* Solution Overview */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Solution:</div>
                        <p className='mt-4'>Taction developed a custom calculator solution for TATA AIA that included a policy premium calculator, life insurance calculator, and health insurance calculator. These tools allow agents to input policy details and receive accurate, real-time calculations.</p>
                    </div>
                </div>

                {/* Key Features and Functionalities */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Key Features and Functionalities:</div>
                        <div className='mt-4'>
                            The TATA AIA Calculator Platform offers:
                            <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                                <li><strong>Policy Premium Calculator:</strong> Calculates premiums based on input policy details like coverage amount, duration, and payment frequency.</li>
                                <li><strong>Life Insurance Calculator:</strong> Provides a breakdown of premium amounts and benefits considering age, sum assured, and policy term.</li>
                                <li><strong>Health Insurance Calculator:</strong> Computes premiums based on age, coverage type, and duration, with a summary of benefits.</li>
                                <li><strong>Integrated Database:</strong> Secured access for authorized agents, ensuring data integrity and efficient workflow integration.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Outcome and Impact */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Outcome and Impact:</div>
                        <p className='mt-4'>
                            The solution delivered by Taction has significantly streamlined TATA AIA's underwriting process, with a 95% reduction in manual errors and an 80% increase in agent productivity. Underwriting time has been cut by 70%, and customer satisfaction levels have increased to 90%.
                        </p>
                    </div>
                </div>

                {/* Evaluation and Impact */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Evaluation and Impact:</div>
                        <p className='mt-4'>
                            By implementing the calculator solution, TATA AIA achieved remarkable results, reducing calculation errors, improving agent efficiency, and enhancing customer experience. With 90% customer satisfaction, TATA AIA has strengthened customer trust and loyalty.
                        </p>
                    </div>
                </div>

                {/* Conclusion */}
                <div className='row'>
                    <div className='col-12 mb-5'>
                        <div className='Vehicle-text'>Conclusion:</div>
                        <p className='mt-4'>
                            Taction’s calculator solution for TATA AIA has streamlined the insurance underwriting process, enhancing accuracy, reducing errors, and improving agent productivity. This solution has provided tangible benefits to both agents and customers, significantly transforming the way TATA AIA calculates and delivers policy premiums.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
