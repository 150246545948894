import React from 'react'
import '../Hire/hire-sec.css'
import { Link } from "react-router-dom";

export default function Hire() {
    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='heading-text text-center mb-4 hire-text mt-4'>
                        Are you in search of hiring proficient developers for your web and mobile applications?<br className='d-none d-lg-block' /> Look no further than Dahooks!
                    </div>
                    <div className='col-md-10 col-12 order-2 order-md-1'>
                        <div className='content'>
                            We're a dependable web and software development firm with a team of talented developers available for projects with set schedules and budgets. Our team is proficient in many tech areas like PHP, Laravel, Magento, ReactJS, AngularJS, and Node.js. We create unique, user-friendly websites, web apps, and e-commerce options.<br /><br />
                            Additional services we provide include mobile app development for both Android & iOS and cross-platform app creation. Our developers are highly skilled in current mobile technologies and tools, including Android Studio, Xcode, Kotlin, Xamarin, PhoneGap, and React Native. You can count on us to build innovative mobile apps for your needs.
                        </div>
                    </div>
                    <div className='col-md-2 col-12 mb-3 mb-md-0 order-1 order-md-2'>
                        <div className='links text-center text-md-left'><Link to='/Services'>Our services</Link></div><br />
                        <div className='links text-center text-md-left'><Link to='/Team'>Read our story</Link></div><br />
                        <div className='links text-center text-md-left'><Link to='/contact-us'>Contact us</Link></div>
                    </div>
                </div>
            </div>
        </>
    )
}
