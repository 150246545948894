import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import SmartP from '../../../assets/Smart-Payments-Application.jpg'

export default function SmartPayments() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Smart Payments Application</strong></div>
                    <p className='mt-2 text-center'>Startup based out of Nigeria in tie with government and banking agencies, with objective of simplifying and securing payments for all its users.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>The concept is very unique wherein based on predefined mapping and settings of QR codes with details of the Payer and Payee, Instant payment is done through the application directly from payer to merchant’s account in real time using QR Code secured scanning from the Application.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>iOS (Objective C), Android, ZEND Framework 2, HTML5, Bootstrap</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={SmartP} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We designed and developed Mobile applications in iOS and Android to achieve the said objectives to manage all application Users and their accounts, save and manage their payment accounts and details, manage credits card and details in a very secured and encrypted manner, payment via QR code Scanning generated from Backend and available at Merchant’s store, instant payment over secured channels, Merchants management and services offered rendered on the Application frontend from backend using high performance and efficient web services and other associated features.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>Applications delivered, LIVE and Successful product in the target area of Usage.</p>
                </div>
            </div>
        </div>
    </>
  )
}
