import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import PHPB from '../../../assets/SaaS Based System.jpg'

export default function SaaSBasedSystem() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>SaaS Based System for Cardiac Implants Monitoring</strong></div>
                    <p className='mt-2 text-center'>A startup based out of USA with objective of developing an iOS application in Fantasy Sports Space.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges and the purpose of the project:</div>
                    <p className='mt-4'>
                        To develop SAAS (Software as a Service) Web-based application for the medical industry. The product will mainly target the cardiology department of the hospitals through which they will be able to manage their patients and pacemaker data of the patients.
                    <br/><br/>
                        The system will interact with the following Pacemaker manufacturer’s website through API:
                    </p>
                    <ul>
                        <li>Medtronic</li>
                        <li>St. Jude Medical (Abbott)</li>
                        <li>Boston Scientific</li>
                        <li>Biotronic Web-based system for Nurses, physicians, hospital admin, and super admin (SAAS Product owner).</li>
                    </ul>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>Zend Framework 2.5.0, MySQL 5.5.62, PHP 5.5.9, Angular JS, Mirth andHL7.</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={PHPB} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>
                        1. Challenges regarding Integration with different Pacemaker manufacturers with the health application as all the Pacemaker’s manufacturer provides a different set of messages.<br/><br/>
                        2. AI and ML-related challenges applications include artificial Intelligence and Machine Learning techniques for data analysis.
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}
