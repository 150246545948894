import React from 'react'
import Video from '../../assets/banner-video.mp4'
import '../hero/hero-sec.css'
import { Link } from "react-router-dom";
export default function Hero() {
  return (
    <>
      <section className="outter option1">
        <section className="video-container">
          <video className='main-video' autoPlay muted loop playsinline preload="metadata">
            <source src={Video} type="video/mp4" />
          </video>
          <div className="callout">
            <div className='video-heading'>
              Developing cutting-edge<br />
              digital experiences that<br />
              bring visionary concepts to fruition.
            </div>
            <div className="desc mt-md-3 mt-2">
              Dahooks is a team of strategists, designers, and engineers<br className='d-none d-lg-block' />
              who create digital solutions using human-centric ideas for startups,<br className='d-none d-lg-block' />
              small and mid-sized businesses, and enterprise businesses.<br className='d-none d-lg-block' />
              We help with discovery, design, and development that drive<br className='d-none d-lg-block' />
              measurable business results.
            </div>
            <Link to='/Services' className='read-more'>Read More :-</Link>
          </div>
        </section>
      </section>
    </>
  )
}
