import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import PHPB from '../../../assets/Music-Guessing-Game-Application-1-600x443.jpg'

export default function MusicGuessing() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Music Guessing Game Application</strong></div>
                    <p className='mt-2 text-center'>Client based out of Canada, objective of development of a highly interactive and engaging iOS Application with state of the art User Experience to come up with a Game Application for US and Canada Markets.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>Designing of UI/UX, Realtime Buffering of Music tracks form backend to Application to give engaging and seamless experience to application Users. A Trial room feature where on a selected face, user could try difference accessories like Sunglasses, Different hairstyles, eyes of different colors etc as awards for guessing games and share the same with his/her friends.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>Swift, ZEND Framework 2</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={PHPB} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>With iOS application developed in Swift, implementation of backend in ZEND Framework 2 and highly optimized and normalized Database and development of RESTFUL APIs, we developed a super successful solution for the Client currently on the APPSTORE proudly claiming thousands of Downloads with No Issues or Bugs reported so far.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>Application Live on Appstore, a Super Successful Game application in US and Canada Appstore(s).</p>
                </div>
            </div>
        </div>
    </>
  )
}
