import React, { useEffect, useState } from "react";
import "../digital-commerce/digital-commerce.css";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AI_Img from "../../assets/AI_Img.jpg";
import Improved_automation from "../../assets/Improved automation.png";
import Decision_making from "../../assets/decision-making.png";
import Personalization from "../../assets/personalization.png";
import Increased_Efficiency from "../../assets/Increased Efficiency.jpg";
import AI_Img2 from "../../assets/AI_Img2.jpg";
import Capability from "../../assets/capability.png";
import Adopting_AI from "../../assets/Adopting AI.jpg";
import Gpt from "../../assets/chat-gpt.png";
import Codex from "../../assets/codex.png";
import DALL from "../../assets/DALL-E-2.jpg";
import Vertex from "../../assets/vertex ai.png";
import Cloud from "../../assets/cloud vision.jpg";
import Opencv from "../../assets/opencv.png";
import NLP from "../../assets/Cloudnlp.jpg";
import Cognitive from "../../assets/cognitive.png";
import Bot from "../../assets/bot.png";
import Health from "../../assets/Healthcare.png";
import Legal from "../../assets/Legal.png";
import Education from "../../assets/Education.png";
import Finance from "../../assets/Finance.png";
import MobInsurance from "../../assets/MobInsurance.png";
import WorkProcess from '../../assets/Work_Process _Arrows.svg'
import { useLocation } from "react-router-dom";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";

// Define the AccordionItem component
const AccordionItem = ({ title, content, index, openIndex, setOpenIndex }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const isOpen = index === openIndex;

  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index);
  };
  return (
    <div className="accordion-item">
      <div
        className="accordion-title"
        type="text"
        onClick={toggleAccordion}
        style={{ fontSize: "18px", cursor: "pointer" }}
      >
        <strong>{isOpen ? "- " : "+ "}</strong> <strong>{title}</strong>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
};

const ListItem = () => {

  const additionalSteps = [
    "Identify areas where AI can make an impact",
    "Determine the right type of AI solution",
    "Gather and analyze data",
    "Develop and train an AI model",
    "Implement and monitor the AI solution",
  ];

  return (
    <div className="col-lg-6 col-12">
      {/* ... (other code) */}
      <div className="new-para" style={{ whiteSpace: "nowrap" }}>
        <p>
          <strong>Key steps involved to use AI for any business</strong>
        </p>
        {additionalSteps.map((step, index) => (
          <p key={index}>
            <span className="circle-number">{index + 1}</span>
            <span>{step}</span>
          </p>
        ))}
      </div>
    </div>
  );
};

export default function Digital_Commerce() {
  const pathname = useLocation().pathname;
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [errr, setErrr] = useState("")

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const onSumbit = () => {
    if (name == '' && email == "" && message == "") {
      setErrr("All field are required!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false
    }
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!email.match(emailReg)) {
      setErrr("Please enter valid email!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false
    }

    if (message?.length > 500) {
      setErrr("Messahe should be contain 500 charectors !!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false
    }

    axios.post("https://smtp.taction.in/api/api/v2/send-mail", {
      name: name,
      email: email,
      message: message
    }).then(res => {
      if (res && res?.status == 200) {
        toast.success('Your query is Submitted ', {
          autoClose: 2000,
        });
        setTimeout(() => {
          setOpen(false);
          setEmail("");
          setName("");
          setMessage("")
        }, 2500);
      }

    }).catch((err) => {
      console.log(err)
    })
    console.log("Contact")



  }
  const [openIndex, setOpenIndex] = React.useState(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Artificial Intelligence (AI) Services & Solutions | Dahooks" /><meta property="og:description" content="Dahooks offer Artificial Intelligence (AI) Services & Solutions for your business. Our AI services improve efficiency, enhance customer experiences, and provide insights from data. Get A Free Quote." /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Artificial Intelligence (AI) Services & Solutions | Dahooks" /><meta name="twitter:description" content="Dahooks offer Artificial Intelligence (AI) Services & Solutions for your business. Our AI services improve efficiency, enhance customer experiences, and provide insights from data. Get A Free Quote." /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
        <title>Artificial Intelligence (AI) Services & Solutions | Dahooks</title>
        <link rel="canonical" href="https://dahooks.com/Artificial-Intelligence" />
      </Helmet>
      <ToastContainer style={{ zIndex: 900 }} />
      <div className="container pt-5">
        <div className="row ">
          <div className="col-lg-12 mt-5 py-4">
            <div className="contact-banner-heading p-lg-4  mt-5">
              <h1 style={{ color: "#f4990b" }}>Artificial Intelligence Solutions</h1>
              <div className="headings"style={{fontSize: "28px",textAlign: "center",lineHeight: "70px",}}>
                Transform business with digital commerce and lead confidently
              </div>
              <p>
                Our team of AI engineers, data scientists, and business analysts
                assist companies in implementing AI technologies to enhance
                their operations and maintain a competitive edge. We are
                dedicated to supporting businesses across various industries in
                adopting these technologies. Get A Free Quote
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <img src={AI_Img} width="100%" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 my-auto py-4">
            <div className="contact-banner-heading  p-lg-4  mt-4 ">
              <div className="row">
                <h1>Why should we care about AI?</h1>
              </div>
              <div className="row mt-5">
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Improved_automation} width="60px" />
                  <h3 className="mt-2">Improved automation</h3>
                  <div>
                    <p style={{ textAlign: "left", textAlign: "justify" }}>
                      AI can automate repetitive tasks, freeing up human workers
                      to focus on more complex and creative work.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Decision_making} width="60px" />
                  <h3 className="mt-2">Enhanced decision making</h3>
                  <div>
                    <p style={{ textAlign: "left", textAlign: "justify" }}>
                      AI can analyze large amounts of data quickly and
                      accurately, allowing businesses and individuals to make
                      better decisions.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Capability} width="60px" />
                  <h3 className="mt-2">New capabilities</h3>
                  <div>
                    <p style={{ textAlign: "left", textAlign: "justify" }}>
                      AI can enable new capabilities that were previously
                      impossible, such as image and speech recognition, natural
                      language processing, and autonomous systems.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Personalization} width="60px" />
                  <h3 className="mt-2">Increased personalization</h3>
                  <div>
                    <p style={{ textAlign: "left", textAlign: "justify" }}>
                      AI can analyze user data and preferences to deliver
                      personalized recommendations and experiences.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Increased_Efficiency} width="60px" />
                  <h3 className="mt-2">Improved efficiency</h3>
                  <div>
                    <p style={{ textAlign: "left", textAlign: "justify" }}>
                      AI can optimize systems and processes, reducing waste and
                      increasing efficiency.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="para" style={{ textAlign: "center" }}>
          <p>
            AI has revolutionized industries with its ability to enable
            predictive analytics, natural language processing, computer vision,
            and robotic process automation. Its potential to unlock new
            insights, enhance customer experiences, and increase efficiency is
            unmatched.
          </p>
        </div>
      </div>
      <div className="background py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div>
                <img className="kl_img" src={AI_Img2} alt="" width={"100%"}/>
              </div>
            </div>
            <div className="col-lg-6 col-12 my-auto">
              <div className="service-content">
                <p className="headings" style={{ textAlign: "left", color: "#f4990b" }}>KEY TERMINOLOGIES</p>
                <h1>Getting Familiar with AI</h1>
                <AccordionItem
                  title="Artificial Intelligence (AI)"
                  content="The ability of machines to perform tasks that would normally require human intelligence, such as learning, problem-solving, and decision-making.With the increasing use of mobile devices for online shopping, it's essential to have a mobile strategy that meets the demands of your customers. Dahooks can help you create mobile apps that deliver a seamless experience for your customers, while providing you with real-time data to help you make informed decisions."
                  index={1}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Machine Learning (ML)"
                  content="A subset of AI that involves using algorithms to analyze and learn from data without being explicitly programmed.."
                  index={2}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Deep Learning"
                  content="A subset of ML that uses neural networks to model and solve complex problems."
                  index={3}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />
                
                <AccordionItem
                  title="Natural Language Processing (NLP)"
                  content="A field of AI that focuses on the interaction between humans and computers using natural language."
                  index={4}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Computer Vision"
                  content="A field of AI that enables computers to interpret and understand visual data from the world, such as images and videos."
                  index={5}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Robotics"
                  content="A field of AI that involves designing and programming robots to perform tasks autonomously or with minimal human intervention."
                  index={6}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Chatbot"
                  content="A computer program designed to simulate conversation with human users, often used for customer service or information retrieval."
                  index={7}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Neural Network"
                  content="A type of ML algorithm modeled after the structure of the human brain, consisting of layers of interconnected nodes that can learn from and process data."
                  index={8}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Big Data"
                  content="A term used to describe the massive amounts of structured and unstructured data that are generated by modern businesses and organizations, often used as input for AI models."
                  index={9}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />

                <AccordionItem
                  title="Algorithm"
                  content="A set of rules or instructions that a computer program follows to perform a specific task, often used in AI to analyze data and make predictions or decisions."
                  index={10}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center '>
            <img className="kl_img" src={WorkProcess} alt="" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12 order-2 order-lg-1">
            <p className="headings mt-5" style={{ textAlign: "left", color: "#f4990b" }}>STEPS TO USE AI</p>
            <h1>Adopting AI</h1>
            <p>
              Adopting AI doesn't have to be complicated. At Dahooks, we work
              with companies to understand their unique needs and develop custom
              AI solutions that fit their specific requirements.
            </p>
            <div className="new-para">
              <ListItem />
            </div>
          </div>
          <div className="col-lg-6 col-12 my-auto order-1 order-lg-2">
            <img className="kl_img" src={Adopting_AI} alt="" width="100%" />
          </div>
        </div>
      </div>
      <div className="background py-5 mt-5">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 my-auto">
              <div className="contact-banner-heading p-lg-4" style={{ textAlign: "center" }}>
                <h1 >Dahooks helps businesses develop a technology transformation strategy that includes AI.</h1>
                <p>We identify areas where AI can make the most impact and create a roadmap for implementation. Our AI services improve efficiency, enhance customer experiences, and provide insights from data.</p>
                <button onClick={onOpenModal} className="btn btn-success" type="submit">Contact Us</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6 col-12 my-auto">
            <h1>AI Tools for Seamless Integration and Model Building.</h1>
            <p>
              AI platforms provide ML model building tools and APIs for integration into other applications. They can be industry-specific or general-purpose, and deployed on cloud, on-premises, or edge devices. Platforms offer both graphical user interface and command-line interface. They provide a range of options for developers and organizations to leverage AI in their applications and services.
            </p>
          </div>
          <div className="col-lg-6 col-12 my-auto">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mt-3 mt-lg-0">
                <div className="card AI">
                  <div className="text-center my-auto justify-content-center d-flex" style={{ height: "160px" }}>
                    <div className="my-auto">
                      <img src={Gpt} width="60px" />
                      <p>GPT 3.5 and 4</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-3 mt-lg-0">
                <div className="card AI">
                  <div className="text-center my-auto justify-content-center d-flex" style={{ height: "160px" }}>
                    <div className="my-auto">
                      <img src={Codex} width="60px" />
                      <p>Codex</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-3 mt-lg-0"  >
                <div className="card AI">
                  <div className="text-center my-auto justify-content-center d-flex" style={{ height: "160px" }}>
                    <div className="my-auto">
                      <img src={DALL} width="60px" ></img>
                      <p>DALL E</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-3"  >
                <div className="card AI">
                  <div className="text-center my-auto justify-content-center d-flex" style={{ height: "160px" }}>
                    <div className="my-auto">
                      <img src={Vertex} width="80px" ></img>
                      <p>Vertex AI</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-3"  >
                <div className="card AI">
                  <div className="text-center my-auto justify-content-center d-flex" style={{ height: "160px" }}>
                    <div className="my-auto">
                      <img src={Cloud} width="60px" ></img>
                      <p>Cloud Vision</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-3" >
                <div className="card AI">
                  <div className="text-center my-auto justify-content-center d-flex" style={{ height: "160px" }}>
                    <div className="my-auto">
                      <img src={Opencv} width="60px" ></img>
                      <p>OpenCV</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-3" >
                <div className="card AI">
                  <div className="text-center my-auto justify-content-center d-flex" style={{ height: "160px" }}>
                    <div className="my-auto">
                      <img src={NLP} width="60px" ></img>
                      <p>Cloud Natural Language</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-3"  >
                <div className="card AI">
                  <div className="text-center my-auto justify-content-center d-flex" style={{ height: "160px" }}>
                    <div className="my-auto">
                      <img src={Cognitive} width="60px" ></img>
                      <p>Cognitive Services</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 mt-3"  >
                <div className="card AI">
                  <div className="text-center my-auto justify-content-center d-flex" style={{ height: "160px" }}>
                    <div className="my-auto">
                      <img src={Bot} width="60px" ></img>
                      <p>Bot Framework</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="background py-5">
        <div className="container-fluid">
          <div className="head" style={{ textAlign: "center" }}>
            <p style={{ color: "#f4990b" }}>AI FOR BUSINESS </p>
            <h1 className="mb-5">Drive growth and unlock new <br />opportunities with Artificial<b /> Intelligence.</h1>
          </div>
          <div className="row">
            <Slider className="d-none d-lg-block" {...settings1}>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services AI" style={{ width: "18rem" }}>
                  <img src={Health} className="card-img-top card-image " alt="..." />
                  <div className="card-body">
                    <div className="card-title">Healthcare</div>
                    <div className="card-text">Modern technology has changed the financial industry dramatically. This has changed the way people invest, manage their money and behave financially. Exciting innovations such as algorithmic trading</div>
                    <div className='read-more pt-2'><Link to='/Healthcare'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services web-card" style={{ width: "18rem" }}>
                  <img src={Legal} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Legal</div>
                    <div className="card-text">Adopting contemporary solutions is crucial to staying ahead of the issues the legal business faces, which include expanding complexity, rising prices, and the demand for quicker and more efficient</div>
                    <div className='read-more pt-2 '><Link to='/Legal'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services mobile-app-card" style={{ width: "18rem" }}>
                  <img src={Education} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Education</div>
                    <div className="card-text">The educational system needs to use cutting-edge approaches like distance learning, adaptive technologies, and individualized learning to satisfy the varied demands of today's pupils.</div>
                    <div className='read-more pt-2 '><Link to='/Education'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services ui-card" style={{ width: "18rem" }}>
                  <img src={Finance} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Finance</div>
                    <div className="card-text">The finance business has seen tremendous transformation as a result of modern technology, which has completely changed how people invest, manage their money, and carry out financial transactions.</div>
                    <div className='read-more pt-2 '><Link to='/Finance'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services AI" style={{ width: "18rem" }}>
                  <img src={MobInsurance} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Insurance</div>
                    <div className="card-text">To stay ahead of the competition and meet the evolving requirements of their customers in today's fast-paced world, insurance companies must employ cutting-edge solutions.</div>
                    <div className='read-more pt-2 '><Link to='/Insurance'>Read More-</Link></div>
                  </div>
                </div>
              </div>
            </Slider>
            <Slider className="d-none d-md-block d-lg-none" {...settings}>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services AI" style={{ width: "18rem" }}>
                  <img src={Health} className="card-img-top card-image " alt="..." />
                  <div className="card-body">
                    <div className="card-title">Healthcare</div>
                    <div className="card-text">Modern technology has changed the financial industry dramatically. This has changed the way people invest, manage their money and behave financially. Exciting innovations such as algorithmic trading</div>
                    <div className='read-more pt-2'><Link to='/Healthcare'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services web-card" style={{ width: "18rem" }}>
                  <img src={Legal} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Legal</div>
                    <div className="card-text">Adopting contemporary solutions is crucial to staying ahead of the issues the legal business faces, which include expanding complexity, rising prices, and the demand for quicker and more efficient</div>
                    <div className='read-more pt-2 '><Link to='/Legal'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services mobile-app-card" style={{ width: "18rem" }}>
                  <img src={Education} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Education</div>
                    <div className="card-text">The educational system needs to use cutting-edge approaches like distance learning, adaptive technologies, and individualized learning to satisfy the varied demands of today's pupils.</div>
                    <div className='read-more pt-2 '><Link to='/Education'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services ui-card" style={{ width: "18rem" }}>
                  <img src={Finance} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Finance</div>
                    <div className="card-text">The finance business has seen tremendous transformation as a result of modern technology, which has completely changed how people invest, manage their money, and carry out financial transactions.</div>
                    <div className='read-more pt-2 '><Link to='/Finance'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services AI" style={{ width: "18rem" }}>
                  <img src={MobInsurance} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Insurance</div>
                    <div className="card-text">To stay ahead of the competition and meet the evolving requirements of their customers in today's fast-paced world, insurance companies must employ cutting-edge solutions.</div>
                    <div className='read-more pt-2 '><Link to='/Insurance'>Read More-</Link></div>
                  </div>
                </div>
              </div>
            </Slider>
            <Slider className="d-md-none" {...settings2}>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services AI" style={{ width: "18rem" }}>
                  <img src={Health} className="card-img-top card-image " alt="..." />
                  <div className="card-body">
                    <div className="card-title">Healthcare</div>
                    <div className="card-text">Modern technology has changed the financial industry dramatically. This has changed the way people invest, manage their money and behave financially. Exciting innovations such as algorithmic trading</div>
                    <div className='read-more pt-2'><Link to='/Healthcare'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services web-card" style={{ width: "18rem" }}>
                  <img src={Legal} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Legal</div>
                    <div className="card-text">Adopting contemporary solutions is crucial to staying ahead of the issues the legal business faces, which include expanding complexity, rising prices, and the demand for quicker and more efficient</div>
                    <div className='read-more pt-2 '><Link to='/Legal'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services mobile-app-card" style={{ width: "18rem" }}>
                  <img src={Education} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Education</div>
                    <div className="card-text">The educational system needs to use cutting-edge approaches like distance learning, adaptive technologies, and individualized learning to satisfy the varied demands of today's pupils.</div>
                    <div className='read-more pt-2 '><Link to='/Education'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services ui-card" style={{ width: "18rem" }}>
                  <img src={Finance} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Finance</div>
                    <div className="card-text">The finance business has seen tremendous transformation as a result of modern technology, which has completely changed how people invest, manage their money, and carry out financial transactions.</div>
                    <div className='read-more pt-2 '><Link to='/Finance'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services AI" style={{ width: "18rem" }}>
                  <img src={MobInsurance} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Insurance</div>
                    <div className="card-text">To stay ahead of the competition and meet the evolving requirements of their customers in today's fast-paced world, insurance companies must employ cutting-edge solutions.</div>
                    <div className='read-more pt-2 '><Link to='/Insurance'>Read More-</Link></div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className='container py-5'>
        <div className='row'>
          <div className="col-lg-6 col-12"  >
            <div className="tagline">
              <p style={{ color: "#f4990b" }}>OUR SERVICES</p>
              <h1> <strong>How we can help you<br /> harness the power of AI<br /> for your business.</strong></h1>
            </div>
          </div>
          <div className='col-lg-6 col-12'>
            <div className="service-content" >
              <p><strong>AI Strategy and Consulting</strong></p>
              <p>Tailored solutions for organizations to leverage artificial intelligence, optimize processes, enhance decision-making, and achieve competitive advantages.</p>

              <p><strong>Data analytics and insights</strong></p>
              <p>Data analytics and insights involve analyzing data to extract valuable information and make informed decisions for business improvements.</p>

              <p><strong>Natural Language Processing (NLP) Solutions</strong></p>
              <p>AI techniques analyzing, understanding, and generating human language, powering chatbots, language translation, sentiment analysis, and more.</p>

              <p><strong>Computer vision and image recognition</strong></p>
              <p>AI field, interpreting images/videos, extracting info. Image recognition: Identifying objects/patterns in images using ML algorithms.</p>

              <p><strong>Robotic process automation (RPA)</strong></p>
              <p>Automates tasks with software bots, mimicking human actions for increased efficiency and accuracy in business processes.</p>

              <p><strong>Predictive analytics and modeling</strong></p>
              <p>Predictive analytics and modeling use data to forecast future outcomes and make informed decisions for various industries and applications.</p>

              <p><strong>Machine learning and deep learning</strong></p>
              <p>Algorithms learn from data, make predictions, and improve performance through experience without explicit programming.</p>

              <p><strong>Sentiment Analysis Solutions</strong></p>
              <p>AI-based tools that analyze text to determine emotions (positive/negative/neutral) for business insights and customer feedback.</p>

              <p><strong>Voice enabled AI Solutions</strong></p>
              <p>Voice-enabled AI solutions enable natural language interaction with devices, using speech recognition and AI to perform tasks and answer questions.</p>
            </div>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} center style={{ background: '#f2f2f2' }}>
        <div className="container-modal">
          <div className="text-center contact">Contact Us</div>
          <form action="/action_page.php">
            <label className="label-text" for="fname">Name <sup style={{ color: 'red' }}>*</sup></label>
            <input type="text" onChange={(e) => {
              setName(e.target.value)
            }} value={name} id="fname" name="firstname" placeholder="Enter Your Name.." />

            <label className="label-text" for="lname">Email<sup style={{ color: 'red' }}>*</sup></label>
            <input type="text" onChange={(e) => {
              setEmail(e.target.value)
            }} value={email} id="lname" name="lastname" placeholder="Enter Your Email.." />
            <label className="label-text" for="subject">Message<sup style={{ color: 'red' }}>*</sup></label>
            <textarea onChange={(e) => {
              if (e.target.value?.length > 500) {
                setErrr("Messahe should be contain 500 charectors !!");
                setTimeout(() => {
                  setErrr("");
                }, 3000);
                return false
              }
              else {
                setMessage(e.target.value)
              }
            }} id="subject" value={message} name="subject" placeholder="Write something.." style={{ height: '100px' }}></textarea>
            <div className="text-center">
              <button onClick={onSumbit} type="button" className="btn btn-primary" style={{ width: '100%' }}>Submit</button>
            </div>
            <div style={{ height: 20, color: "red", fontSize: 12, marginTop: 5 }}>{errr ? errr : ""}</div>
          </form>
        </div>
      </Modal>
    </>
  );
}