import React from 'react';
import { Link } from 'react-router-dom';
import WarumLeer from '../../../assets/Warumleer_inside.jpg';

export default function WarumLeerVehicleTransport() {
    return (
        <>
            <div className='container pt-4'>
                <div className='row pt-5'>
                    <div className='col-12 mt-5 pt-5'>
                        <div className='mx-5 heading-text text-center my-3'><strong>WarumLeer - Affordable and Efficient Vehicle Transport</strong></div>
                        <p className='mt-2 text-center'>WarumLeer, a Munich-based startup, provides an innovative solution for transporting vehicles efficiently and affordably by connecting vehicle owners with travelers. This peer-to-peer marketplace eliminates the need for traditional transport companies, cutting costs and optimizing routes.</p>
                    </div>
                </div>

                {/* Problem Statement */}
                <div className='row mt-5'>
                    <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                        <div className='Vehicle-text mt-0'>Challenges:</div>
                        <p className='mt-4'>Transporting a vehicle from one city to another often involves high costs, significant coordination, and lengthy processes. Traditional transport companies charge premiums, making it an inconvenient option for many vehicle owners.</p>
                        <div className='Vehicle-text'>Technology:</div>
                        <p className='mt-4'>React JS, Node JS, MySQL, Google Map APIs</p>
                    </div>
                    <div className='col-lg-6 order-1 order-lg-2'>
                        <div><img src={WarumLeer} width={"100%"} alt="WarumLeer vehicle transport" /></div>
                    </div>
                </div>

                {/* Solution Overview */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Solution:</div>
                        <p className='mt-4'>Taction Software developed a peer-to-peer platform for WarumLeer that connects vehicle owners with travelers, making the vehicle transport process more affordable and efficient. By leveraging existing transportation networks, this solution eliminates intermediaries and streamlines logistics.</p>
                    </div>
                </div>

                {/* Key Features and Functionalities */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Key Features and Functionalities:</div>
                        <div className='mt-4'>
                            The WarumLeer platform simplifies the process of vehicle transportation:
                            <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                                <li><strong>Request a Ride:</strong> Vehicle owners can request transportation through the WarumLeer app, providing details such as pickup and drop-off locations, vehicle specifics, and preferred times.</li>
                                <li><strong>Matchmaking Algorithm:</strong> WarumLeer uses an intelligent algorithm to match vehicle owners with available drivers based on distance, fuel efficiency, and schedule alignment.</li>
                                <li><strong>Safe Transportation:</strong> The driver collects the vehicle and ensures its safe and secure delivery to the destination city.</li>
                                <li><strong>Real-Time Tracking:</strong> The platform provides real-time updates, enabling users to track their vehicle throughout the journey.</li>
                            </ul>
                        </div>

                    </div>
                </div>

                {/* Outcome and Impact */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Outcome and Impact:</div>
                        <p className='mt-4'>
                            The pilot program for WarumLeer showed remarkable success in reducing transportation costs and improving efficiency. The average cost per kilometer was reduced by 47.2% compared to traditional methods. Additionally, the platform's route optimization reduced travel time by 31.4%, contributing to higher customer satisfaction rates, which averaged 92.5%. By connecting vehicle owners with suitable drivers, WarumLeer transformed the traditional vehicle transportation model into a more cost-effective and time-efficient solution.
                        </p>
                    </div>
                </div>

                {/* Evaluation and Impact */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Evaluation and Impact:</div>
                        <p className='mt-4'>
                            The WarumLeer platform has successfully transported over 1,500 vehicles, with an average cost reduction of 43.1% compared to conventional transport services. Customer satisfaction rates reached an impressive 92.5%, with users praising the ease of use, real-time tracking, and efficient delivery processes. Moreover, optimized route algorithms led to a 31.4% reduction in travel time. Taction’s solution has redefined vehicle transport, offering an affordable, efficient, and customer-friendly alternative for private vehicle owners.
                        </p>
                    </div>
                </div>

                {/* Conclusion */}
                <div className='row'>
                    <div className='col-12 mb-5'>
                        <div className='Vehicle-text'>Conclusion:</div>
                        <p className='mt-4'>
                            WarumLeer's peer-to-peer platform, developed by Taction Software, offers a game-changing solution for vehicle transportation, significantly reducing costs, improving efficiency, and delivering higher user satisfaction. With over 1,500 vehicles transported and a 92.5% satisfaction rate, WarumLeer has demonstrated its ability to provide a practical, scalable, and cost-effective solution for private vehicle transport.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
