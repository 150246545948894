import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import PHPB from '../../../assets/Fantasy Sports Application.jpg'

export default function FantastSportsApplication() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Fantasy Sports Application</strong></div>
                    <p className='mt-2 text-center'>A startup based out of USA with objective of developing an iOS application in Fantasy Sports Space.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>Implementing features of Virtual Fantasy sports consuming Real-time Data from Web Services providing feeds of Scores of Matches and Leagues happening globally so that all participating application users can internally in the application launch sessions and matches making their own teams and defining estimated scores throwing challenges on all other users in that group involving virtual currency.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>ZEND Framework 2, MySQL for Backend, Objective C for iOS Application</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={PHPB} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>Development of a robust Backend in ZEND FrameWork 2 with Managing Data in real time from multiple sources consuming Real-time feeds, implementing Logical calculations across data received and rendering Output to Users with no loss of Time and triggering related events in the Solution. The Solution demanded a very Light and efficient logic implementation to manage all real time data management and the same was accomplished successfully by sour technology architects and developers to deliver a robust and highly performing mobility solution used globally.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>iOS Application launched on Appstore, flexing its muscles Fantasy Sports space with lots of Positive reviews by it’s users.</p>
                </div>
            </div>
        </div>
    </>
  )
}
