import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import OrderMa from '../../../assets/Order-Manage.jpg'

export default function OrderM() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Order Management and Contract Management Automation using Customized SugarCRM</strong></div>
                    <p className='mt-2 text-center'>A US based all-in-one publishing house and advertising agency which has their primary focus on self-improvement and personal development. Being one of the fastest growing publishing house established in 2006, it has been a necessity for them to manage their web presence effectively and has about 20+ odd product sites as one of the primary lead sources.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>Client was looking for existing SugarCRM customization to automate their sales, order, invoice management processes and also third party payment gateway integration.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>SugarCRM, NMI, Talend ETL</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={OrderMa} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We got engaged for SugarCRM customization and implementation work to automate their sales, order, invoice management processes. To develop a centralized data mine using SugarCRM 7 professional, multiple custom modules had to be developed including a load balancer module to help in choosing a Merchant Account based upon limits available etc. Apart from these, integration of SugarCRM with NMI payment gateway for accepting credit card payments, custom web services development for the Orders to directly come from the product sites etc. were also part of the said integrated platform design and development work.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The solution offered the sales team an integrated platform to operate more effectively and met aggressive sales targets with up-to-date data readily available on few clicks. The SugarCRM instance also worked as the central repository of data associating and consolidating all their product sites and other lead sources.</p>
                </div>
            </div>
        </div>
    </>
  )
}
