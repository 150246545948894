import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import CHIPSS from '../../../assets/Chipss_inside.jpg'

export default function CollaborationHealthInfo() {
    return (
        <>
            <div className='container pt-4'>
                <div className='row pt-5'>
                    <div className='col-12 mt-5 pt-5'>
                        <div className='mx-5 heading-text text-center my-3'><strong>Collaboration Health Information for Peer Support Specialists (CHIPSS)</strong></div>
                        <p className='mt-2 text-center'>The University of Alabama at Birmingham, USA, partnered with Taction Software to develop a platform that aids Opioid Peer Support Specialists (OPSS) in tracking user progress, identifying areas of need, and guiding referrals.</p>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                        <div className='Vehicle-text mt-0'>Challenges:</div>
                        <p className='mt-4'>Opioid addiction is a significant public health crisis in the United States, with over 2 million people suffering from substance abuse. Peer support specialists play a vital role in helping individuals recover from addiction, but it was challenging for them to track user progress and provide targeted support. Traditional paper-based record-keeping methods were often insufficient, leading to gaps in data and a lack of personalized support.</p>
                        <div className='Vehicle-text'>Technology:</div>
                        <p className='mt-4'>React JS, HTML, CSS, jQuery, Pimcore, WS-O2 integration</p>
                    </div>
                    <div className='col-lg-6 order-1 order-lg-2'>
                        <div><img src={CHIPSS} width={"75%"} height={'56%'} alt="CHIPSS Case Study" /></div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Solution:</div>
                        <p className='mt-4'>CHIPSS is a web-based platform designed for OPSSs to monitor client journeys from intake to recovery. It includes tools to assess abuse history, suicide severity, anxiety disorders, and health questionnaires, enabling Peer Support Specialists to provide better-targeted care and make seamless referrals.</p>
                    </div>

                    <div className='col-12'>
                        <div className='Vehicle-text'>Key Features and Functionalities:</div>
                        <ul className='mt-4'>
                            <li><strong>Data-Driven Insights:</strong> CHIPSS provides actionable insights and data visualization tools that enable OPSSs to identify areas of improvement, track user progress, and optimize their support strategies.</li>
                            <li><strong>Seamless Referral System:</strong> CHIPSS integrates with various external systems, allowing OPSSs to swiftly refer clients to necessary resources, such as medical treatment, counselling services, and support groups.</li>
                            <li><strong>Encrypted Data Storage:</strong> CHIPSS employs HL7-based message exchange, ensuring that all data is securely stored and transmitted, adhering to HIPAA guidelines and regulations.</li>
                            <li><strong>Role-Based Access Control:</strong> The platform provides customized user roles, ensuring that sensitive patient information is accessible only to authorized OPSSs and healthcare professionals.</li>
                        </ul>

                    </div>
                    <div className='col-12 mb-5'>
                        <div className='Vehicle-text'>Outcome and Impact:</div>
                        <p className='mt-4'>
                            Since its deployment, CHIPSS has significantly improved client engagement by empowering individuals undergoing treatment to access and manage their own health data. This sense of control has led to enhanced collaboration between Opioid Peer Support Specialists (OPSSs) and other healthcare professionals, ensuring a comprehensive and coordinated treatment approach. Additionally, CHIPSS has enabled OPSSs to provide more targeted support, leading to better patient outcomes, reduced relapse rates, and overall improved treatment adherence.
                        </p>
                        <p>
                            A study conducted by the national practice center reported a 25% increase in treatment adherence rates among clients using CHIPSS. OPSSs also reported enhanced communication with their clients, noting a better understanding of client needs and concerns. Moreover, a pilot study conducted with a leading healthcare organization revealed a 20% reduction in hospital readmissions among CHIPSS patients. The platform’s secure, HIPAA-compliant design has further ensured the safe handling of sensitive data, making CHIPSS an essential tool in improving patient outcomes within the opioid treatment landscape.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
