import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import Tele from '../../../assets/telephone.jpg'

export default function Telephone() {
  return (
    <>
        <div className='container pt-4 telephone'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>SuiteCRM Asterisk Telephony Integration</strong></div>
                    <p className='mt-2 text-center'>A Leading US based Financial Institution that deals in the lending business with more than 1000 offices across the US.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>The client wanted Call Center Automation to manage their telephonic conversations with the leads and customers effectively having a tightly integrated platform of Telephone and the backend SuiteCRM system. They wanted to achieve a seamless automated for inbound and outbound calls along with a provision of capturing call notes, call to lead creation in CRM, Call transfer and Call recording functionality either to monitor the performance of their agents or for regulatory compliance.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>SuiteCRM, Asterisk, Zoiper</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={Tele} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>As the most cost-effective and integrated solution landscape for the above-mentioned requirements, we proposed to leverage the open source tools like Asterisk (an open source telephony switching and Private Branch Exchange service for Linux) and Zoiper (a free softphone to make VoIP calls through a PBX) integrated with the existing SuiteCRM instance. The SuiteCRM-Asterisk Telephony Integration solution supports click-to-dial or click-to-call functionality, empowering the call center representatives in dialing the registered number(s) directly from the click-to-call functionality, empowering the call center representatives in dialing the registered number(s) directly from the SuiteCRM interface without going to softphone dialer interface. The solution supports other features like call transfer in case of any need for additional support from other rep or higher authority involvement, call recording as an audio file for future reference, automatic call scheduling as per availabilities of the call center reps etc.</p>
                    <p><strong>As a summary, the solution included the following features:</strong></p>
                    <ul>
                        <li>Incoming Call integration into SuiteCRM</li>
                        <li>Outgoing Call integration from SuiteCRM</li>
                        <li>Soft Phone Integration with Zoiper as the Soft Phone</li>
                        <li>Call Recording saved in SuiteCRM</li>
                        <li>Call Saving and auto creation of call notes as a historical record in SuiteCRM</li>
                        <li>Automatic Lead Creation into the SuiteCRM</li>
                        <li>Call Association with Leads, Account and Contacts</li>
                        <li>Contact Center Automation</li>
                    </ul>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The implementation enabled the client’s representatives to receive the incoming calls with demographic details of the existing contacts/ customers with a single click option to view all previous collaboration details directly in the SuiteCRM. It also enabled them in creating call notes associating with the contact as history items saving the link to the audio file of the recorded call. It allowed them in creating leads directly in the SuiteCRM from the call receive interface in case of a call coming in from an unregistered telephone number. The SuiteCRM-Asterisk Telephony Integration solution has improved the efficiency of the customer relationship management process of the client dramatically with reduced individual dependency on the representatives and helped them in having well informed and personalized call conversations with the leads and customers.</p>
                </div>
            </div>
        </div>
    </>
  )
}
