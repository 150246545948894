import React, { useEffect } from "react";
import "../digital-commerce/digital-commerce.css";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Business_Automation from "../../assets/Business-Automation.jpg";
import Improved_Efficiency from "../../assets/Improved Efficiency and Productivity.png";
import Enhanced_Customer from "../../assets/Enhanced Customer Experience.png";
import Accuracy from "../../assets/Accuracy.png";
import Data_Management from "../../assets/data-management.png";
import Cost_Savings from "../../assets/Cost Savings.jpg";
import Adaptation from "../../assets/adaptation.png";
import Analysis_Advisory from "../../assets/Analysis  Advisory.jpg";
import Design_Develop from "../../assets/Design Develop.jpg";
import Implement_Deploy from "../../assets/Implement Deploy.jpg";
import Optimization_Improvement from "../../assets/Optimization  Improvement.jpg";
import Health from "../../assets/Healthcare.png";
import Legal from "../../assets/Legal.png";
import Education from "../../assets/Education.png";
import Finance from "../../assets/Finance.png";
import MobInsurance from "../../assets/MobInsurance.png";
import CRM_Education from "../../assets/CRM_Education.png";
import CRM_Finance from "../../assets/CRM_Finance.png";
import Dot_Net from '../../assets/Dot_Net.png'
import CRM_Health from '../../assets/CRM_Health.png'
import CRM_Publication from '../../assets/CRM_INV.png'
import CRM_ETL from '../../assets/CRM_ETL.png'
import CRM_Finance1 from '../../assets/CRM_Finance1.png'
import CRM_Inventory from '../../assets/CRM_Inventory.png'
import { Helmet } from "react-helmet";

const AccordionItem = ({ title, content, index, openIndex, setOpenIndex }) => {
  const isOpen = index === openIndex;
  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index);
  };
  return (
    <div className="accordion-item">
      <div
        className="accordion-title"
        type="text"
        onClick={toggleAccordion}
        style={{ fontSize: "18px", cursor: "pointer" }}
      >
        <strong>{isOpen ? "- " : "+ "}</strong> <strong>{title}</strong>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default function Digital_Commerce() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const [openIndex, setOpenIndex] = React.useState(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Business Process Automation (BPA) Services Company - Dahooks" /><meta property="og:description" content="Dahooks Provide Business Automation Services to automate various company processes and functions. Business Automation Services may save time, decrease errors, boost efficiency, and cut expenses." /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Business Process Automation (BPA) Services Company - Dahooks" /><meta name="twitter:description" content="Dahooks Provide Business Automation Services to automate various company processes and functions. Business Automation Services may save time, decrease errors, boost efficiency, and cut expenses." /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
        <title>Business Process Automation (BPA) Services Company - Dahooks</title>
        <link rel="canonical" href="https://dahooks.com/Business-Automation" />
      </Helmet>
      <div className="container pt-5">
        <div className="row ">
          <div className="col-lg-12 mt-5 py-4">
            <div className="contact-banner-heading p-lg-4  mt-5">
              <h1 style={{ color: "#f4990b" }}>Business Automation Services</h1>
              <div className="headings" style={{ fontSize: "28px", textAlign: "center", lineHeight: "70px", }}>Transform your business with automation and lead boldly.</div>
              <p>
                company automation is the use of technology to automate various company processes and functions, thereby lowering manual labor, minimizing errors, and increasing operational efficiency. This increases business productivity and profitability by freeing up resources to focus on important goals.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <img src={Business_Automation} width="100%" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 my-auto py-4">
            <div className="contact-banner-heading  p-lg-4  mt-4 ">
              <div className="row">
                <h1>Key Advantages of Business Automation</h1>
                <p>Automation may save time, decrease errors, boost efficiency, and cut expenses. It can also increase product quality, consistency, and scalability.</p>
              </div>
              <div className="row mt-5">
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Improved_Efficiency} width="60px" />
                  <h3 className="mt-2">
                    Improved Productivity & Efficiency
                  </h3>
                  <div>
                    <p style={{ textAlign: "left", textAlign: "justify" }}>
                      Automation minimizes the need for manual intervention, lowering the risk of human error and speeding up the process.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Enhanced_Customer} width="60px" />
                  <h3 className="mt-2">
                    Improved Client Relationship
                  </h3>
                  <div>
                    <p style={{ textAlign: "left", textAlign: "justify" }}>
                      Process optimization and tailored interactions are two ways that business automation can enhance the customer experience.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Accuracy} width="60px" />
                  <h3 className="mt-2">
                    Improved Accuracy and Quality
                  </h3>
                  <div>
                    <p style={{ textAlign: "left", textAlign: "justify" }}>
                      Boost corporate automation by using data-driven decision-making and process optimization to increase quality and accuracy.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Data_Management} width="60px" />
                  <h3 className="mt-2">
                    Improved Analysis and Management of Data
                  </h3>
                  <div>
                    <p style={{ textAlign: "left", textAlign: "justify" }}>
                      Enhancing corporate automation and efficiency can be achieved through optimizing data management and analysis procedures.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Cost_Savings} width="60px" />
                  <h3 className="mt-2">
                    Financial Savings and Investment Return
                  </h3>
                  <div>
                    <p style={{ textAlign: "left", textAlign: "justify" }}>
                      By boosting productivity and efficiency, business automation can reduce costs and increase return on investment.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                  <img src={Adaptation} width="60px" />
                  <h3 className="mt-2">
                    Enhanced Adaptability and Agility
                  </h3>
                  <div>
                    <p style={{ textAlign: "left", textAlign: "justify" }}>
                      Business automation improves agility and flexibility, allowing for rapid reactions to changes and difficulties
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-5'>
        <div className='row'>
          <div className="col-lg-6 col-12"  >
            <div className="tagline">
              <p style={{ color: "#f4990b" }}>OUR SERVICES FOR BUSINESS AUTOMATION</p>
              <h1>Business automation can be used for a variety of operations, including customer service, manufacturing, accountancy, and more.</h1>
            </div>
          </div>
          <div className='col-lg-6 col-12'>
            <div className="service-content" >
              <p><strong>Workflow supervision.</strong></p>
              <p>Makes it possible for companies to control and optimize their workflows, guaranteeing that projects are finished quickly and effectively.</p>

              <p><strong>Administration of documents.</strong></p>
              <p>Aids companies in centrally managing, storing, and organizing records to guarantee safe and convenient storage.</p>

              <p><strong>Automation and scheduling of tasks.</strong></p>
              <p>Automates time-consuming and repetitive tasks to free up staff' time for more critical work.</p>

              <p><strong>Engine for business rules.</strong></p>
              <p>Gives companies the ability to specify and automate intricate business procedures and standards, guaranteeing precision and consistency.</p>

              <p><strong>Reporting and analytics.</strong></p>
              <p>Offers data-driven decision-making tools and insights to businesses, enhancing their overall efficacy and efficiency.</p>

              <p><strong>Combining with other systems.</strong></p>
              <p>reduces the need for human data entry and increases the accuracy of data by enabling enterprises to connect and share data between various systems.</p>

              <p><strong>Permissions and user control.</strong></p>
              <p>Makes sure that only authorized users have access to sensitive data and functions by controlling access to them.</p>

              <p><strong>Access control and data security.</strong></p>
              <p>Puts security measures in place to guard against data breaches and unwanted access to sensitive information.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-12 my-auto py-4">
            <div className="contact-banner-heading p-lg-4  mt-4">
              <p style={{ color: "#f4990b" }}>
                OUR BUSINESS AUTOMATION PROCESS
              </p>
              <h1>
                Technology streamlines operations, lowers manual labor, boosts productivity, and automates business.
              </h1>
            </div>
          </div>
          <div className="row">
            <Slider className="d-none d-lg-block" {...settings3}>
              <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                <div className="slide" style={{ width: "16rem" }}>
                  <img src={Analysis_Advisory} alt='' width="100%" />
                  <h3 className="mt-2">Evaluation and Counseling</h3>
                  <div className="service-points">
                    <ul>
                      <li>Determine which parts of the company stand to gain from automation.</li>
                      <li>Examine current procedures and pinpoint any problems.</li>
                      <li>Describe the aims and purposes of automation.</li>
                      <li>Create a roadmap for automation that includes deadlines and goals.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                <div className="slide" style={{ width: "16rem" }}>
                  <img src={Design_Develop} alt='' width="100%" />
                  <h3 className="mt-2">Creation & Design</h3>
                  <div className="service-points">
                    <ul>
                      <li>Create the workflow and architecture for the automation system.</li>
                      <li>Create your own automation software or use a pre-made one</li>
                      <li>Verify and test the automation system.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                <div className="slide" style={{ width: "16rem" }}>
                  <img src={Implement_Deploy} alt='' width="100%" />
                  <h3 className="mt-2">Put into Practice & Deploy</h3>
                  <div className="service-points">
                    <ul>
                      <li>Teach staff members how to operate the new automation system.</li>
                      <li>Check the system for problems and make adjustments as needed.</li>
                      <li>Install the automation system all at once or in stages.</li>
                      <li>Continually assist users</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                <div className="slide" style={{ width: "16rem" }}>
                  <img src={Optimization_Improvement} alt='' width="100%" />
                  <h3 className="mt-2">Enhancement & Streamlining</h3>
                  <div className="service-points">
                    <ul>
                      <li>Examine how the system is being used and note any shortcomings.</li>
                      <li>Obtain user and stakeholder input.</li>
                      <li>Execute updates and improvements to the system</li>
                      <li>Make constant improvements to the automation system to maximize ROI and efficiency.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </Slider>
            <Slider className="d-none d-md-block d-lg-none" {...settings}>
              <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                <div className="slide" style={{ width: "16rem" }}>
                  <img src={Analysis_Advisory} alt='' width="100%" />
                  <h3 className="mt-2">Evaluation and Counseling</h3>
                  <div className="service-points">
                    <ul>
                      <li>Determine which parts of the company stand to gain from automation.</li>
                      <li>Examine current procedures and pinpoint any problems.</li>
                      <li>Describe the aims and purposes of automation.</li>
                      <li>Create a roadmap for automation that includes deadlines and goals.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                <div className="slide" style={{ width: "16rem" }}>
                  <img src={Design_Develop} alt='' width="100%" />
                  <h3 className="mt-2">Creation & Design</h3>
                  <div className="service-points">
                    <ul>
                      <li>Create the workflow and architecture for the automation system.</li>
                      <li>Create your own automation software or use a pre-made one</li>
                      <li>Verify and test the automation system.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                <div className="slide" style={{ width: "16rem" }}>
                  <img src={Implement_Deploy} alt='' width="100%" />
                  <h3 className="mt-2">Put into Practice & Deploy</h3>
                  <div className="service-points">
                    <ul>
                      <li>Teach staff members how to operate the new automation system.</li>
                      <li>Check the system for problems and make adjustments as needed.</li>
                      <li>Install the automation system all at once or in stages.</li>
                      <li>Continually assist users</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                <div className="slide" style={{ width: "16rem" }}>
                  <img src={Optimization_Improvement} alt='' width="100%" />
                  <h3 className="mt-2">Enhancement & Streamlining</h3>
                  <div className="service-points">
                    <ul>
                      <li>Examine how the system is being used and note any shortcomings.</li>
                      <li>Obtain user and stakeholder input.</li>
                      <li>Execute updates and improvements to the system</li>
                      <li>Make constant improvements to the automation system to maximize ROI and efficiency.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </Slider>
            <Slider className="d-md-none" {...settings2}>
              <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                <div className="slide" style={{ width: "16rem" }}>
                  <img src={Analysis_Advisory} alt='' width="100%" />
                  <h3 className="mt-2">Evaluation and Counseling</h3>
                  <div className="service-points">
                    <ul>
                      <li>Determine which parts of the company stand to gain from automation.</li>
                      <li>Examine current procedures and pinpoint any problems.</li>
                      <li>Describe the aims and purposes of automation.</li>
                      <li>Create a roadmap for automation that includes deadlines and goals.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                <div className="slide" style={{ width: "16rem" }}>
                  <img src={Design_Develop} alt='' width="100%" />
                  <h3 className="mt-2">Creation & Design</h3>
                  <div className="service-points">
                    <ul>
                      <li>Create the workflow and architecture for the automation system.</li>
                      <li>Create your own automation software or use a pre-made one</li>
                      <li>Verify and test the automation system.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                <div className="slide" style={{ width: "16rem" }}>
                  <img src={Implement_Deploy} alt='' width="100%" />
                  <h3 className="mt-2">Put into Practice & Deploy</h3>
                  <div className="service-points">
                    <ul>
                      <li>Teach staff members how to operate the new automation system.</li>
                      <li>Check the system for problems and make adjustments as needed.</li>
                      <li>Install the automation system all at once or in stages.</li>
                      <li>Continually assist users</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                <div className="slide" style={{ width: "16rem" }}>
                  <img src={Optimization_Improvement} alt='' width="100%" />
                  <h3 className="mt-2">Enhancement & Streamlining</h3>
                  <div className="service-points">
                    <ul>
                      <li>Examine how the system is being used and note any shortcomings.</li>
                      <li>Obtain user and stakeholder input.</li>
                      <li>Execute updates and improvements to the system</li>
                      <li>Make constant improvements to the automation system to maximize ROI and efficiency.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className="background py-5">
        <div className="container-fluid">
          <div className="head" style={{ textAlign: "center" }}>
            <p style={{ color: "#f4990b" }}>BUSINESS SOLUTIONS FOR INDUSTRIES</p>
            <h1 className="mb-5">Industry Specific User cases</h1>
          </div>
          <div className="row">
            <Slider className="d-none d-lg-block" {...settings1}>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services AI" style={{ width: "18rem" }}>
                  <img src={Health} className="card-img-top card-image " alt="..." />
                  <div className="card-body">
                    <div className="card-title">Healthcare</div>
                    <div className="card-text">Modern technology has changed the financial industry dramatically. This has changed the way people invest, manage their money and behave financially. Exciting innovations such as algorithmic trading</div>
                    <div className='read-more pt-2'><Link to='/Healthcare'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services web-card" style={{ width: "18rem" }}>
                  <img src={Legal} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Legal</div>
                    <div className="card-text">Adopting contemporary solutions is crucial to staying ahead of the issues the legal business faces, which include expanding complexity, rising prices, and the demand for quicker and more efficient</div>
                    <div className='read-more pt-2 '><Link to='/Legal'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services mobile-app-card" style={{ width: "18rem" }}>
                  <img src={Education} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Education</div>
                    <div className="card-text">The educational system needs to use cutting-edge approaches like distance learning, adaptive technologies, and individualized learning to satisfy the varied demands of today's pupils.</div>
                    <div className='read-more pt-2 '><Link to='/Education'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services ui-card" style={{ width: "18rem" }}>
                  <img src={Finance} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Finance</div>
                    <div className="card-text">The finance business has seen tremendous transformation as a result of modern technology, which has completely changed how people invest, manage their money, and carry out financial transactions.</div>
                    <div className='read-more pt-2 '><Link to='/Finance'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services AI" style={{ width: "18rem" }}>
                  <img src={MobInsurance} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Insurance</div>
                    <div className="card-text">To stay ahead of the competition and meet the evolving requirements of their customers in today's fast-paced world, insurance companies must employ cutting-edge solutions.</div>
                    <div className='read-more pt-2 '><Link to='/Insurance'>Read More-</Link></div>
                  </div>
                </div>
              </div>
            </Slider>
            <Slider className="d-none d-md-block d-lg-none" {...settings}>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services AI" style={{ width: "18rem" }}>
                  <img src={Health} className="card-img-top card-image " alt="..." />
                  <div className="card-body">
                    <div className="card-title">Healthcare</div>
                    <div className="card-text">Modern technology has changed the financial industry dramatically. This has changed the way people invest, manage their money and behave financially. Exciting innovations such as algorithmic trading</div>
                    <div className='read-more pt-2'><Link to='/Healthcare'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services web-card" style={{ width: "18rem" }}>
                  <img src={Legal} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Legal</div>
                    <div className="card-text">Adopting contemporary solutions is crucial to staying ahead of the issues the legal business faces, which include expanding complexity, rising prices, and the demand for quicker and more efficient</div>
                    <div className='read-more pt-2 '><Link to='/Legal'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services mobile-app-card" style={{ width: "18rem" }}>
                  <img src={Education} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Education</div>
                    <div className="card-text">The educational system needs to use cutting-edge approaches like distance learning, adaptive technologies, and individualized learning to satisfy the varied demands of today's pupils.</div>
                    <div className='read-more pt-2 '><Link to='/Education'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services ui-card" style={{ width: "18rem" }}>
                  <img src={Finance} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Finance</div>
                    <div className="card-text">The finance business has seen tremendous transformation as a result of modern technology, which has completely changed how people invest, manage their money, and carry out financial transactions.</div>
                    <div className='read-more pt-2 '><Link to='/Finance'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services AI" style={{ width: "18rem" }}>
                  <img src={MobInsurance} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Insurance</div>
                    <div className="card-text">To stay ahead of the competition and meet the evolving requirements of their customers in today's fast-paced world, insurance companies must employ cutting-edge solutions.</div>
                    <div className='read-more pt-2 '><Link to='/Insurance'>Read More-</Link></div>
                  </div>
                </div>
              </div>
            </Slider>
            <Slider className="d-md-none" {...settings2}>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services AI" style={{ width: "18rem" }}>
                  <img src={Health} className="card-img-top card-image " alt="..." />
                  <div className="card-body">
                    <div className="card-title">Healthcare</div>
                    <div className="card-text">Modern technology has changed the financial industry dramatically. This has changed the way people invest, manage their money and behave financially. Exciting innovations such as algorithmic trading</div>
                    <div className='read-more pt-2'><Link to='/Healthcare'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services web-card" style={{ width: "18rem" }}>
                  <img src={Legal} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Legal</div>
                    <div className="card-text">Adopting contemporary solutions is crucial to staying ahead of the issues the legal business faces, which include expanding complexity, rising prices, and the demand for quicker and more efficient</div>
                    <div className='read-more pt-2 '><Link to='/Legal'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services mobile-app-card" style={{ width: "18rem" }}>
                  <img src={Education} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Education</div>
                    <div className="card-text">The educational system needs to use cutting-edge approaches like distance learning, adaptive technologies, and individualized learning to satisfy the varied demands of today's pupils.</div>
                    <div className='read-more pt-2 '><Link to='/Education'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services ui-card" style={{ width: "18rem" }}>
                  <img src={Finance} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Finance</div>
                    <div className="card-text">The finance business has seen tremendous transformation as a result of modern technology, which has completely changed how people invest, manage their money, and carry out financial transactions.</div>
                    <div className='read-more pt-2 '><Link to='/Finance'>Read More-</Link></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                <div className="card core-services AI" style={{ width: "18rem" }}>
                  <img src={MobInsurance} className="card-img-top card-image" alt="..." />
                  <div className="card-body">
                    <div className="card-title">Insurance</div>
                    <div className="card-text">To stay ahead of the competition and meet the evolving requirements of their customers in today's fast-paced world, insurance companies must employ cutting-edge solutions.</div>
                    <div className='read-more pt-2 '><Link to='/Insurance'>Read More-</Link></div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className="background py-5">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-12 my-auto py-4">
              <div className="contact-banner-heading p-lg-4  mt-4">
                <p className="headings" style={{ textAlign: "center", color: "#f4990b" }}>CASE STUDIES</p>
                <h1>Examine our projects in more detail to see the business solutions.</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <Slider className="d-none d-lg-block" {...settings1}>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={Dot_Net} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>.NET / INFORMATION TECHNOLOGY</div>
                    <p className="card-text">.NET Framework based Vehicle Speed Enforcing Application</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>.NET</div>
                      <div className='py-1 px-2 disble-btn mt-4'>INFORMATION TECHNOLOGY</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Health} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / HEALTHCARE / SUITECRM</div>
                    <p className="card-text">SuiteCRM Contivio Integrationfor Patient Care Management System</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>HEALTHCARE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Education} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / EDUCATION / SUITECRM</div>
                    <p className="card-text">Sales Process Automation using SuiteCRM for Education Bussiness</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>EDUCATION</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_ETL} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / ETL / PUBLICATION / SUGARCRM</div>
                    <p className="card-text">Order Management and Contract Management Automation using Customized SugarCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn '>CRM</div>
                      <div className='py-1 px-2 disble-btn '>ETL</div>
                      <div className='py-1 px-2 disble-btn '>PUBLICATION</div>
                      <div className='py-1 px-2 disble-btn '>SUGARCRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Finance} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / FINANCE / SUITECRM</div>
                    <p className="card-text">GoldMine to SuiteCRM Migration</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-5'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-5'>FINANCE</div>
                      <div className='py-1 px-2 disble-btn mt-5'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Finance1} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / FINANCE / SUITECRM</div>
                    <p className="card-text">SuiteCRM Asterisk Telephony Integration</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>FINANCE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Inventory} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / SERVICE / SUITECRM</div>
                    <p className="card-text">Inventory Management Using SuiteCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SERVICE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
            <Slider className="d-none d-md-block d-lg-none" {...settings}>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={Dot_Net} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>.NET / INFORMATION TECHNOLOGY</div>
                    <p className="card-text">.NET Framework based Vehicle Speed Enforcing Application</p>
                    <div className='d-flex'>

                      <div className='py-1 px-2 disble-btn mt-4'>.NET</div>
                      <div className='py-1 px-2 disble-btn mt-4'>INFORMATION TECHNOLOGY</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Health} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / HEALTHCARE / SUITECRM</div>
                    <p className="card-text">SuiteCRM Contivio Integrationfor Patient Care Management System</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>HEALTHCARE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Education} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / EDUCATION / SUITECRM</div>
                    <p className="card-text">Sales Process Automation using SuiteCRM for Education Bussiness</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>EDUCATION</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_ETL} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / ETL / PUBLICATION / SUGARCRM</div>
                    <p className="card-text">Order Management and Contract Management Automation using Customized SugarCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn '>CRM</div>
                      <div className='py-1 px-2 disble-btn '>ETL</div>
                      <div className='py-1 px-2 disble-btn '>PUBLICATION</div>
                      <div className='py-1 px-2 disble-btn '>SUGARCRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Publication} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / PUBLICATION / SUITECRM</div>
                    <p className="card-text">Order Management, Invoice Management and Contract Management Automation using SuiteCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn'>CRM</div>
                      <div className='py-1 px-2 disble-btn'>PUBLICATION</div>
                      <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Finance} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / FINANCE / SUITECRM</div>
                    <p className="card-text">GoldMine to SuiteCRM Migration</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-5'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-5'>FINANCE</div>
                      <div className='py-1 px-2 disble-btn mt-5'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Finance1} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / FINANCE / SUITECRM</div>
                    <p className="card-text">SuiteCRM Asterisk Telephony Integration</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>FINANCE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Inventory} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / SERVICE / SUITECRM</div>
                    <p className="card-text">Inventory Management Using SuiteCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SERVICE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
            <Slider className="d-md-none" {...settings2}>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={Dot_Net} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>.NET / INFORMATION TECHNOLOGY</div>
                    <p className="card-text">.NET Framework based Vehicle Speed Enforcing Application</p>
                    <div className='d-flex'>

                      <div className='py-1 px-2 disble-btn mt-4'>.NET</div>
                      <div className='py-1 px-2 disble-btn mt-4'>INFORMATION TECHNOLOGY</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Health} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / HEALTHCARE / SUITECRM</div>
                    <p className="card-text">SuiteCRM Contivio Integrationfor Patient Care Management System</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>HEALTHCARE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Education} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / EDUCATION / SUITECRM</div>
                    <p className="card-text">Sales Process Automation using SuiteCRM for Education Bussiness</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>EDUCATION</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_ETL} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / ETL / PUBLICATION / SUGARCRM</div>
                    <p className="card-text">Order Management and Contract Management Automation using Customized SugarCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn '>CRM</div>
                      <div className='py-1 px-2 disble-btn '>ETL</div>
                      <div className='py-1 px-2 disble-btn '>PUBLICATION</div>
                      <div className='py-1 px-2 disble-btn '>SUGARCRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Publication} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / PUBLICATION / SUITECRM</div>
                    <p className="card-text">Order Management, Invoice Management and Contract Management Automation using SuiteCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn'>CRM</div>
                      <div className='py-1 px-2 disble-btn'>PUBLICATION</div>
                      <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Finance} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / FINANCE / SUITECRM</div>
                    <p className="card-text">GoldMine to SuiteCRM Migration</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-5'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-5'>FINANCE</div>
                      <div className='py-1 px-2 disble-btn mt-5'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Finance1} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / FINANCE / SUITECRM</div>
                    <p className="card-text">SuiteCRM Asterisk Telephony Integration</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>FINANCE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Inventory} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / SERVICE / SUITECRM</div>
                    <p className="card-text">Inventory Management Using SuiteCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SERVICE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
