import React, { useEffect } from 'react'
import Development from '../../assets/web-development.png'
import Mobile from '../../assets/mobile-apps.png'
import UiUx from '../../assets/ui-ux.png'
import '../core-services-sec/core-service.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AI from '../../assets/AI_ML.png'
import Devops from '../../assets/DevOps.png'
import QA from '../../assets/QA.png'
import IOT from '../../assets/IOT.png'
import Marketing from '../../assets/Marketing.png'
import { Link } from "react-router-dom";

export default function CoreService() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
      };
      var settings1 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
      };
      var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
      };
  return (
    <>
    <div style={{backgroundColor: "#f7f7f7"}}>
    <div className='container pb-5'>
        <div className='row text-center'><div className='heading-text py-5'>Our Core Services</div></div>
        <div className='row d-none d-lg-block'>
            <Slider {...settings}>
            <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={AI} className="card-img-top card-image " alt="..."/>
                        <div className="card-body">
                            <div className="card-title">AI / ML</div>
                            <div className="card-text">We infuse AI elements to existing business processes to boost productivity. Our AI engineers are experts to deliver next-gen AI apps & solutions to diverse industries.</div>
                            <div className='read-more pt-2'><Link to='/AI'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={Development} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">Web Development</div>
                            <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                            <div className='read-more pt-2 '><Link to='/web-dev'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                        <img src={Mobile} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">Mobile Apps</div>
                            <div className="card-text">We build intuitive and engaging Android, iOS and cross-platform apps for businesses, consumers and enterprises that end users love and adapt to them very quickly.</div>
                            <div className='read-more pt-2 '><Link to='/mobile-apps'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services ui-card" style={{width: "18rem"}}>
                        <img src={UiUx} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">UI/UX Design</div>
                            <div className="card-text">Starting from concept, information architecture, visual identity and UI/UX design, our team delivers dazzling experiences for maximum user engagement.</div>
                            <div className='read-more pt-2 '><Link to='/ui-ux'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={Devops} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">Devops</div>
                            <div className="card-text">Our DevOps experts help you with secured & stable infrastructure management for Continuous integration and Continuous Delivery for faster releases.</div>
                            <div className='read-more pt-2 '><Link to='/DevOps'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={QA} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">QA</div>
                            <div className="card-text">Our QA engineers don’t just test, they make your software application successful ensuring quality delivery with expert manual and automated testing services.</div>
                            <div className='read-more pt-2 '><Link to='/Qa'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={IOT} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">IOT</div>
                            <div className="card-text">Hire IoT programmers who develop bespoke and innovative IoT solutions to simplify and automate complex business processes to save overall operational cost.</div>
                            <div className='read-more pt-2 '><Link to='/iot'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={Marketing} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">Marketing</div>
                            <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                            <div className='read-more pt-2 '><Link to='/Marketing'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>

        <div className='row d-none  d-md-block d-xl-none'>
        <Slider {...settings1}>
        <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={AI} className="card-img-top card-image " alt="..."/>
                        <div className="card-body">
                            <div className="card-title">AI / ML</div>
                            <div className="card-text">We infuse AI elements to existing business processes to boost productivity. Our AI engineers are experts to deliver next-gen AI apps & solutions to diverse industries.</div>
                            <div className='read-more pt-2'><Link to='/AI'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={Development} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">Web Development</div>
                            <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                            <div className='read-more pt-2 '><Link to='/web-dev'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                        <img src={Mobile} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">Mobile Apps</div>
                            <div className="card-text">We build intuitive and engaging Android, iOS and cross-platform apps for businesses, consumers and enterprises that end users love and adapt to them very quickly.</div>
                            <div className='read-more pt-2 '><Link to='/mobile-apps'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services ui-card" style={{width: "18rem"}}>
                        <img src={UiUx} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">UI/UX Design</div>
                            <div className="card-text">Starting from concept, information architecture, visual identity and UI/UX design, our team delivers dazzling experiences for maximum user engagement.</div>
                            <div className='read-more pt-2 '><Link to='/ui-ux'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={Devops} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">Devops</div>
                            <div className="card-text">Our DevOps experts help you with secured & stable infrastructure management for Continuous integration and Continuous Delivery for faster releases.</div>
                            <div className='read-more pt-2 '><Link to='/DevOps'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={QA} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">QA</div>
                            <div className="card-text">Our QA engineers don’t just test, they make your software application successful ensuring quality delivery with expert manual and automated testing services.</div>
                            <div className='read-more pt-2 '><Link to='/Qa'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={IOT} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">IOT</div>
                            <div className="card-text">Hire IoT programmers who develop bespoke and innovative IoT solutions to simplify and automate complex business processes to save overall operational cost.</div>
                            <div className='read-more pt-2 '><Link to='/iot'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={Marketing} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">Marketing</div>
                            <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                            <div className='read-more pt-2 '><Link to='/Marketing'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>

        <div className='d-block d-md-none d-lg-none'>
        <Slider {...settings2}>
        <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={AI} className="card-img-top card-image " alt="..."/>
                        <div className="card-body">
                            <div className="card-title">AI / ML</div>
                            <div className="card-text">We infuse AI elements to existing business processes to boost productivity. Our AI engineers are experts to deliver next-gen AI apps & solutions to diverse industries.</div>
                            <div className='read-more pt-2'><Link to='/AI'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={Development} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">Web Development</div>
                            <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                            <div className='read-more pt-2 '><Link to='/web-dev'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services mobile-app-card" style={{width: "18rem"}}>
                        <img src={Mobile} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">Mobile Apps</div>
                            <div className="card-text">We build intuitive and engaging Android, iOS and cross-platform apps for businesses, consumers and enterprises that end users love and adapt to them very quickly.</div>
                            <div className='read-more pt-2 '><Link to='/mobile-apps'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services ui-card" style={{width: "18rem"}}>
                        <img src={UiUx} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">UI/UX Design</div>
                            <div className="card-text">Starting from concept, information architecture, visual identity and UI/UX design, our team delivers dazzling experiences for maximum user engagement.</div>
                            <div className='read-more pt-2 '><Link to='/ui-ux'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services hire-card" style={{width: "18rem"}}>
                        <img src={Devops} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">Devops</div>
                            <div className="card-text">Our DevOps experts help you with secured & stable infrastructure management for Continuous integration and Continuous Delivery for faster releases.</div>
                            <div className='read-more pt-2 '><Link to='/DevOps'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={QA} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">QA</div>
                            <div className="card-text">Our QA engineers don’t just test, they make your software application successful ensuring quality delivery with expert manual and automated testing services.</div>
                            <div className='read-more pt-2 '><Link to='/Qa'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={IOT} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">IOT</div>
                            <div className="card-text">Hire IoT programmers who develop bespoke and innovative IoT solutions to simplify and automate complex business processes to save overall operational cost.</div>
                            <div className='read-more pt-2 '><Link to='/iot'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services web-card" style={{width: "18rem"}}>
                        <img src={Marketing} className="card-img-top card-image" alt="..."/>
                        <div className="card-body">
                            <div className="card-title">Marketing</div>
                            <div className="card-text">Our web developers create custom web and web application solutions. We deliver web presence to help you grow your business using the best web technologies.</div>
                            <div className='read-more pt-2 '><Link to='/Marketing'>Read More-</Link></div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    </div>
    </div>
    </>
  )
}
