import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import PHPB from '../../../assets/hippa complaint.jpg'

export default function HippaComplaint() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>HIPAA Compliant SaaS Based Patient Relationship Management Software</strong></div>
                    <p className='mt-2 text-center'>The Client offers the wide range of offshore healthcare application development services covering the wide range of health care activities including Practice Management, Electronic Medical Records, Insurance claims, Financial transactions and Integrations with numerous health care devices. They deliver high-quality offshore software for customized health care applications to health care providers, health care institutes, hospitals, doctors, physicians, clinics and health care entrepreneur communities.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>The Client wanted to design and develop an integrated HIPPA compliant cloud-based technology platform for effective management of Patient Relationship. The major functionalities proposed were Online Appointment Management with Voice/ Text/ Email reminders, Online Bill Payment, Patient/ Doctor Referral Management, Social Media Integration etc.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>
                        <strong>PHP –</strong> It is a server-side scripting language, and it is capable of rendering dynamic pages in real time. It is a widely chosen programming language for faster, reliable, secure, scalable and flexible web-based solutions for businesses.<br/><br/>
                        <strong>ZEND Framework –</strong> The application is an object-oriented and open source framework which uses PHP 7 for its implementation. Collection of packages based on professional PHP sums up this Zend Framework. The framework utilizes different packages like for testing packages, it has PHP Unit, for the continuous services of Integration, it has Travis CI.<br/><br/>
                        <strong>MySQL-</strong> MySQL has become an important part of every PHP application. It is also utilized by famous websites like Twitter, YouTube, Flickr and Facebook.<br/><br/>
                        <strong>Twilio –</strong> It is a cloud based communication platform that widely used for building SMS, messaging application and Voice applications.
                    </p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={PHPB} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We Designed and Developed a cloud-based Doctor-Patient communication system leveraging ZEND Framework that helped Patients and Doctors communicate through a very secured messaging platform. We developed an Appointment Management System, Patient Review Management System, Campaign Management System, Patient / Doctor Communication History Management Module, Integrated Twilio for sending SMS and Voice appointment reminders to Doctors and Patients.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The SaS based technology platform has helped the Doctors, Providers or the Clinics in managing the Patient Communications with higher effectiveness while minimizing the no-shows and maximizing their revenue. It has not only helped the subscribers in managing Patient Appointments, Reminders, Birthday Wishes, Searches as per Doctor’s availability and Specialty but also in managing Patient Reviews and publish them in various social media channels through Moderation and Publish process.</p>
                </div>
            </div>
        </div>
    </>
  )
}
