import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import AugmentedR from '../../../assets/Augmented-Reality-Application.jpg'

export default function AugmentedReality() {
    return (
        <>
            <div className='container pt-4'>
                <div className='row pt-5'>
                    <div className='col-12 mt-5 pt-5'>
                        <div className='mx-5 heading-text text-center my-3'><strong>Augmented Reality Application</strong></div>
                        <p className='mt-2 text-center'>Furnishing and Custom Skirtings Manufacturing Company based out of UK.</p>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                        <div className='Vehicle-text mt-0'>Challenges:</div>
                        <p className='mt-4'>Customer wanted to provide his buyers a visualization tool through an android application, using which buyer can capture Image of his home/office for which we wants to buy Furniture/Skirtings for, using features of the application, try the same on the Image of the space in real time to get actual look and feel of how things will look like in real.</p>
                        <div className='Vehicle-text'>Technology:</div>
                        <p className='mt-4'>Android, ZEND Framework 2, HTML5, Bootstrap, Javascript, Jquery</p>
                    </div>
                    <div className='col-lg-6 order-1 order-lg-2'>
                        <div><img src={AugmentedR} width={"100%"} /></div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Solution:</div>
                        <p className='mt-4'>Development of android application, leveraging Android Native features and methods to achieve the said functionality with seamless user experience and high clarity output.</p>
                    </div>
                    <div className='col-12 mb-5'>
                        <div className='Vehicle-text'>Outcome:</div>
                        <p className='mt-4'>Application Developed and Delivered, a famous furnishing AR Application in UK for furnishing industry.</p>
                    </div>
                </div>
            </div>
        </>
    )
}
