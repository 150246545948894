import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import HealthcareI from '../../../assets/Healthcare-Information-Exchange.jpg'

export default function HealthcareInformation() {
  return (
    <>
        <div className='container pt-4 Healthcare-Information'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Customized HL7 Channels for Healthcare Information Exchange (HIE)</strong></div>
                    <p className='mt-2 text-center'>Client is Midwest based Healthcare Service Company providing behavioral health services such as mental health, chemical dependency/ substance abuse, and social services to larger and smaller organization and human services.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>Client was looking for automating the data exchange processes and integration with various Billing Platforms and their proprietary lab results system for specific behavioral results.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>HL7, Mirth Connect</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={HealthcareI} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>Dahooks designed and developed number of customized HL7 secured channels using Mirth Connect to automate the Healthcare Information Exchange (HIE) processes.</p>
                    <p><strong>Technical Solution Overview:</strong></p>
                    <ul>
                        <li>Labs compose and send transaction messages (DFT 03) in HL7 format to HL7 server.</li>
                        <li>These messages are received or fetched by HL7 server remotely.</li>
                        <li>HL7 server validates these messages for patient name, address, DOB, referring doctor, plan id etc. and performs corresponding crosswalk on each transaction of corresponding messages as per the CMS and Non-CMS code rules through incorporated logic to obtain transformed DFT and ADT messages.</li>
                    </ul>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The solution offered the sales team an integrated platform to operate more effectively and meet aggressive sales targets with up-to-date data readily available on few clicks. The effectiveness of the Targeted Sales vs. Actual Sales went nearly to 90% accuracy i.e. variance is only 10% or less month on month, year on year basis.</p>
                </div>
            </div>
        </div>
    </>
  )
}
