import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import BillingP from '../../../assets/Billing-Platform.jpg'

export default function BillingPlatform() {
  return (
    <>
        <div className='container pt-4 Billing-Platform'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Billing Platform and Lab Results Integration Using HL7 and Mirth Connect</strong></div>
                    <p className='mt-2 text-center'>The client is a USA based medical billing company that deals in toxicology labs.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>Client wanted to automate the data exchange process with multiple labs via HL7 secured channels using Mirth Connect, increasing their revenue against manual data submissions.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>HL7, Mirth Connect</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={BillingP} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>Dahooks designed and developed number of customized HL7 secured channels using Mirth Connect. Mirth Connect is a cross-platform HL7 interface engine that enables bi-directional HL7 messages between systems and applications over multiple transports. It automated the billing submission process with labs.</p>
                </div>
                <div className='col-12'>
                    <div className='Vehicle-text'>Technical Solution Overview:</div>
                    <p className='mt-4'>Dahooks designed and developed number of customized HL7 secured channels using Mirth Connect. Mirth Connect is a cross-platform HL7 interface engine that enables bi-directional HL7 messages between systems and applications over multiple transports. It automated the billing submission process with labs.</p>
                    <ul>
                        <li>Labs compose and send transaction messages (DFT 03) in HL7 format to HL7 server that are received or fetched by HL7 server remotely</li>
                        <li>HL7 server validates these messages for patient name, address, DOB, referring doctor, plan id etc. and performs corresponding crosswalk on each transaction of corresponding messages as per the CMS and Non-CMS code rules through custom build logic and Billing Company’s Database to obtain transformed DFT and ADT messages</li>
                        <li>HL7 server then decides whether to send a transformed message or to put the raw message into suspense to process later or to reject the message permanently</li>
                        <li>ADT and DFT messages are sent to the corresponding destination</li>
                        <li>Corresponding destination receives these transformed messages and processes further as part of the insurance claim process</li>
                    </ul>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>With the implementation of Mirth HL7 based customized channels, the tedious manual works of message submission, coding, validation, tracking etc. became easier and got to higher accuracy and speed. A basic level of regular maintenance efforts was enough in managing the Medical Billing Organization and Labs collaboration needs.</p>
                </div>
            </div>
        </div>
    </>
  )
}
