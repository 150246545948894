import React, { useEffect } from "react";
import "../digital-commerce/digital-commerce.css";
import "./legal.css"
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Technology_Integration from "../../assets/legal.jpg";
import Improved_Efficiency from "../../assets/Improved Efficiency and Productivity.png";
import Enhanced_Customer from "../../assets/Enhanced Customer Experience.png";
import Cost_Savings from "../../assets/Cost Savings.jpg";
import Teamwork from "../../assets/teamwork.png";
import Flexibility from "../../assets/flexibility.png";
import Decision_Making from "../../assets/decision-making.png";
import Health from "../../assets/Healthcare.png";
import Legal from "../../assets/Legal.png";
import Education from "../../assets/Education.png";
import Finance from "../../assets/Finance.png";
import MobInsurance from "../../assets/MobInsurance.png";
import CRM from "../../assets/crm.png";
import Img_1 from '../../assets/ind-legal-slide-img-1.jpg'
import Img_2 from '../../assets/ind-legal-slide-img-2.jpg'
import Img_3 from '../../assets/ind-legal-slide-img-3.jpg'
import Img_4 from '../../assets/ind-legal-slide-img-4.jpg'
import Img_5 from '../../assets/ind-legal-slide-img-5.jpg'
import Img_6 from '../../assets/ind-legal-slide-img-6.jpg'
import Img_7 from '../../assets/ind-legal-slide-img-7.jpg'
import Img_8 from '../../assets/ind-legal-slide-img-8.jpg'
import Cost from '../../assets/cost.png'
import Quality from '../../assets/quality1.png'
import Communication from '../../assets/communication.png'
import Flex from '../../assets/flexibility.png'
import Security from '../../assets/security.png'
import { Helmet } from "react-helmet";

// Define the AccordionItem component
const AccordionItem = ({ title, content, index, openIndex, setOpenIndex }) => {
  const isOpen = index === openIndex;
  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index);
  };
  return (
    <div className="accordion-item">
      <div className="accordion-title"type="text"onClick={toggleAccordion}style={{ fontSize: "18px", cursor: "pointer" }}>
        <strong>{isOpen ? "- " : "+ "}</strong> <strong>{title}</strong>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default function LegalSec() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const [openIndex, setOpenIndex] = React.useState(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings3 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Legal Software Development Company | Legal Software Solution - Dahooks" /><meta property="og:description" content="Dahooks a leading software development Company offering legal software solution to the legal industry professionals. Hire our expert software developers today!" /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Legal Software Development Company | Legal Software Solution - Dahooks" /><meta name="twitter:description" content="Dahooks a leading software development Company offering legal software solution to the legal industry professionals. Hire our expert software developers today!" /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
        <title>Legal Software Development Company | Legal Software Solution - Dahooks</title>
        <link rel="canonical" href="https://dahooks.com/Legal" />
      </Helmet>
      <div className="legal">
        <div className="container pt-5">
          <div className="row ">
            <div className="col-lg-12 mt-5 py-4">
              <div className=" p-lg-4  mt-5">
                <h1 className="mb-0 text-center" style={{ color: "#FFB800", }}>
                  Services for Software Development in the Legal Sector
                </h1>
                <div className="headings" style={{fontSize: "28px",textAlign: "center",lineHeight: "70px",fontWeight: 700}}>
                  Modern Tech Solutions for Transformation
                </div>
                <p className="text-center">
                  Adopting contemporary solutions is crucial to staying ahead of the issues the legal business faces, which include expanding complexity, rising prices, and the demand for quicker and more efficient services." Artificial intelligence, cloud computing, automation in the legal field, and other cutting-edge technologies are all available to optimize workflows, cut expenses, and enhance results.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <img src={Technology_Integration} width="100%" />
            </div>
          </div>
        </div>
        <div className="container pb-5">
          <div className="row">
            <div className="col-lg-12 my-auto py-4">
              <div className=" p-lg-4  mt-4">
                <div className="text-center" style={{ color: "#f4990b", fontSize: 18 }}>
                  DEMAND
                </div>
                <h1 className="text-center" style={{}}>
                  Transforming Legal Enterprises: Exposing the Role of Technology in Contemporary Change
                </h1>
              </div>
            </div>
            <div className="row d-none d-lg-block">
              <Slider {...settings1}>
                <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_1} alt='' width="100%" />
                    <h3 className="mt-3">Increasing Rivalry</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Due to increased competition in the legal sector, businesses must prioritize technology and provide high-quality services at reasonable prices.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_2} alt='' width="100%" />
                    <h3 className="mt-3">Increasing intricacy</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      The complexity of legal situations is rising along with the volume of data that needs to be managed and examined.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_3} alt='' width="100%" />
                    <h3 className="mt-3">Financial constraints</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Clients are searching for ways to cut costs as legal services become more pricey.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_4} alt='' width="100%" />
                    <h3 className="mt-3">Inadequacies</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Legal procedures are frequently laborious and manual, which causes delays and inefficiencies.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_5} alt='' width="100%" />
                    <h3 className="mt-3">Digital change</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      The legal sector is going through a digital revolution, and those which don't adapt risk falling behind.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_6} alt='' width="100%" />
                    <h3 className="mt-3">A rise in the need for data</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      In order to better guide their legal strategies, clients are seeking more data-driven insights and solutions.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_7} alt='' width="100%" />
                    <h3 className="mt-3">Better teamwork is required.</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Technology can help legal teams collaborate more effectively, which is something that they should do.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_8} alt='' width="100%" />
                    <h3 className="mt-3">Shifting demands from clients</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Customers want a more individualized and technologically advanced experience from their legal support providers.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="row d-none d-md-block d-lg-none">
              <Slider {...settings}>
                <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_1} alt='' width="100%" />
                    <h3 className="mt-3">Increasing Rivalry</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Due to increased competition in the legal sector, businesses must prioritize technology and provide high-quality services at reasonable prices.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_2} alt='' width="100%" />
                    <h3 className="mt-3">Increasing intricacy</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      The complexity of legal situations is rising along with the volume of data that needs to be managed and examined.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_3} alt='' width="100%" />
                    <h3 className="mt-3">Financial constraints</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Clients are searching for ways to cut costs as legal services become more pricey.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_4} alt='' width="100%" />
                    <h3 className="mt-3">Inadequacies</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Legal procedures are frequently laborious and manual, which causes delays and inefficiencies.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_5} alt='' width="100%" />
                    <h3 className="mt-3">Digital change</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      The legal sector is going through a digital revolution, and those which don't adapt risk falling behind.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_6} alt='' width="100%" />
                    <h3 className="mt-3">A rise in the need for data</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      In order to better guide their legal strategies, clients are seeking more data-driven insights and solutions.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_7} alt='' width="100%" />
                    <h3 className="mt-3">Better teamwork is required.</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Technology can help legal teams collaborate more effectively, which is something that they should do.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_8} alt='' width="100%" />
                    <h3 className="mt-3">Shifting demands from clients</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Customers want a more individualized and technologically advanced experience from their legal support providers.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="row d-md-none">
              <Slider {...settings2}>
                <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_1} alt='' width="100%" />
                    <h3 className="mt-3">Increasing Rivalry</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Due to increased competition in the legal sector, businesses must prioritize technology and provide high-quality services at reasonable prices.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_2} alt='' width="100%" />
                    <h3 className="mt-3">Increasing intricacy</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      The complexity of legal situations is rising along with the volume of data that needs to be managed and examined.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_3} alt='' width="100%" />
                    <h3 className="mt-3">Financial constraints</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Clients are searching for ways to cut costs as legal services become more pricey.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_4} alt='' width="100%" />
                    <h3 className="mt-3">Inadequacies</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Legal procedures are frequently laborious and manual, which causes delays and inefficiencies.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_5} alt='' width="100%" />
                    <h3 className="mt-3">Digital change</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      The legal sector is going through a digital revolution, and those which don't adapt risk falling behind.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_6} alt='' width="100%" />
                    <h3 className="mt-3">A rise in the need for data</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      In order to better guide their legal strategies, clients are seeking more data-driven insights and solutions.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_7} alt='' width="100%" />
                    <h3 className="mt-3">Better teamwork is required.</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Technology can help legal teams collaborate more effectively, which is something that they should do.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 my-auto py-4 d-flex justify-content-center">
                  <div className="slide" style={{ width: "18rem" }}>
                    <img src={Img_8} alt='' width="100%" />
                    <h3 className="mt-3">Shifting demands from clients</h3>
                    <p className="service-points" style={{ textAlign: "justify" }}>
                      Customers want a more individualized and technologically advanced experience from their legal support providers.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <div className='container py-5'>
          <div className='row'>
            <div className="col-lg-6 col-12"  >
              <div className="tagline">
                <p style={{ color: "#f4990b" }}>OUR LEGAL SOFTWARE AND IT SOLUTIONS</p>
                <h1>law firms, NGOs, government entities, and corporate legal departments.</h1>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className="service-content" >
                <p><strong>Systems for managing documents</strong></p>
                <p>Software that makes it possible for legal businesses to effectively handle, arrange, and preserve their electronic files and records.</p>

                <p><strong>Contract Administration</strong></p>
                <p>Software driven by artificial intelligence that automates the drafting, evaluation, approval, and implementation of contracts, streamlining the process.</p>

                <p><strong>Case Handling</strong></p>
                <p>A piece of software intended to monitor and control matters and legal cases, including contacts, deadlines, and case files.</p>

                <p><strong>Manage Practices</strong></p>
                <p>Program that makes administrative duties like timekeeping, scheduling, billing, and client communications easier.</p>

                <p><strong>E-Learning</strong></p>
                <p>A software program that makes it easier to find, gather, and examine electronic documents—a task that is frequently necessary in legal proceedings.</p>

                <p><strong>Tools for Legal Research</strong></p>
                <p>Legal practitioners can obtain statutes, rules, and case law through online research portals.</p>

                <p><strong>Management of Intellectual Property</strong></p>
                <p>A specialized software that is designed for companies that deal with intellectual property law. The software is capable of tracking and maintaining patents, trademarks, and copyrights held by such companies.</p>

                <p><strong>Cybersecurity and Data privacy</strong></p>
                <p>There is a software that is designed to protect confidential customer information and also follows relevant laws like GDPR, CCPA, and HIPAA.</p>

                <p><strong>Platforms for Virtual Collaboration</strong></p>
                <p>Software that runs in the cloud that makes it easier for clients, stakeholders, and remote teams to collaborate.</p>

                <p><strong>Apply Analytics in Practice</strong></p>
                <p>Software that offers analytics and insights into data related to business performance, such as indicators related to billing, profitability, and use.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="background py-5">
          <div className="container-fluid">
            <div className="head" style={{ textAlign: "center" }}>
              <p className="pb-4" style={{ color: "#f4990b" }}>FOCUS AREAS</p>
              <h1 className="mb-5" style={{}}>Important areas of concentration for creating strategies that<br /> work for legal organizations.</h1>
            </div>
            <div className="row">
              <Slider className="d-none d-md-block d-lg-none" {...settings}>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services hire-card" style={{ width: "18rem" }}>
                    <img src={Health} className="card-img-top card-image " alt="..." />
                    <div className="card-body">
                      <div className="card-title">Enterprise Solutions</div>
                      <div className="card-text">With complete practice management solutions, you can streamline internal operations and improve customer interactions while growing your firm. Use contract and document management solutions to automate processes and guarantee simple access to papers.</div>
                      <div className='read-more pt-2'><Link to='/Enterprise'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services web-card" style={{ width: "18rem" }}>
                    <img src={Legal} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">Artificial Intelligence</div>
                      <div className="card-text">Leverage AI-powered legal research and contract review capabilities to greatly increase accuracy and efficiency. Optimize your process to save time and guarantee thorough analysis for improved results. You can stay ahead of the legal game by having access to state-of-the-art technology.</div>
                      <div className='read-more pt-2 '><Link to='/Artificial-Intelligence'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services mobile-app-card" style={{ width: "18rem" }}>
                    <img src={Education} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">BI and Analytics</div>
                      <div className="card-text">Track billable hours and client satisfaction using data analytics tools, and use business intelligence dashboards to improve decision-making by visualizing and analyzing data.</div>
                      <div className='read-more pt-2 '><Link to='/BI'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services ui-card" style={{ width: "18rem" }}>
                    <img src={Finance} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">Technology Integration</div>
                      <div className="card-text">Increase productivity by utilizing cloud-based technologies for collaboration and smoothly integrating applications to create effective workflows. Accept the potential of technology to improve collaboration and expedite processes.</div>
                      <div className='read-more pt-2 '><Link to='/Technology-Integration'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services hire-card" style={{ width: "18rem" }}>
                    <img src={MobInsurance} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">Digital Commerce</div>
                      <div className="card-text">Our platform optimizes interactions and communication by providing a full array of online tools for customer involvement. We also easily include safe payment methods and online invoicing, making transactions easier for both clients and companies.</div>
                      <div className='read-more pt-2 '><Link to='/Digital-Commerce'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services web-card" style={{ width: "18rem" }}>
                    <img src={CRM} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">Business Automation</div>
                      <div className="card-text">Utilize automation to streamline time-consuming, repetitive processes like case management and document assembling, increasing productivity. Use effective workflow automation solutions to streamline internal operations and create standard operating procedures.</div>
                      <div className='read-more pt-2 '><Link to='/Business-Automation'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
              </Slider>
              <Slider className="d-none d-lg-block" {...settings3}>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services hire-card" style={{ width: "18rem" }}>
                    <img src={Health} className="card-img-top card-image " alt="..." />
                    <div className="card-body">
                      <div className="card-title">Enterprise Solutions</div>
                      <div className="card-text">With complete practice management solutions, you can streamline internal operations and improve customer interactions while growing your firm. Use contract and document management solutions to automate processes and guarantee simple access to papers.</div>
                      <div className='read-more pt-2'><Link to='/Enterprise'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services web-card" style={{ width: "18rem" }}>
                    <img src={Legal} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">Artificial Intelligence</div>
                      <div className="card-text">Leverage AI-powered legal research and contract review capabilities to greatly increase accuracy and efficiency. Optimize your process to save time and guarantee thorough analysis for improved results. You can stay ahead of the legal game by having access to state-of-the-art technology.</div>
                      <div className='read-more pt-2 '><Link to='/Artificial-Intelligence'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services mobile-app-card" style={{ width: "18rem" }}>
                    <img src={Education} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">BI and Analytics</div>
                      <div className="card-text">Track billable hours and client satisfaction using data analytics tools, and use business intelligence dashboards to improve decision-making by visualizing and analyzing data.</div>
                      <div className='read-more pt-2 '><Link to='/BI'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services ui-card" style={{ width: "18rem" }}>
                    <img src={Finance} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">Technology Integration</div>
                      <div className="card-text">Increase productivity by utilizing cloud-based technologies for collaboration and smoothly integrating applications to create effective workflows. Accept the potential of technology to improve collaboration and expedite processes.</div>
                      <div className='read-more pt-2 '><Link to='/Technology-Integration'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services hire-card" style={{ width: "18rem" }}>
                    <img src={MobInsurance} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">Digital Commerce</div>
                      <div className="card-text">Our platform optimizes interactions and communication by providing a full array of online tools for customer involvement. We also easily include safe payment methods and online invoicing, making transactions easier for both clients and companies.</div>
                      <div className='read-more pt-2 '><Link to='/Digital-Commerce'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services web-card" style={{ width: "18rem" }}>
                    <img src={CRM} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">Business Automation</div>
                      <div className="card-text">Utilize automation to streamline time-consuming, repetitive processes like case management and document assembling, increasing productivity. Use effective workflow automation solutions to streamline internal operations and create standard operating procedures.</div>
                      <div className='read-more pt-2 '><Link to='/Business-Automation'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
              </Slider>
              <Slider className="d-md-none" {...settings2}>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services hire-card" style={{ width: "18rem" }}>
                    <img src={Health} className="card-img-top card-image " alt="..." />
                    <div className="card-body">
                      <div className="card-title">Enterprise Solutions</div>
                      <div className="card-text">With complete practice management solutions, you can streamline internal operations and improve customer interactions while growing your firm. Use contract and document management solutions to automate processes and guarantee simple access to papers.</div>
                      <div className='read-more pt-2'><Link to='/Enterprise'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services web-card" style={{ width: "18rem" }}>
                    <img src={Legal} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">Artificial Intelligence</div>
                      <div className="card-text">Leverage AI-powered legal research and contract review capabilities to greatly increase accuracy and efficiency. Optimize your process to save time and guarantee thorough analysis for improved results. You can stay ahead of the legal game by having access to state-of-the-art technology.</div>
                      <div className='read-more pt-2 '><Link to='/Artificial-Intelligence'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services mobile-app-card" style={{ width: "18rem" }}>
                    <img src={Education} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">BI and Analytics</div>
                      <div className="card-text">Track billable hours and client satisfaction using data analytics tools, and use business intelligence dashboards to improve decision-making by visualizing and analyzing data.</div>
                      <div className='read-more pt-2 '><Link to='/BI'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services ui-card" style={{ width: "18rem" }}>
                    <img src={Finance} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">Technology Integration</div>
                      <div className="card-text">Increase productivity by utilizing cloud-based technologies for collaboration and smoothly integrating applications to create effective workflows. Accept the potential of technology to improve collaboration and expedite processes.</div>
                      <div className='read-more pt-2 '><Link to='/Technology-Integration'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services hire-card" style={{ width: "18rem" }}>
                    <img src={MobInsurance} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">Digital Commerce</div>
                      <div className="card-text">Our platform optimizes interactions and communication by providing a full array of online tools for customer involvement. We also easily include safe payment methods and online invoicing, making transactions easier for both clients and companies.</div>
                      <div className='read-more pt-2 '><Link to='/Digital-Commerce'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                  <div className="card core-services web-card" style={{ width: "18rem" }}>
                    <img src={CRM} className="card-img-top card-image" alt="..." />
                    <div className="card-body">
                      <div className="card-title">Business Automation</div>
                      <div className="card-text">Utilize automation to streamline time-consuming, repetitive processes like case management and document assembling, increasing productivity. Use effective workflow automation solutions to streamline internal operations and create standard operating procedures.</div>
                      <div className='read-more pt-2 '><Link to='/Business-Automation'>Read More-</Link></div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 my-auto py-4">
              <div className="  p-lg-4  mt-4 ">
                <div className="row">
                  <p className="heading text-center" style={{ color: "rgb(244, 153, 11)" }}>KEY BENEFITS</p>
                  <h1 className="mt-0 text-center">All crucial elements for learning online at any time and<br /> from any place</h1>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                    <div>
                      <img src={Improved_Efficiency} width="60px" />
                      <p className="mt-3" ><strong>Enhanced Effectiveness</strong></p>
                      <p >By automating procedures, software solutions increase productivity and allow up legal experts to concentrate on higher-value work.</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                    <div>
                      <img src={Enhanced_Customer} width="60px" />
                      <p className="mt-3" ><strong>Enhanced Precision</strong></p>
                      <p >Legal software solutions reduce mistakes and inconsistencies, which raises quality and accuracy.</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                    <div>
                      <img src={Teamwork} width="60px" />
                      <p className="mt-3" ><strong>Enhanced Cooperation</strong></p>
                      <p >Legal teams can share knowledge and communicate remotely more effectively when they use collaboration software.</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start mt-3">
                    <div>
                      <img src={Cost_Savings} width="60px" />
                      <p className="mt-3" ><strong>Increased Output</strong></p>
                      <p >Software speeds up turnaround times and increases productivity by automating operations, decreasing errors, and streamlining workflows.</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start mt-3">
                    <div>
                      <img src={Flexibility} width="60px" />
                      <p className="mt-3" ><strong>Enhanced Economic Benefits</strong></p>
                      <p >Legal organizations can save money by implementing automation, which lowers costs and boosts profitability.</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mt-3 text-center text-lg-start">
                    <div>
                      <img src={Decision_Making} width="60px" />
                      <p className="mt-3" ><strong>Improved Customer Service</strong></p>
                      <p >With better communication, quicker reaction times, and real-time information, legal software improves client service.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 text-center">
              <div className="heading py-2" style={{ color: "rgb(244, 153, 11)" }}>WHY CHOOSE US?</div>
              <h1 className="mb-5" style={{}}>Let Dahooks take care of your legal IT needs.</h1>
            </div>
            <div className="col-md-4 col-12 mt-3 mt-md-0">
              <div className="p-4 cost-box">
                <div className="mb-3"><img src={Security} width={"50px"} /></div>
                <div className="cost-heading mb-3"><strong>Expertise</strong></div>
                <p>Tech professionals who understand rules and specifications and provide customized solutions for all of your requirements.</p>
              </div>
            </div>
            <div className=" col-md-4 col-12 mt-3 mt-md-0">
              <div className="p-4 cost-box">
                <div className="mb-3"><img src={Quality} width={"50px"} /></div>
                <div className="cost-heading mb-3"><strong>Quality</strong></div>
                <p>A track record of meeting deadlines, producing high-quality work, and offering clients the finest options.</p>
              </div>
            </div>
            <div className="col-md-4 col-12 mt-3 mt-md-0">
              <div className="p-4 cost-box">
                <div className="mb-3"><img src={Security} width={"50px"} /></div>
                <div className="cost-heading mb-3"><strong>Security</strong></div>
                <p className="pb-4 mb-2">We put a high priority on data privacy and safeguard customer data with robust security procedures.</p>
              </div>
            </div>
            <div className=" col-md-4 col-12 mt-3">
              <div className="p-4 cost-box">
                <div className="mb-3"><img src={Communication} width={"50px"} /></div>
                <div className="cost-heading mb-3"><strong>Communication</strong></div>
                <p>Communicative responsiveness and close collaboration with clients to comprehend their needs.</p>
              </div>
            </div>
            <div className="col-md-4 col-12 mt-3">
              <div className="p-4 cost-box">
                <div className="mb-3"><img src={Flex} width={"50px"} /></div>
                <div className="cost-heading mb-3"><strong>Flexibility</strong></div>
                <p>We make adjustments to our solutions in response to our clients' evolving needs.</p>
              </div>
            </div>
            <div className="col-md-4 col-12 mt-3">
              <div className="p-4 cost-box">
                <div className="mb-3"><img src={Cost} width={"50px"} /></div>
                <div className="cost-heading mb-3"><strong>Cost-effectiveness</strong></div>
                <p className="pb-4 mb-2">Economical alternatives that don't sacrifice knowledge or quality.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}