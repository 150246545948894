import React, { useState } from "react";
import Logo from "../../assets/Dahookslogo.png";
import People from "../../assets/people.png";
import Web_Development from "../../assets/Web_Development.png";
import Mobile from "../../assets/Mobile.png";
import AI_ML from "../../assets/AI_ML.png";
import UI_UX from "../../assets/UI_UX.png";
import QA from "../../assets/QA.png";
import IOT from "../../assets/IOT.png";
import Marketing from "../../assets/Marketing.png";
import Healthcare from "../../assets/Healthcare.png";
import Insurance from "../../assets/insurance icon.png";
import Legal from "../../assets/Legal.png";
import Finance from "../../assets/Finance.png";
import Education from "../../assets/Education.png";
import HandShake from "../../assets/handshake.png";
import PhoneCall from "../../assets/phone-call.png";
import Success from "../../assets/success.png";
import "../headers/headers.css";
import { Link, useLocation } from "react-router-dom";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Location from '../../assets/location_on.png'
import Email from '../../assets/email.png'
import India from '../../assets/india-flag.jpg'
import USA from '../../assets/united-states-flag.jpg'
import Instagram from '../../assets/instagram.png'
import Facebook from '../../assets/facebook.png'
import LinkedIn from '../../assets/linkedin.png'
import Twiter from '../../assets/twitter.png'
import Case from '../../assets/header-casestudy.jpg'
import Popular from '../../assets/popular-brands.jpg'
import Work from '../../assets/our-work.jpg'
import Product from '../../assets/our-product.jpg'

const Header = (props) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [errr, setErrr] = useState("")
  const [showMenu, setShowMenu] = useState(true)
  
  const onOpenModal = () => {
    setOpen(true)
    document.getElementById("navbarSupportedContent")?.classList.remove("show");
  };
  const onCloseModal = () => setOpen(false);

  const onSumbit=()=>{
    if(name=='' && email=="" && message==""){
      setErrr("All field are required!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
      return false
    }
    const emailReg=/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(!email.match(emailReg)){
          setErrr("Please enter valid email!!");
      setTimeout(() => {
        setErrr("");
      }, 3000);
        return false
        }

        if(message?.length>500){
          setErrr("Messahe should be contain 500 charectors !!");
          setTimeout(() => {
            setErrr("");
          }, 3000);
          return false
        }
        setOpen(false);
     
        axios.post("https://smtp.taction.in/api/api/v2/send-mail",{
          name:name,
          email:email,
          message:message
        }).then(res=>{
          if(res && res?.status==200){
           
            toast.success('Your query is Submitted ', {
              autoClose: 2000,
            }
            );
            // setTimeout(() => {
              
            //   setEmail("");
            //   setName("");
            //   setMessage("")
            // }, 2500);
          }
         
        }).catch((err)=>{
          console.log(err)
        })
        console.log("Contact")

    
    
  }

  const closeT=()=>{
    document.getElementById("navbarSupportedContent")?.classList.remove("show");
  }
  return (
    <React.Fragment>
      <ToastContainer style={{zIndex:900}} />
      
      <div className="container-fluid px-lg-5 py-2" style={{backgroundColor:"#000000",color:"rgb(178 178 178)",position:"fixed",borderBottom:"1px solid rgb(178 178 178)",zIndex:"99"}}>
        <div className="row">
          <div className="col-md-6 col-12 d-none d-lg-block">
            <div className="d-flex">
              <div className="d-flex"><div className="my-auto"><img src={Email} style={{height:"80%"}}/></div><div className="mx-2"><a href="mailto:info@dahooks.com" style={{fontSize:14,textDecoration:"none",color:"rgb(178, 178, 178)"}}>info@dahooks.com</a></div></div>
              <div className="d-flex mx-4"><img src={Location} style={{height:"80%"}}/><div className="mx-2" style={{fontSize:14}}>A-83 Sector 63 Noida</div></div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="d-flex justify-content-lg-end">
              <div className="d-flex mx-4 d-none d-xl-block">
                <div className="row px-2">
                  <div className="col-3">
                    
                      <div className="my-auto">
                      <a href="https://www.linkedin.com/company/dahooks-technologies/" target="blank">
                        <img className="mx-2" src={LinkedIn} style={{height:"80%"}}/>
                    </a>
                      </div>
                  </div>
                  <div className="col-3">
                    <a href="https://www.facebook.com/dahookstechnologies" target="blank">
                      <img className="mx-2" src={Facebook} style={{height:"80%"}}/>
                    </a>
                  </div>
                  <div className="col-3">
                    <a href="https://www.instagram.com/dahookstechnologies/" target="blank">
                      <img className="mx-2" src={Instagram} style={{height:"80%"}}/>
                    </a>
                  </div>
                  <div className="col-3">
                    <a href="https://twitter.com/dahookstech" target="blank">
                      <img className="mx-2" src={Twiter} style={{height:"80%"}}/>
                    </a>
                  </div>
                </div>
                
                
                
                
              </div>
              <div className="d-flex" ><div className="my-auto"><img src={India} style={{height:"80%",marginRight:5,marginLeft:5}}/></div><div className="mx-2 my-auto" style={{width:140,fontSize:14}}>+91-7827150289</div></div>
              <div className="d-flex "><div className="my-auto"><img src={USA} style={{height:"80%"}}/></div><div className="mx-2 my-auto" style={{width:110,fontSize:14}}>+1 302-219-0001</div></div>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-dark py-0" style={{backgroundColor:"#000000",marginTop:"41px"}}>
        <div className="container-fluid px-lg-5">
          <Link className="navbar-brand" to="/" onClick={closeT}>
            <img src={Logo} alt="Dahooks" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >

            {/* web */}
            <form className="d-flex d-lg-block d-none">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  {/*<a className="nav-link active" aria-current="page" href="#">About</a> */}
                  <div className="dropdown">
                    <button className="nav-link dropbtn" onMouseOver={()=>{
                setShowMenu(false);
              }}>About</button>
                    <div className="dropdown-content content1">
                      <div className={`row ${showMenu?"no-show":""}`}>
                        <div className="col-md-6" >
                          <Link to="/Team"  onClick={()=>{
                            setShowMenu(true)
                          }}>
                            <img className="drpdn-icon" src={People} />
                            <span className="dropdown-link">Team</span>
                            <div className="para-graph">
                              We are committed to delivering great software solutions
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-6">
                          <Link to="/work" onClick={()=>{
                            setShowMenu(true)
                          }}>
                            <img className="drpdn-icon" src={HandShake} />
                            <span className="dropdown-link">Engagement Models</span>
                            <div className="para-graph">
                              If you are interested in working with us, we would love to hear from you.
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-6">
                          <Link to="/contact-us" onClick={()=>{
                            setShowMenu(true)
                          }}>
                            <img className="drpdn-icon" src={PhoneCall} />
                            <span className="dropdown-link">Contact</span>
                            <div className="para-graph">
                              We offer consultation services with our technology experts to discuss your ideas
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-6">
                          <Link to="/career" onClick={()=>{
                            setShowMenu(true)
                          }}>
                            <img className="drpdn-icon" src={Success} />
                            <span className="dropdown-link">Careers</span>
                            <div className="para-graph">
                              If you are interested in joining our team, we encourage you to explore our current job openings
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <a className="nav-link dropbtn" href="#" onMouseOver={()=>{
                      setShowMenu(false);
                    }}>
                      Focus
                    </a>
                    <div className={`dropdown-content content2 ${showMenu?"no-show":""}`}>
                      <div className="linkInfoGroup">
                        <ul className="avatarGroup">
                          <li>
                            {/* <img
                              src="https://dcdh7ea8gkhvt.cloudfront.net/wp-content/uploads/2022/12/team-people-pic-v1.webp"
                              alt="Submenu"
                              width="174"
                              height="54"
                            /> */}
                          </li>
                        </ul>
                        <div className="contactInfo">
                          <div className="infoWrap">
                            <span className="sub-title">Build your own</span>
                            <h3>Agile Team</h3>
                            <p>by assembling the right set of resources with specific skills and expertise</p>
                          </div>
                          <Link to="/contact-us" className="contactBtn" title="Contact Us" onClick={()=>{
                            setShowMenu(true)
                          }}>
                            Contact Us
                          </Link>
                        </div>
                      </div>
                      <div className="infoSection">
                        <div className="row">
                          <div className="col-md-6">
                            <Link to="/Enterprise" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={People} />
                              <span className="dropdown-link">
                                Enterprise Applications
                              </span>
                              <div className="para-graph">
                                At Dahooks, we specialize in empowering organizations with robust and scalable enterprise applications that are tailored to their unique needs
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <Link to="/Artificial-Intelligence" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={HandShake} />
                              <span className="dropdown-link">
                                Artificial Intelligence
                              </span>
                              <div className="para-graph">
                                At Dahooks, we are committed to harnessing the power of artificial intelligence to drive innovation and boost efficiency for our clients
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <Link to="/Digital-Commerce" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={People} />
                              <span className="dropdown-link">
                                Digital Commerce
                              </span>
                              <div className="para-graph">
                                we specialize in creating high-performance and engaging digital commerce solutions that help businesses maximize their online potential
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <Link to="/Technology-Integration" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={HandShake} />
                              <span className="dropdown-link">
                                Technology Integration
                              </span>
                              <div className="para-graph">
                                We specialize in seamlessly integrating technologies to build cohesive and efficient IT infrastructures for businesses. 
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <Link to="/Business-Automation" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={People} />
                              <span className="dropdown-link">
                                Business Automation
                              </span>
                              <div className="para-graph">
                                we specialize in streamlining operations and boosting productivity for businesses, through custom business automation solutions
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <Link to="/BI" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={HandShake} />
                              <span className="dropdown-link">
                                BI & Analytics
                              </span>
                              <div className="para-graph">
                                we specialize in turning data into actionable insights with our advanced BI and analytics development service
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <a className="nav-link dropbtn" href="#" onMouseOver={()=>{
                      setShowMenu(false);
                    }}>
                      Industries
                    </a>
                    <div className={`dropdown-content content3 ${showMenu?"no-show":""}`}>
                      <div className="linkInfoGroup">
                        <div className="company-basic">
                          <div className="ind-data">
                            <span>Countries</span>
                            <h3>90+</h3>
                          </div>
                          <div className="ind-data">
                            <span>Since</span>
                            <h3>2012</h3>
                          </div>
                          <div className="ind-data">
                            <span>Projects</span>
                            <h3>7000+</h3>
                          </div>
                          <div className="ind-data">
                            <span>Clients</span>
                            <h3>3000+</h3>
                          </div>
                        </div>
                        <div className="contactInfo">
                          <div className="infoWrap">
                            <h3></h3>
                            <p>
                              Our learning from variety of industries over more
                              than two decades have helped us bring immediate
                              impact.
                            </p>
                          </div>
                          <Link to="/contact-us" className="contactBtn" title="Contact Us" onClick={()=>{
                            setShowMenu(true)
                          }}>
                            Contact Us
                          </Link>
                        </div>
                      </div>
                      <div className="linkGroup columns-3">
                        <div className="linkGroupCol">
                          <div className="linkGroupCol-head">
                            <div className="header-icon">
                              <span className="menu-icon icon-m-ind-healthcare"></span>
                            </div>
                            <h3 className="industries-link">
                              <Link to="/Healthcare" title="Healthcare" onClick={()=>{
                                setShowMenu(true)
                              }}>
                              <img className="drpdn-icon" src={Healthcare} width="30px" />
                                Healthcare
                              </Link>
                            </h3>
                          </div>
                        </div>
                        <div className="linkGroupCol">
                          <div className="linkGroupCol-head">
                            <div className="header-icon">
                              <span className="menu-icon icon-m-ind-legal"></span>
                            </div>
                            <h3 className="industries-link">
                              <Link to="/Legal" title="Legal" onClick={()=>{
                                setShowMenu(true)
                              }}>
                              <img className="drpdn-icon" src={Legal} width="30px" />
                                Legal
                              </Link>
                            </h3>
                          </div>
                        </div>
                        <div className="linkGroupCol">
                          <div className="linkGroupCol-head">
                            <div className="header-icon">
                              <span className="menu-icon icon-m-ind-education"></span>
                            </div>
                            <h3 className="industries-link">
                              <Link to="/Education" title="Education" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={Education} width="30px" />
                                Education
                              </Link>
                            </h3>
                          </div>
                        </div>
                        <div className="linkGroupCol">
                          <div className="linkGroupCol-head">
                            <div className="header-icon">
                              <span className="menu-icon icon-m-ind-finance"></span>
                            </div>
                            <h3 className="industries-link">
                              <Link to="/Finance" title="Finance" onClick={()=>{
                                setShowMenu(true)
                              }}>
                              <img className="drpdn-icon" src={Finance} width="30px" />
                                Finance
                              </Link>
                            </h3>
                          </div>
                        </div>
                        <div className="linkGroupCol">
                          <div className="linkGroupCol-head">
                            <div className="header-icon">
                              <span className="menu-icon icon-m-ind-insurance"></span>
                            </div>
                            <h3 className="industries-link">
                              <Link to="/Insurance" title="Insurance" onClick={()=>{
                                setShowMenu(true)
                              }}>
                              <img className="drpdn-icon" src={Insurance} width="30px" />
                                Insurance
                              </Link>
                            </h3>
                          </div>
                        </div>
                        {/* <div className="linkGroupCol">
                          <div className="linkGroupCol-head">
                            <div className="header-icon">
                              <span className="menu-icon icon-m-ind-social-media"></span>
                            </div>
                            <h3 className="industries-link">
                              <a href="#" title="CRM">
                              <img className="drpdn-icon" src={CRM} width="30px" />
                                CRM
                              </a>
                            </h3>
                          </div>
                        </div>
                        <div className="linkGroupCol">
                          <div className="linkGroupCol-head">
                            <div className="header-icon">
                              <span className="menu-icon icon-m-ind-media"></span>
                            </div>
                            <h3 className="industries-link">
                              <a href="#" title="Mobile &amp;Application">
                              <img className="drpdn-icon" src={Mob_app} width="30px" />
                              Mobile Application
                              </a>
                            </h3>
                          </div>
                        </div>
                      
                        <div className="linkGroupCol">
                          <div className="linkGroupCol-head">
                            <div className="header-icon">
                              <span className="menu-icon icon-m-ind-travel"></span>
                            </div>
                            <h3 className="industries-link">
                              <a href="#" title="Custom &amp; Software &amp; Solution">
                              <img className="drpdn-icon" src={Custom_Software} width="30px" />
                              Custom Software Solution
                              </a>
                            </h3>
                          </div>
                        </div>
                        <div className="linkGroupCol">
                          <div className="linkGroupCol-head">
                            <div className="header-icon">
                              <span className="menu-icon icon-ind-building-models"></span>
                            </div>
                            <h3 className="industries-link">
                              <a href="#" title="ETL &amp; Solution">
                              <img className="drpdn-icon" src={ETL} width="30px" />
                              ETL Solution
                              </a>
                            </h3>
                          </div>
                        </div>
                        <div className="linkGroupCol">
                          <div className="linkGroupCol-head">
                            <div className="header-icon">
                              <span className="menu-icon icon-m-ind-retail"></span>
                            </div>
                            <h3 className="industries-link">
                              <a href="#" title="Salesforce">
                              <img className="drpdn-icon" src={Salesforce} width="30px" />
                              Salesforce
                              </a>
                            </h3>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <Link className="nav-link dropbtn" to="/Services" onMouseOver={()=>{
                      setShowMenu(false);
                    }}>
                      Services
                    </Link>
                    <div className={`dropdown-content content4 ${showMenu?"no-show":""}`}>
                      <div className="linkInfoGroup content41">
                        <ul className="avatarGroup">
                          <li>
                            {/* <img
                              src="https://dcdh7ea8gkhvt.cloudfront.net/wp-content/uploads/2023/07/m-services-menu-img.webp"
                              alt="Submenu"
                              width="174"
                              height="54"
                            /> */}
                          </li>
                        </ul>
                        <div className="contactInfo">
                          <div className="infoWrap">
                            <span className="sub-title"></span>
                            <h3>Discovery Workshop</h3>
                            <p>
                              Define functional scope of your project to plan
                              project budget and timeline.
                            </p>
                          </div>
                          <Link to="/Services" className="contactBtn" title="View More" onClick={()=>{
                            setShowMenu(true)
                          }}>
                            View More
                          </Link>
                        </div>
                      </div>
                      <div className="infoSection">
                        <div className="row">
                          <div className="col-md-6">
                            <Link to="/web-dev" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={Web_Development} width="30px"/>
                              <span className="dropdown-link">
                                Web Development
                              </span>
                              <div className="para-graph">
                                Creating innovative and dynamic web applications for a digital-first world
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <Link to="/mobile-apps" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={Mobile} width="30px" />
                              <span className="dropdown-link">Mobile Apps</span>
                              <div className="para-graph">
                                Creating high-performance mobile apps for the linked future
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <Link to="/AI" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={AI_ML} width="30px" />
                              <span className="dropdown-link">AI / ML</span>
                              <div className="para-graph">
                                Leveraging AI and ML to Deliver Innovative Solutions
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <Link to="/ui-ux" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={UI_UX} width="30px"/>
                              <span className="dropdown-link">
                                UI/UX Consultancy
                              </span>
                              <div className="para-graph">
                                Improving experience through intuitive and user-centric design services.
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <Link to="/DevOps" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={People} />
                              <span className="dropdown-link">DevOps</span>
                              <div className="para-graph">
                                Streamlined processes and collaboration for the code lifecycle.
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <Link to="/Qa" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={QA} width="30px" />
                              <span className="dropdown-link">QA</span>
                              <div className="para-graph">
                                Delivering excellence through comprehensive automated and manual QA.
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <Link to="/iot" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={IOT} width="30px" />
                              <span className="dropdown-link">IoT</span>
                              <div className="para-graph">
                                Leveraging device ecosystems to create a connected world
                              </div>
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <Link to="/Marketing" onClick={()=>{
                            setShowMenu(true)
                          }}>
                              <img className="drpdn-icon" src={Marketing} width="30px" />
                              <span className="dropdown-link">Marketing</span>
                              <div className="para-graph">
                                Developing results-oriented methods to take your organization ahead
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <Link className="nav-link dropbtn " to="/Methodologies" onMouseOver={()=>{
                        setShowMenu(false);
                      }}>
                      Methodology
                    </Link>
                    <div className={`dropdown-content content1 ${showMenu?"no-show":""}`}>
                      <div className="row">
                        <div className="col-md-6">
                          <Link to="/Discovery" onClick={()=>{
                            setShowMenu(true)
                          }}>
                            <img className="drpdn-icon" src={People} />
                            <span className="dropdown-link">Discovery</span>
                            <div className="para-graph">
                              Functional UI/UX Design transforms concepts into measurable scopes.
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-6">
                          <Link to="/speak" onClick={()=>{
                            setShowMenu(true)
                          }}>
                            <img className="drpdn-icon" src={HandShake} />
                            <span className="dropdown-link">
                              Project Execution
                            </span>
                            <div className="para-graph">
                              Learn how we balance the important triangle of cost, time, and scope.
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-6">
                          <Link to="/speak" onClick={()=>{
                            setShowMenu(true)
                          }}>
                            <img className="drpdn-icon" src={PhoneCall} />
                            <span className="dropdown-link">
                              Dedicated Developers
                            </span>
                            <div className="para-graph">
                              Create an expert team that works remotely under your control.
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-6">
                          <Link to="/speak" onClick={()=>{
                            setShowMenu(true)
                          }}>
                            <img className="drpdn-icon" src={Success} />
                            <span className="dropdown-link">
                              Project Review
                            </span>
                            <div className="para-graph">
                              Proactive review at each stage ensures effective delivery.
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <div className="dropdown">
                    <a className="nav-link dropbtn" href="#" onMouseOver={()=>{
                      setShowMenu(false);
                    }}>
                      Works
                    </a>
                    <div className={`dropdown-content content5 ${showMenu?"no-show":""}`}>
                      <div className="case-studies">
                        <Link to="/speak" title="Popular Brands" onClick={()=>{
                            setShowMenu(true)
                          }}>
                          <img
                            src={Popular}
                            alt="submenu"
                            width="165"
                            height="100"
                          />
                          <h3>Popular Brands</h3>
                          <p>
                            A showcase of incredible solutions offered to the most well-known brands.
                          </p>
                        </Link>
                        <Link to="/case" title="Case Studies" onClick={()=>{
                            setShowMenu(true)
                          }}>
                          <img
                            src={Case}
                            alt="submenu"
                            width="165"
                            height="100"
                          />
                          <h3>Case Studies</h3>
                          <p>
                            Explore how Dahooks provided IT solutions to startups and enterprises.
                          </p>
                        </Link>
                        <Link to="/speak" title="Our Work" onClick={()=>{
                            setShowMenu(true)
                          }}>
                          <img
                            src={Work}
                            alt="submenu"
                            width="165"
                            height="100"
                          />
                          <h3>Our Work</h3>
                          <p>
                            A insight into our design and development industry experience.
                          </p>
                        </Link>
                        <Link to="/speak" title="Products" onClick={()=>{
                            setShowMenu(true)
                          }}>
                          <img
                            src={Product}
                            alt="submenu"
                            width="165"
                            height="100"
                          />
                          <h3>Products</h3>
                          <p>
                            We developed digital technologies to help firms increase productivity.
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                {/* <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a className="dropdown-item" href="#">Action</a></li>
                <li><a className="dropdown-item" href="#">Another action</a></li>
                <li><hr className="dropdown-divider"/></li>
                <li><a className="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </li> */}
              </ul>
              {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/> */}
            </form>

            {/* phone */}
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-lg-none">
              <li className="nav-item dropdown" >
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  About
                </a>
                <ul className={`dropdown-menu`} aria-labelledby="navbarDropdown">
                  <li onClick={closeT}><Link className="dropdown-item" to="/Team">Team</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/Work">How We Work</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/contact-us">Contact</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/career">Career</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown3" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Focus
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown3">
                  <li onClick={closeT}><Link className="dropdown-item" to="/Enterprise">Enterprise Solution</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/Artificial-Intelligence">Artificial Intelligence</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/Digital-Commerce">Digital Commerce</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/Technology-Integration">Technology Integration</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/Business-Automation">Business Automation</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/BI">BI & Analytics</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown4" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Industries
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown4">
                  <li onClick={closeT}><Link className="dropdown-item" to="/Healthcare">Healthcare</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/Legal">Legal</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/Education">Education</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/Finance">Finance</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/Insurance">Insurance</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Services
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">
                  <li onClick={closeT}><Link className="dropdown-item" to="/web-dev">Web Development</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/mobile-apps">Mobile App Development</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/AI">AI / ML</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/ui-ux">UI / UX Consultancy</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/DevOps">DevOps</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/Qa">QA</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/iot">IoT</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/Marketing">Marketing</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Works
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                  <li onClick={closeT}><Link className="dropdown-item" to="/speak">Popular Brands</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/case">Case Studies</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/speak">Our Work</Link></li>
                  <li onClick={closeT}><Link className="dropdown-item" to="/speak">Products</Link></li>
                </ul>
              </li>
            </ul>

            <button onClick={onOpenModal} className="btn btn-success" type="submit">
              Contact Us
            </button>
          </div>
        </div>
      </nav>
      
      {/* End Header */}
      <Modal open={open} onClose={onCloseModal} center style={{background:'#f2f2f2'}}>
        <div className="container-modal">
          <div className="text-center contact">Contact Us</div>
          <form action="/action_page.php">
            <label className="label-text" for="fname">Name <sup style={{color:'red'}}>*</sup></label>
            <input type="text" onChange={(e)=>{
              setName(e.target.value)
            }} value={name} id="fname" name="firstname" placeholder="Enter Your Name.."/>

            <label className="label-text" for="lname">Email<sup style={{color:'red'}}>*</sup></label>
            <input type="text" onChange={(e)=>{
              setEmail(e.target.value)
            }} value={email} id="lname" name="lastname" placeholder="Enter Your Email.."/>
            <label className="label-text" for="subject">Message<sup style={{color:'red'}}>*</sup></label>
            <textarea onChange={(e)=>{
              if(e.target.value?.length>500){
                setErrr("Messahe should be contain 500 charectors !!");
                setTimeout(() => {
                  setErrr("");
                }, 3000);
                return false
              }
              else{
                setMessage(e.target.value)
              }
            }} id="subject" value={message} name="subject" placeholder="Write something.." style={{height:'100px'}}></textarea>

            {/* <input type="submit" value="Submit"/> */}
            <div className="text-center">
              <button onClick={onSumbit} type="button" className="btn btn-primary" style={{width:'100%'}}>Submit</button>
            </div> 
            <div style={{height:20,color:"red",fontSize:12,marginTop:5}}>{errr?errr:""}</div>
          </form>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default Header;