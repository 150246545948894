import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import SocialF from '../../../assets/Social-Fashion-Application.jpg'

export default function SocialFashion() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Social Fashion Application</strong></div>
                    <p className='mt-2 text-center'>Startup based out of Middle East to disrupt the way people connect with each other by sharing their fashion tastes and preferences and sharing their latest Images wearing latest garments and apparels which people follow and rate.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>Customer wanted to have a mobile platform enabling fashion lovers to collaborate with each other in real time in which people can view images of each other , rate, like and comment on it, tap on a particular section of Image to identify that particular garment piece or apparel and its seller in his/her geography.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>iOS (Swift), Android, Ruby on Rails, HTML5, Bootstrap, Javascript, Jquery</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={SocialF} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>Mobile applications developed in Native iOS and Android, powered by robust and high performance backend developed in ZEND Framework 2 with a highly interactive responsive Admin Panel to manage the users, content, merchants in the Application.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>Applications Developed and Delivered, a famous brand in Middle East, a well to do and performing company.</p>
                </div>
            </div>
        </div>
    </>
  )
}
