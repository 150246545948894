import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import DataM from '../../../assets/Data-mining.jpg'

export default function DataMining() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Data Mining, Data Warehousing and Tableau Dashboard Creation</strong></div>
                    <p className='mt-2 text-center'>US based world leaders in creating top of the line nutritional and dietary supplements to help purify and strengthen human organs and body.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>The client was looking for a Business Intelligence (BI) Platform to help Sales and Marketing teams in making informed strategic decisions. They also proposed to have a custom portal developed to publish the dashboard(s) to keep look, feel and usability similar to their existing Sales and Marketing Management systems.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>PHP, ZEND Framework 2, Tableau BI</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={DataM} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>Dahooks has been engaged in mining raw data from various data sources such as CRM (SugarCRM & LimeLight), 20+ product websites, Google Adwords, Facebook, and others, for data warehousing and finally generating various analytics leveraging Tableau. We also have designed and built an Analytic Portal leveraging ZEND Framework 2 (ZF2) with the high level of PHP Security layer implementation and integration with Tableau API. This Portal allows Admin to create various Users with defined roles/permissions, upload Tableau Dashboard(s) in different categories/sub categories and provide access to appropriate Users.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The solution offered the sales team an integrated platform to operate more effectively and meet aggressive sales targets with up-to-date data readily available on few clicks. The effectiveness of the Targeted Sales vs. Actual Sales went nearly to 90% accuracy i.e. variance is only 10% or less month on month, year on year basis.</p>
                </div>
            </div>
        </div>
    </>
  )
}
