import React,{useEffect} from 'react'

export default function Privacy() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);
  return (
    <>
    <div className='container pt-5'>
        <div className='row pt-5'>
            <div className='col-12 py-5'>
                <div className='privacy-heading mt-5'>Dahooks Technologies Privacy Policy</div>
                <div className='privacy-text mt-3'>
                    We take your privacy seriously. Dahooks Technologies remains committed to respecting your privacy while you use our website. The policy below is applicable to our site visitor information collection practices.
                </div>
                <div className='privacy-text mt-3'>
                    Any information relating to a natural person is termed Personally Identifiable Information. Either indirectly or directly, this information, used in combination with other information available or likely available to a corporate body, is capable and can possibly identify such a person.
                </div>

                <div className='privacy-heading mt-5'>Information we collect</div>
                <div className='privacy-text mt-3'>
                    Unless you choose to fill out and submit your information on our website through our specifically provided forms, interactive live chat features, or other means, Dahooks Technologies does not collect Personally Identifiable Information about you. In order to provide our visitors with a better experience and usability, we do automatically collect certain information.
                </div>
                <div className='privacy-text mt-3'>
                    When you visit our website, we collect the operating system you use, your IP address, and the browser you are using in order to provide a better, more personal experience.
                </div>
                <div className='privacy-text mt-3'>
                    Dahooks Technologies shall only process the information we receive in order to pursue legitimate business interests to establish communications with possible clients/customers and other persons with general, business related inquiries.
                </div>

                <div className='privacy-heading mt-5'>Information about you</div>
                <div className='privacy-text mt-3'>
                    To improve our website, we use certain information to analyze our site usage.
                </div>
                <div className='privacy-text mt-3'>
                    Any Personally Identifiable Information you provide, we use to respond to your inquiry or process an application form you completed on our website. We may also use it to respond to legal process or as required by law in response to a subpoena, law enforcement agency, court order, to take action against any potential threat to the physical safety of a person or any illegal activity.
                </div>

                <div className='privacy-heading mt-5'>Information sharing policy</div>
                <div className='privacy-text mt-3'>
                    We may share your Personally Identifiable Information within Dahooks Technologies , authorized third-party agents in any part of the world, or our business partners for purposes of data enrichment, storage, processing, or to provide services to a transaction that was requested, after ensuring that such entities are they, themselves bound contractually by data privacy obligations.
                </div>
                <div className='privacy-text mt-3'>
                    If and when we transfer Personally Identifiable Information outside of the EU, it is either to countries determined by the European Commission to have an adequate level of protection or have been determined to have in place the appropriate safeguards.
                </div>

                <div className='privacy-heading mt-5'>Privacy rights</div>
                <div className='privacy-text mt-3'>
                    Visitors to our site may have certain rights pertaining to their Personally Identifiable Information that is provided under applicable law. These rights are:
                </div>
                <div className='privacy-text mt-3'>
                    <ul>
                        <li>The right to request access to your Personally Identifiable Information and related processing activities.</li>
                        <li>The right to request that any inaccurate or incomplete Personally Identifiable Information be rectified.</li>
                        <li>The right to request that you’re Personally Identifiable Information be erased under certain circumstances.</li>
                        <li>The right to request that your Personally Identifiable Information processing be restricted under certain circumstances.</li>
                        <li>The right to object to having your Personally Identifiable Information be processed under certain circumstances.</li>
                        <li>The right to receive from us, a controller, you’re provided Personally Identifiable Information in a machine-readable, commonly used format under certain circumstances.</li>
                        <li>The right to report a complaint to the supervisory authority</li>
                        <li>The right to contact us at any time to withdraw your provided consent.</li>
                    </ul>
                </div>
                <div className='privacy-text mt-3'>
                    Should you wish to exercise your rights in respect to the articles listed above regarding your Personally Identifiable Information, please use the contact section provided below. As required by law, we will respond to you within the appropriate time.
                </div>

                <div className='privacy-heading mt-5'>Information Storage Policy</div>
                <div className='privacy-text mt-3'>
                    Your Personally Identifiable Information is stored in our databases on servers of cloud-based data management services with which Dahooks Technologies engages.
                </div>
                <div className='privacy-text mt-3'>
                    We will keep your Personally Identifiable Information to pursue business purposes and it will be kept in line with our data retention policies.
                </div>
                <div className='privacy-text mt-3'>
                    Any Personally Identifiable Information that is received from prospective clients/customers will be retained for the entire duration of the prospective clients’ / customers’ business relationship with Dahooks Technologies Software.
                </div>

                <div className='privacy-heading mt-5'>Third party websites</div>
                <div className='privacy-text mt-3'>
                    The Dahooks Technologies website may have links to other websites, including social media platforms and third party sites, whose data collection and information gathering practices are different from ours. Dahooks Technologies does not control those privacy practices or sites, and you should read the privacy notices associated with any outside website. Dahooks Technologies does not make any representations, nor do we endorse third-party websites.
                </div>

                <div className='privacy-heading mt-5'>Commitment to your security</div>
                <div className='privacy-text mt-3'>
                    To safeguard the information we collect, Dahooks Technologies uses appropriate technical, administrative, and physical procedures. To ensure that all matters relating to Personally Identifiable Information are processed lawfully and fairly, Dahooks Technologies , takes reasonable steps in its PII dealings. As long as there is a business requirement, or if required under other applicable laws, Dahooks Technologies may retain your PII.
                </div>

                <div className='privacy-heading mt-5'>Addressing of grievances</div>
                <div className='privacy-text mt-3'>
                    Any complaint or grievance relating to the processing of information should be sent in writing to Dahooks Technologies . Please see below for contact information.
                </div>

                <div className='privacy-heading mt-5'>Policy Changes</div>
                <div className='privacy-text mt-3'>
                    From time to time, this policy may change. Please check back periodically for any updates or changes to this page.
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
