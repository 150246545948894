import React, { useEffect } from "react";
import "../Enterprise/Enterprise.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Enterprise_Application from "../../assets/Enterprise_Application.jpg";
import Increased_Efficiency from "../../assets/Increased Efficiency.jpg";
import Customer_Experience from "../../assets/Customer Experience.jpg";
import Competitive_Advantage from "../../assets/Competitive Advantage.jpg";
import Improved_Security from "../../assets/Improved Security.jpg";
import Cost_Savings from "../../assets/Cost Savings.jpg";
import Scalability from "../../assets/Scalability.jpg";
import Enterprise_Application_Development from "../../assets/Enterprise-Application-Development.jpg";
import Health from "../../assets/Healthcare.png";
import Legal from "../../assets/Legal.png";
import Education from "../../assets/Education.png";
import Finance from "../../assets/Finance.png";
import MobInsurance from "../../assets/MobInsurance.png";
import Wellness from "../../assets/wellness.png";
import Banking from "../../assets/bank.png";
import Hospitality from "../../assets/hospitality.png";
import ECommerce from "../../assets/e-commerce.png";
import Logistics from "../../assets/Logistics.png";
import CRM_Education from "../../assets/CRM_Education.png";
import CRM_Finance from "../../assets/CRM_Finance.png";
import Dot_Net from '../../assets/Dot_Net.png'
import CRM_Health from '../../assets/CRM_Health.png'
import CRM_Publication from '../../assets/CRM_INV.png'
import CRM_ETL from '../../assets/CRM_ETL.png'
import CRM_Finance1 from '../../assets/CRM_Finance1.png'
import CRM_Inventory from '../../assets/CRM_Inventory.png'
import { Helmet } from "react-helmet";

// Define the AccordionItem component
const AccordionItem = ({ title, content, index, openIndex, setOpenIndex }) => {
  const isOpen = index === openIndex;

  const toggleAccordion = () => {
    setOpenIndex(isOpen ? null : index);
  };

  return (
    <div className="accordion-item">
      <div
        className="accordion-title"
        type="text"
        onClick={toggleAccordion}
        style={{ fontSize: '18px', cursor: 'pointer' }}
      >
        <strong>{isOpen ? '- ' : '+ '}</strong>
        {' '}
        <strong>{title}</strong>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default function Enterprise() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const [openIndex, setOpenIndex] = React.useState(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
  };
  var settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <Helmet>
        <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Enterprise Application Modernization Services and Solutions | Dahooks" /><meta property="og:description" content="Dahooks offers professional Enterprise application modernization services to Enhanced Productivity & Efficiency, Improved Client Relationship, An edge over competitors, Enhanced Safety, Savings on Costs & Scalability. Contact us today!" /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Enterprise Application Modernization Services and Solutions | Dahooks" /><meta name="twitter:description" content="Dahooks offers professional Enterprise application modernization services to Enhanced Productivity & Efficiency, Improved Client Relationship, An edge over competitors, Enhanced Safety, Savings on Costs & Scalability. Contact us today!" /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
        <title>Enterprise Application Modernization Services and Solutions | Dahooks</title>
        <link rel="canonical" href="https://dahooks.com/Enterprise" />
      </Helmet>
      <div className="container pt-5">
        <div className="row ">
          <div className="col-lg-12 mt-5 py-4">
            <div className="contact-banner-heading p-lg-4  mt-5">
              <h1 className="headings">
                Enterprise Application Modernization Services
              </h1>
              <p className="headings">
                Utilize EAS Solution to transform business and lead with assurance.
              </p>
              <p className="headings">
                To stay competitive, boost efficiency, and modernize enterprise apps, digital transformation is necessary. Workflows are streamlined, productivity is increased, and user experience is enhanced with custom apps. Creating with change in mind fosters long-term success and unlocks potential.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <img src={Enterprise_Application} width="100%" />
          </div>
        </div>
      </div>
      <div className="container pt-5">
        <h1 className="headings text-center py-3">
          Enterprise Application Layer Modernization
        </h1>
        <p className="headings text-center">
          Companies need to update their enterprise software to stay competitive in the digital world. Modernizing outdated apps requires embracing digital transformation.
        </p>
        <div className="row my-5">
          <div className="col-xl-4 col-md-6 col-12 p-3 text-center text-lg-start">
            <div>
              <img src={Increased_Efficiency} alt="" width="60px" />
              <h3 className="mt-2">
                Enhanced Productivity & Efficiency
              </h3>
              <div className=" bg-white">
                <div style={{ fontSize: "14px", textAlign: "justify" }}>
                  Tasks are automated by software, which also maximizes output and decreases waste while boosting productivity.
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-12 p-3 text-center text-lg-start">
            <img src={Customer_Experience} alt="" width="60px" />
            <h3 className="mt-2">
              Improved Client Relationship
            </h3>
            <div className=" bg-white">
              <div style={{ fontSize: "14px", textAlign: "justify" }}>
                Customizing customer experiences with modern workplace apps can increase customer happiness and loyalty.
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-12 p-3 text-center text-lg-start">
            <img src={Competitive_Advantage} alt="" width="60px" />
            <h3 className="mt-2">An edge over competitors</h3>
            <div className=" bg-white">
              <div style={{ fontSize: "14px", textAlign: "justify" }}>
                Business applications help companies remain competitive and agile by assisting them to adjust to shifting circumstances and client demands.
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-12 p-3 text-center text-lg-start">
            <div>
              <img src={Improved_Security} alt="" width="60px" />
              <h3 className="mt-2">Enhanced Safety</h3>
              <div className=" bg-white">
                <div style={{ fontSize: "14px", textAlign: "justify" }}>
                  Security is prioritised in modern enterprise apps, lowering the chance of data breaches and enhancing threat response.
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-12 p-3 text-center text-lg-start">
            <img src={Cost_Savings} alt="" width="60px" />
            <h3 className="mt-2">Savings on Costs</h3>
            <div className=" bg-white">
              <div style={{ fontSize: "14px", textAlign: "justify" }}>
                Updating outdated business apps reduces infrastructure and maintenance costs.
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-12 p-3 text-center text-lg-start">
            <img src={Scalability} alt="" width="60px" />
            <h3 className="mt-2">Scalability</h3>
            <div className=" bg-white">
              <div style={{ fontSize: "14px", textAlign: "justify" }}>
                Scalability High data and user volume are handled by enterprise programs that are scalable and don't have performance problems.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="background py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div>
                <img className="kl_img" src={Enterprise_Application_Development} alt="" width={'100%'} />
              </div>
            </div>
            <div className="col-lg-6 col-12 my-auto">
              <div className="service-content">
                <div className="headings pt-3" style={{ fontSize: "23px", textAlign: "left", }}>
                  Services for Modernizing
                </div>
                <h1 className="headings py-3">
                  Enterprise Applications
                  Our Principal Products for Your Achievement
                </h1>
                <AccordionItem className="pb-3"
                  title="Consulting for Digital Strategy"
                  content="Businesses may create and execute a digital strategy that drives growth and is in line with their goals with the assistance of Dahooks."
                  index={1}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />
                <AccordionItem className="pb-3"
                  title="Development for the Cloud-Native"
                  content="Dahooks is skilled in developing cloud-native apps that offer performance, scalability, and high availability."
                  index={3}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />
                <AccordionItem className="pb-3"
                  title="Application Development That Is Agile"
                  content="Dahooks uses an Agile process that prioritizes teamwork, adaptability, and short iterations in order to produce high-caliber software more quickly."
                  index={4}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />
                <AccordionItem className="pb-3"
                  title="Development of Custom Applications"
                  content="Dahooks uses cutting-edge technologies like AI, blockchain, and IoT to assist organizations in creating custom apps that cater to their unique needs."
                  index={5}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />
                <AccordionItem className="pb-3"
                  title="Business Combination"
                  content="Dahooks can assist companies with integrating their current apps, systems, and workflows to enhance user experience and boost productivity."
                  index={6}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />
                <AccordionItem className="pb-3"
                  title="Modernization of Legacy Applications"
                  content="Dahooks can assist companies in updating their antiquated software so they can take advantage of contemporary innovations, boost productivity, and cut expenses."
                  index={7}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />
                <AccordionItem className="pb-3"
                  title="Upkeep and Assistance for Apps"
                  content="Dahooks can offer continuous maintenance and support services to guarantee that applications are secure, dependable, and current with emerging trends and technology."
                  index={8}
                  openIndex={openIndex}
                  setOpenIndex={setOpenIndex}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container py-5'>
        <div className='row'>
          <div className="col-lg-6 col-12 order-2 order-lg-1"  >
            <div className="tagline pt-5">
              <p>WHY CHOOSE Dahooks?</p>
              <h1> Why Dahooks Should Be Your First Pick for Services in Enterprise Modernization</h1>
            </div>
          </div>
          <div className='col-lg-6 col-12 order-1 order-lg-2'>
            <div className="service-content" >
              <div className="pb-3">
                <p className="mb-1"><strong>Extensive Knowledge</strong></p>
                <p>We have a track record of success in providing enterprise application development services and a wide range of industry experience.</p>
              </div>
              <div className="pb-3">
                <p className="mb-1"><strong>Comprehensive Understanding</strong></p>
                <p>To keep you ahead of the curve, our team of professionals has an in-depth understanding of the newest technology and market trends.</p>
              </div>
              <div className="pb-3">
                <p className="mb-1"><strong>Particularized Remedies</strong></p>
                <p>We offer customized corporate solutions that support your company's unique aims and objectives.</p>
              </div>
              <div className="pb-3">
                <p className="mb-1"><strong>Agile Approach</strong></p>
                <p>We employ an agile methodology to guarantee the prompt delivery of high-calibre solutions that meet your expectations.</p>
              </div>
              <div className="pb-3"></div>
              <p className="mb-1"><strong>Working Together</strong></p>
              <p>In close collaboration with our clients, we ascertain their specific demands and devise solutions that optimize their company performance.</p>
            </div>
            <div className="pb-3">
              <p className="mb-1"><strong>Verified Achievement</strong></p>
              <p>Our happy customers are evidence of our track record of providing creative solutions that encourage company expansion.</p>
            </div>
            <div className="pb-3">
              <p className="mb-1"><strong>Experience in the Industry</strong></p>
              <p>We have years of experience working in various industries, so we have the know-how to create solutions specifically tailored to your business requirements.</p>
            </div>
            <div>
              <p className="mb-1"><strong>Customer Attention</strong></p>
              <p>Because our client-centric strategy prioritizes your company's demands, we can guarantee that our solutions are tailored to your unique goals and objectives.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className="row">
            <div className="contact-banner-heading p-lg-4  mt-4">
              <h1>Sectors We've collaborated with</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 col-md-3 col-6">
              <div className="img-block">
                <div className="icon">
                  <div>
                    <img src={Health} width="60" mb-4 />
                  </div>
                </div>
                <p>Healthcare</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <div className="img-block">
                <div className="icon">
                  <div>
                    <img src={Legal} width="60" mb-4 />
                  </div>
                </div>
                <p>Legal</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <div className="img-block">
                <div className="icon">
                  <div>
                    <img src={Education} width="60" mb-4 />
                  </div>
                </div>
                <p>Education</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <div className="img-block">
                <div className="icon">
                  <div>
                    <img src={Finance} width="60" mb-4 />
                  </div>
                </div>
                <p>Finance</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <div className="img-block">
                <div className="icon">
                  <div>
                    <img src={MobInsurance} width="60" mb-4 />
                  </div>
                </div>
                <p>Insurance</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <div className="img-block">
                <div className="icon">
                  <div>
                    <img src={Wellness} width="60" mb-4 />
                  </div>
                </div>
                <p>Wellness</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 offset-lg-2">
              <div className="img-block">
                <div className="icon">
                  <div>
                    <img src={Banking} width="60" mb-4 />
                  </div>
                </div>
                <p>Banking</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <div className="img-block">
                <div className="icon">
                  <div>
                    <img src={Hospitality} width="60" mb-4 />
                  </div>
                </div>
                <p>Hospitality</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <div className="img-block">
                <div className="icon">
                  <div>
                    <img src={ECommerce} width="60" mb-4 />
                  </div>
                </div>
                <p>E-Commerce</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <div className="img-block">
                <div className="icon">
                  <div>
                    <img src={Logistics} width="60" mb-4 />
                  </div>
                </div>
                <p>Logistics</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="background py-5">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-12 my-auto py-4">
              <div className="contact-banner-heading p-lg-4  mt-4">
                <p className="headings" style={{ textAlign: "center", color: "#f4990b" }}>
                  CASE STUDIES
                </p>
                <h1 className="headings text-center">
                  Please take a closer look at our projects that offer enterprise application services.
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <Slider className="d-none d-lg-block" {...settings}>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={Dot_Net} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>.NET / INFORMATION TECHNOLOGY</div>
                    <p className="card-text">.NET Framework based Vehicle Speed Enforcing Application</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>.NET</div>
                      <div className='py-1 px-2 disble-btn mt-4'>INFORMATION TECHNOLOGY</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Health} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / HEALTHCARE / SUITECRM</div>
                    <p className="card-text">SuiteCRM Contivio Integrationfor Patient Care Management System</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>HEALTHCARE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Education} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / EDUCATION / SUITECRM</div>
                    <p className="card-text">Sales Process Automation using SuiteCRM for Education Bussiness</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>EDUCATION</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_ETL} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / ETL / PUBLICATION / SUGARCRM</div>
                    <p className="card-text">Order Management and Contract Management Automation using Customized SugarCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn '>CRM</div>
                      <div className='py-1 px-2 disble-btn '>ETL</div>
                      <div className='py-1 px-2 disble-btn '>PUBLICATION</div>
                      <div className='py-1 px-2 disble-btn '>SUGARCRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Publication} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / PUBLICATION / SUITECRM</div>
                    <p className="card-text">Order Management, Invoice Management and Contract Management Automation using SuiteCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn'>CRM</div>
                      <div className='py-1 px-2 disble-btn'>PUBLICATION</div>
                      <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Finance} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / FINANCE / SUITECRM</div>
                    <p className="card-text">GoldMine to SuiteCRM Migration</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-5'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-5'>FINANCE</div>
                      <div className='py-1 px-2 disble-btn mt-5'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Finance1} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / FINANCE / SUITECRM</div>
                    <p className="card-text">SuiteCRM Asterisk Telephony Integration</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>FINANCE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Inventory} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / SERVICE / SUITECRM</div>
                    <p className="card-text">Inventory Management Using SuiteCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SERVICE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
            <Slider className="d-none d-md-block d-lg-none" {...settings1}>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={Dot_Net} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>.NET / INFORMATION TECHNOLOGY</div>
                    <p className="card-text">.NET Framework based Vehicle Speed Enforcing Application</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>.NET</div>
                      <div className='py-1 px-2 disble-btn mt-4'>INFORMATION TECHNOLOGY</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Health} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / HEALTHCARE / SUITECRM</div>
                    <p className="card-text">SuiteCRM Contivio Integrationfor Patient Care Management System</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>HEALTHCARE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Education} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / EDUCATION / SUITECRM</div>
                    <p className="card-text">Sales Process Automation using SuiteCRM for Education Bussiness</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>EDUCATION</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_ETL} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / ETL / PUBLICATION / SUGARCRM</div>
                    <p className="card-text">Order Management and Contract Management Automation using Customized SugarCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn '>CRM</div>
                      <div className='py-1 px-2 disble-btn '>ETL</div>
                      <div className='py-1 px-2 disble-btn '>PUBLICATION</div>
                      <div className='py-1 px-2 disble-btn '>SUGARCRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Publication} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / PUBLICATION / SUITECRM</div>
                    <p className="card-text">Order Management, Invoice Management and Contract Management Automation using SuiteCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn'>CRM</div>
                      <div className='py-1 px-2 disble-btn'>PUBLICATION</div>
                      <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Finance} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / FINANCE / SUITECRM</div>
                    <p className="card-text">GoldMine to SuiteCRM Migration</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-5'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-5'>FINANCE</div>
                      <div className='py-1 px-2 disble-btn mt-5'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Finance1} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / FINANCE / SUITECRM</div>
                    <p className="card-text">SuiteCRM Asterisk Telephony Integration</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>FINANCE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Inventory} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / SERVICE / SUITECRM</div>
                    <p className="card-text">Inventory Management Using SuiteCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SERVICE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
            <Slider className="d-md-none" {...settings2}>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={Dot_Net} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>.NET / INFORMATION TECHNOLOGY</div>
                    <p className="card-text">.NET Framework based Vehicle Speed Enforcing Application</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>.NET</div>
                      <div className='py-1 px-2 disble-btn mt-4'>INFORMATION TECHNOLOGY</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Health} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / HEALTHCARE / SUITECRM</div>
                    <p className="card-text">SuiteCRM Contivio Integrationfor Patient Care Management System</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>HEALTHCARE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Education} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / EDUCATION / SUITECRM</div>
                    <p className="card-text">Sales Process Automation using SuiteCRM for Education Bussiness</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>EDUCATION</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_ETL} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / ETL / PUBLICATION / SUGARCRM</div>
                    <p className="card-text">Order Management and Contract Management Automation using Customized SugarCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn '>CRM</div>
                      <div className='py-1 px-2 disble-btn '>ETL</div>
                      <div className='py-1 px-2 disble-btn '>PUBLICATION</div>
                      <div className='py-1 px-2 disble-btn '>SUGARCRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Publication} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / PUBLICATION / SUITECRM</div>
                    <p className="card-text">Order Management, Invoice Management and Contract Management Automation using SuiteCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn'>CRM</div>
                      <div className='py-1 px-2 disble-btn'>PUBLICATION</div>
                      <div className='py-1 px-2 disble-btn'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Finance} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / FINANCE / SUITECRM</div>
                    <p className="card-text">GoldMine to SuiteCRM Migration</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-5'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-5'>FINANCE</div>
                      <div className='py-1 px-2 disble-btn mt-5'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Finance1} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / FINANCE / SUITECRM</div>
                    <p className="card-text">SuiteCRM Asterisk Telephony Integration</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>FINANCE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 col-12  d-flex justify-content-center justify-content-md-left'>
                <div className="card mb-2 mb-lg-0" style={{ width: "16rem" }}>
                  <img src={CRM_Inventory} className="card-img-top" alt="..." />
                  <div className="card-body">
                    <div className='py-2' style={{ fontSize: 12, color: '#BEBEBE' }}>CRM / SERVICE / SUITECRM</div>
                    <p className="card-text">Inventory Management Using SuiteCRM</p>
                    <div className='d-flex'>
                      <div className='py-1 px-2 disble-btn mt-4'>CRM</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SERVICE</div>
                      <div className='py-1 px-2 disble-btn mt-4'>SUITECRM</div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
