import React, { useEffect } from "react";
import "../digital-commerce/digital-commerce.css";
import "./insurance.css"
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Technology_Integration from "../../assets/insurance-bg.jpg";
import Improved_Efficiency from "../../assets/Improved Efficiency and Productivity.png";
import Enhanced_Customer from "../../assets/Enhanced Customer Experience.png";
import Cost_Savings from "../../assets/Cost Savings.jpg";
import Teamwork from "../../assets/teamwork.png";
import Flexibility from "../../assets/flexibility.png";
import Decision_Making from "../../assets/decision-making.png";
import Health from "../../assets/Healthcare.png";
import Legal from "../../assets/Legal.png";
import Education from "../../assets/Education.png";
import Finance from "../../assets/Finance.png";
import MobInsurance from "../../assets/MobInsurance.png";
import CRM from "../../assets/crm.png";
import Cost from '../../assets/cost.png'
import Quality from '../../assets/quality1.png'
import Communication from '../../assets/communication.png'
import Flex from '../../assets/flexibility.png'
import Security from '../../assets/security.png'
import Insurance_img from '../../assets/insurance.jpg'
import { Helmet } from "react-helmet";

const AccordionItem = ({ title, content, index, openIndex, setOpenIndex }) => {
    const isOpen = index === openIndex;
    const toggleAccordion = () => {
        setOpenIndex(isOpen ? null : index);
    };
    return (
        <div className="accordion-item">
            <div
                className="accordion-title"
                type="text"
                onClick={toggleAccordion}
                style={{ fontSize: "18px", cursor: "pointer" }}
            >
                <strong>{isOpen ? "- " : "+ "}</strong> <strong>{title}</strong>
            </div>
            {isOpen && <div className="accordion-content">{content}</div>}
        </div>
    );
};

export default function InsuranceSec() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const [openIndex, setOpenIndex] = React.useState(null);
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
    };
    var settings1 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
    };
    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };
    var settings3 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
    };
    return (
        <>
            <Helmet>
                <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Insurance Software Development Company | Insurance Software Solutions" /><meta property="og:description" content="Dahooks is a leading Insurance software Development Company in India, USA. We provide Web & Mobile app Software Development Solutions for Insurance Industry hire our experienced developers today." /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Insurance Software Development Company | Insurance Software Solutions" /><meta name="twitter:description" content="Dahooks is a leading Insurance software Development Company in India, USA. We provide Web & Mobile app Software Development Solutions for Insurance Industry hire our experienced developers today." /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
                <title>Insurance Software Development Company | Insurance Software Solutions</title>
                <link rel="canonical" href="https://dahooks.com/Insurance" />
            </Helmet>
            <div className="insurance">
                <div className="container pt-5">
                    <div className="row ">
                        <div className="col-lg-12 mt-5 py-5">
                            <div className=" p-lg-4  mt-5">
                                <h1 className="mb-0 text-center" style={{ color: "#FFB800", }}>
                                    IT Solutions for the Insurance Sector
                                </h1>
                                <div className="headings" style={{ fontSize: "28px", textAlign: "center", lineHeight: "70px", fontWeight: 700 }}>
                                    Modern Tech Solutions for Transformation
                                </div>
                                <p className="text-center">
                                    To stay ahead of the competition and meet the evolving requirements of their customers in today's fast-paced world, insurance companies must employ cutting-edge solutions. The conventional methods of insurance firms are becoming less and less effective, and they must use new technologies such as artificial intelligence (AI), the Internet of things (IoT), and big data to keep up.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <img src={Technology_Integration} width="100%" />
                        </div>
                    </div>
                </div>
                <div className="container pb-5">
                    <div className="row">
                        <div className="col-lg-12 my-auto py-4">
                            <div className=" p-lg-4  mt-4">
                                <p className="text-center" style={{ color: "#f4990b" }}>DEMAND</p>
                                <h1 className="text-center" style={{}}>Examining Technology's Role in Modernizing the Insurance Industry.</h1>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <img src={Insurance_img} width={"100%"} />
                        </div>
                        <div className="col-lg-6 col-12 my-auto">
                            <h1>Growing need for insurance services</h1>
                            <p>Insurance businesses struggle to keep up with the demands of a shifting consumer base, intense competition, and mounting costs. Adoption of technology, such as artificial intelligence (AI), the Internet of Things (IoT), and big data, can reduce fraud, generate new products, and improve risk assessment, customer experience, and operational efficiency. In today's quickly changing industry, insurers must use technology to remain competitive.</p>
                        </div>
                    </div>
                </div>
                <div className='container py-5'>
                    <div className='row'>
                        <div className="col-lg-6 col-12"  >
                            <div className="tagline">
                                <p style={{ color: "#f4990b" }}>OUR SOFTWARE AND INSURANCE IT SOLUTIONS</p>
                                <h1 style={{}}>Regarding Agencies: Carriers, Brokers, Insurance Agents, and Providers</h1>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12'>
                            <div className="service-content" >
                                <p><strong>Customer Relationship Management (CRM)</strong></p>
                                <p>This system assists with keeping track of communications, organizing information about customers, and streamlining repetitive tasks for future correspondence.</p>

                                <p><strong>Software Underwriting</strong></p>
                                <p>Reduces the time and expense needed to assess risks and approve policies by automating the underwriting process.</p>

                                <p><strong>Software for Policy Administration</strong></p>
                                <p>simplifies the process of managing policies, enabling insurers to draft, amend, and distribute policies more quickly.</p>

                                <p><strong>Software for Claims Management</strong></p>
                                <p>Reduces errors and raises customer satisfaction by assisting in the management of the whole claims process, from initial filing to final payment.</p>


                                <p><strong>Software for Processing Bills and Payments</strong></p>
                                <p>Streamlines the billing process, facilitating payments from clients and payment tracking for insurance.</p>

                                <p><strong>Software for Document Management</strong></p>
                                <p>Aids in file and document management, minimizing the requirement for physical storage and enhancing access to crucial data.</p>

                                <p><strong>Software for Data Analytics</strong></p>
                                <p>Aids insurers in the analysis of vast amounts of data to spot trends, evaluate risks, and make informed judgments</p>

                                <p><strong>Software for Detecting Fraud</strong></p>
                                <p>Identifies fraudulent activity using AI and machine learning, which helps insurers lower losses and increase overall profitability.</p>

                                <p><strong>Software for marketing automation</strong></p>
                                <p>Helps insurance firms increase client acquisition and cut marketing expenses by helping them target the right people with the right products.</p>

                                <p><strong>Smartphone applications</strong></p>
                                <p>Allow customers to conveniently process payments, submit claims, and view their policy details while on the move to improve their overall satisfaction and user experience.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="background py-5">
                    <div className="container-fluid">
                        <div className="head" style={{ textAlign: "center" }}>
                            <p className="text-center" style={{ color: "#f4990b" }}>FOCUS AREAS</p>
                            <h1 className="mb-5" style={{}}>Important areas of concentration while creating strategies for insurance companies</h1>
                        </div>
                        <div className="row">
                            <Slider className="d-none d-md-block d-lg-none" {...settings}>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services hire-card" style={{ width: "18rem" }}>
                                        <img src={Health} className="card-img-top card-image " alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Enterprise Solutions</p>
                                            <ul>
                                                <li>Manage customer data, policies, claims, and billing.</li>
                                                <li>CRM System: Enhancing Customer Experience</li>
                                                <li>Implement ERP for streamlined processes, efficiency.</li>
                                            </ul>
                                            <div className='read-more pt-2'><Link to='/Enterprise'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services web-card" style={{ width: "18rem" }}>
                                        <img src={Legal} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Artificial Intelligence</p>
                                            <ul>
                                                <li>Automate tasks, reduce errors, improve accuracy.</li>
                                                <li>Automate interactions, enhance experience.</li>
                                                <li>Predictive analytics for customer insights and future needs.</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/Artificial-Intelligence'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services mobile-app-card" style={{ width: "18rem" }}>
                                        <img src={Education} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Technology Integration</p>
                                            <ul>
                                                <li>Digital tech boosts efficiency: Cloud, IoT, blockchain.</li>
                                                <li>Improve scalability and flexibility with microservices.</li>
                                                <li>DevOps: Boost Collaboration, Accelerate Delivery</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/Technology-Integration'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services ui-card" style={{ width: "18rem" }}>
                                        <img src={Finance} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Digital Commerce</p>
                                            <ul>
                                                <li>Create app for ins. products, policy management &amp; claim filing</li>
                                                <li>Enable e-commerce: payments, history, preferences.</li>
                                                <li>Targeted campaigns for customer acquisition.</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/Digital-Commerce'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services hire-card" style={{ width: "18rem" }}>
                                        <img src={MobInsurance} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Business Automation</p>
                                            <ul>
                                                <li>Automate tasks with RPA: data entry, form filling, policy admin.</li>
                                                <li>Automate workflows for better efficiency.</li>
                                                <li>Automate customer interactions, boost experience with chatbots.</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/Business-Automation'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services web-card" style={{ width: "18rem" }}>
                                        <img src={CRM} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">BI and Analytics</p>
                                            <ul>
                                                <li>Utilize data tools for customer insights on behavior, preferences, and needs.</li>
                                                <li>Data-driven culture: Improve underwriting, risk assessment, claims processing.</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/BI'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                            <Slider className="d-none d-lg-block" {...settings3}>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services hire-card" style={{ width: "18rem" }}>
                                        <img src={Health} className="card-img-top card-image " alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Enterprise Solutions</p>
                                            <ul>
                                                <li>Manage customer data, policies, claims, and billing.</li>
                                                <li>CRM System: Enhancing Customer Experience</li>
                                                <li>Implement ERP for streamlined processes, efficiency.</li>
                                            </ul>
                                            <div className='read-more pt-2'><Link to='/Enterprise'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services web-card" style={{ width: "18rem" }}>
                                        <img src={Legal} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Artificial Intelligence</p>
                                            <ul>
                                                <li>Automate tasks, reduce errors, improve accuracy.</li>
                                                <li>Automate interactions, enhance experience.</li>
                                                <li>Predictive analytics for customer insights and future needs.</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/Artificial-Intelligence'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services mobile-app-card" style={{ width: "18rem" }}>
                                        <img src={Education} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Technology Integration</p>
                                            <ul>
                                                <li>Digital tech boosts efficiency: Cloud, IoT, blockchain.</li>
                                                <li>Improve scalability and flexibility with microservices.</li>
                                                <li>DevOps: Boost Collaboration, Accelerate Delivery</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/Technology-Integration'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services ui-card" style={{ width: "18rem" }}>
                                        <img src={Finance} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Digital Commerce</p>
                                            <ul>
                                                <li>Create app for ins. products, policy management &amp; claim filing</li>
                                                <li>Enable e-commerce: payments, history, preferences.</li>
                                                <li>Targeted campaigns for customer acquisition.</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/Digital-Commerce'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services hire-card" style={{ width: "18rem" }}>
                                        <img src={MobInsurance} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Business Automation</p>
                                            <ul>
                                                <li>Automate tasks with RPA: data entry, form filling, policy admin.</li>
                                                <li>Automate workflows for better efficiency.</li>
                                                <li>Automate customer interactions, boost experience with chatbots.</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/Business-Automation'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services web-card" style={{ width: "18rem" }}>
                                        <img src={CRM} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">BI and Analytics</p>
                                            <ul>
                                                <li>Utilize data tools for customer insights on behavior, preferences, and needs.</li>
                                                <li>Data-driven culture: Improve underwriting, risk assessment, claims processing.</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/BI'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                            <Slider className="d-md-none" {...settings2}>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services hire-card" style={{ width: "18rem" }}>
                                        <img src={Health} className="card-img-top card-image " alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Enterprise Solutions</p>
                                            <ul>
                                                <li>Manage customer data, policies, claims, and billing.</li>
                                                <li>CRM System: Enhancing Customer Experience</li>
                                                <li>Implement ERP for streamlined processes, efficiency.</li>
                                            </ul>
                                            <div className='read-more pt-2'><Link to='/Enterprise'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services web-card" style={{ width: "18rem" }}>
                                        <img src={Legal} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Artificial Intelligence</p>
                                            <ul>
                                                <li>Automate tasks, reduce errors, improve accuracy.</li>
                                                <li>Automate interactions, enhance experience.</li>
                                                <li>Predictive analytics for customer insights and future needs.</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/Artificial-Intelligence'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services mobile-app-card" style={{ width: "18rem" }}>
                                        <img src={Education} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Technology Integration</p>
                                            <ul>
                                                <li>Digital tech boosts efficiency: Cloud, IoT, blockchain.</li>
                                                <li>Improve scalability and flexibility with microservices.</li>
                                                <li>DevOps: Boost Collaboration, Accelerate Delivery</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/Technology-Integration'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services ui-card" style={{ width: "18rem" }}>
                                        <img src={Finance} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Digital Commerce</p>
                                            <ul>
                                                <li>Create app for ins. products, policy management &amp; claim filing</li>
                                                <li>Enable e-commerce: payments, history, preferences.</li>
                                                <li>Targeted campaigns for customer acquisition.</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/Digital-Commerce'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services hire-card" style={{ width: "18rem" }}>
                                        <img src={MobInsurance} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">Business Automation</p>
                                            <ul>
                                                <li>Automate tasks with RPA: data entry, form filling, policy admin.</li>
                                                <li>Automate workflows for better efficiency.</li>
                                                <li>Automate customer interactions, boost experience with chatbots.</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/Business-Automation'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                                    <div className="card core-services web-card" style={{ width: "18rem" }}>
                                        <img src={CRM} className="card-img-top card-image" alt="..." />
                                        <div className="card-body">
                                            <p className="card-title">BI and Analytics</p>
                                            <ul>
                                                <li>Utilize data tools for customer insights on behavior, preferences, and needs.</li>
                                                <li>Data-driven culture: Improve underwriting, risk assessment, claims processing.</li>
                                            </ul>
                                            <div className='read-more pt-2 '><Link to='/BI'>Read More-</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 my-auto py-4">
                            <div className="  p-lg-4  mt-4 ">
                                <div className="row">
                                    <p className="heading text-center" style={{ color: "rgb(244, 153, 11)" }}>KEY BENEFITS</p>
                                    <h1 className="mt-0 text-center" style={{}}>All crucial elements for learning online at any time<br /> and from any place</h1>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                                        <div>
                                            <img src={Improved_Efficiency} width="60px" />
                                            <h3 className="mt-3"><strong>Enhanced Effectiveness</strong></h3>
                                            <p>Insurance firms can operate more quickly and efficiently by automating the underwriting, claims processing, and policy issuance processes.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                                        <div>
                                            <img src={Enhanced_Customer} width="60px" />
                                            <h3 className="mt-3"><strong>Improve the Client Experience</strong></h3>
                                            <p>Technologies like chatbots, portals, and mobile apps can enhance communications and experiences between customers and insurers.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                                        <div>
                                            <img src={Teamwork} width="60px" />
                                            <h3 className="mt-3"><strong>Improved Risk Evaluation</strong></h3>
                                            <p>In order to improve underwriting decisions, insurers can evaluate risks and spot trends with the use of data analytics technologies.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                                        <div>
                                            <img src={Cost_Savings} width="60px" />
                                            <h3 className="mt-3"><strong>An edge over competitors</strong></h3>
                                            <p>Using new tools and technologies can help insurers stay competitive, relevant, and draw in new business.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                                        <div>
                                            <img src={Flexibility} width="60px" />
                                            <h3 className="mt-3"><strong>Boost Savings</strong></h3>
                                            <p>By automating processes and lowering manual labor, insurers may increase their bottom line and save operating costs.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12 text-center text-lg-start">
                                        <div>
                                            <img src={Decision_Making} width="60px" />
                                            <h3 className="mt-3"><strong>Increased Safety</strong></h3>
                                            <p>Insurers can reduce risk and avoid breaches by using encryption and multi-factor authentication to safeguard client data.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 text-center">
                            <div className="heading py-2" style={{ color: "rgb(244, 153, 11)" }}>WHY CHOOSE US?</div>
                            <h1 className="mb-5" style={{}}>Let Dahooks take care of your IT needs for insurance.</h1>
                        </div>
                        <div className="col-md-4 col-12 mt-3 mt-md-0">
                            <div className="p-4 cost-box">
                                <div className="mb-3"><img src={Security} width={"50px"} /></div>
                                <div className="cost-heading mb-3"><strong>Expertise</strong></div>
                                <p>Tech professionals who understand rules and specifications and provide customized solutions for all of your requirements.</p>
                            </div>
                        </div>
                        <div className=" col-md-4 col-12 mt-3 mt-md-0">
                            <div className="p-4 cost-box">
                                <div className="mb-3"><img src={Quality} width={"50px"} /></div>
                                <div className="cost-heading mb-3"><strong>Quality</strong></div>
                                <p>A track record of meeting deadlines, producing high-quality work, and offering clients the finest options.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mt-3 mt-md-0">
                            <div className="p-4 cost-box">
                                <div className="mb-3"><img src={Security} width={"50px"} /></div>
                                <div className="cost-heading mb-3"><strong>Security</strong></div>
                                <p className="pb-4 mb-2">We put a high priority on data privacy and safeguard customer data with robust security procedures.</p>
                            </div>
                        </div>
                        <div className=" col-md-4 col-12 mt-3">
                            <div className="p-4 cost-box">
                                <div className="mb-3"><img src={Communication} width={"50px"} /></div>
                                <div className="cost-heading mb-3"><strong>Communication</strong></div>
                                <p>Communicative responsiveness and close collaboration with clients to comprehend their needs.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mt-3">
                            <div className="p-4 cost-box">
                                <div className="mb-3"><img src={Flex} width={"50px"} /></div>
                                <div className="cost-heading mb-3"><strong>Flexibility</strong></div>
                                <p>We make adjustments to our solutions in response to our clients' evolving needs.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 mt-3">
                            <div className="p-4 cost-box">
                                <div className="mb-3"><img src={Cost} width={"50px"} /></div>
                                <div className="cost-heading mb-3"><strong>Cost-effectiveness</strong></div>
                                <p className="pb-4 mb-2">Economical alternatives that don't sacrifice knowledge or quality.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}