import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import SMigration from '../../../assets/Migration.jpg'

export default function Migration() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>SugarCRM Pro to SuiteCRM Migration</strong></div>
                    <p className='mt-2 text-center'>Client is the largest company selling products for packaging and focused on the manufacture and distribution of products for packaging, industrial supplies and disposable products.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>Client was looking to migrate from SugarCRM 7 Pro (On Demand) to SuiteCRM to reduce the costs and manage the number of Users increasing day by day. As per the Client requirements, there were some major customization need to be done in SugarCRM 7 Pro (On Demand) which was time consuming and costly.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>SugarCRM 7 Pro (On Demand), SugarCRM, SuiteCRM</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={SMigration} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We have migrated all the functionalities and data from SugarCRM Pro to SuiteCRM. Before adding new/enhanced functionalities, we have migrated the existing modules such as Prospects, Contacts, Opportunity and Quotes and various others important modules along with their respective data from SugarCRM Pro to SuiteCRM. Our system migration approach is to perform detailed analysis of the existing system for better effectiveness in migration of functionalities and data to the new environment. This way we always retain the relationship between the modules, fields or records that are critical for the new system work effectively.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>This helped in a smoother user acceptance run during final implementation of the new system. The migration has resolved couple of operational issues along with cost concerns of the client.</p>
                </div>
            </div>
        </div>
    </>
  )
}
