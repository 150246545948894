import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import GoldM from '../../../assets/goldmine.jpg'

export default function GoldMine() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>GoldMine to SuiteCRM Migration</strong></div>
                    <p className='mt-2 text-center'>One of the US based companies that deals in Fuel Cards and manage associated discounts and royalty points for the subscribers.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>Client was using GoldMine system to manage their Customer Information and wanted to migrate to SuiteCRM for having a better access to automate the Marketing, Pre and Post Sales processes. They also had a set of customized requirements which were not directly catered by the default functionality of SuiteCRM and needed code level customization.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>GoldMine, SuiteCRM</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={GoldM} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>As first step we studied all the existing functionalities (modules, workflows, reports etc.) and data set of the GoldMine system. Based on that understanding we customized the default modules, designed/developed custom modules, built the required dependencies and relationships between the modules in the SuiteCRM development instance. Then we did a data cleaning and deduping before importing and distributing them into the relevant modules. We did apply multiple logic to categorize and distribute the existing data, that were previously managed only as Contacts in GoldMine system, into modules like Accounts, Contacts, Opportunities, Vendors etc. before building the workflows and reports.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>This helped in a smoother user acceptance run during final implementation of the new system. The migration resolved couple of customer data management issues and allowed the client to automate their Marketing, Sales, Vendor Management and Customer Service Desk process more effectively.</p>
                </div>
            </div>
        </div>
    </>
  )
}
