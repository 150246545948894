import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import PortalD from '../../../assets/Portal-Development.jpg'

export default function PortalDev() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Portal Development and Tableau Dashboards Integration</strong></div>
                    <p className='mt-2 text-center'>The Client is an end-to-end revenue cycle improvement company headquartered in Southfield, Michigan, dedicated to achieving sustainable improvement for healthcare systems by the Healthcare Service Provider.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>The Client wanted to design and develop a portal with tight integration with Tableau, a very popular Business Intelligence (BI) tool, to publish various Analytics and Dashboards for the user as per their access rights.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>HEALTHCARE / PHP FRAMEWORK / TABLEAU</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={PortalD} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>Dahooks Software Designed and Developed a Portal leveraging ZEND Framework 2 (ZF2) with high level of PHP Security layer implementation and integration with Tableau JS API. With responsive design implementation leveraging Bootstrap, the Portal allowed creating various Users with defined roles/permissions where Admin might upload Tableau Dashboards to portal and provided permissions to different Users as per need. We also implemented SSL on Tableau server for higher security.</p>
                </div>
                <div className='col-12'>
                    <div className='Vehicle-text'>PHP:</div>
                    <p className='mt-4'>It is a high-end open source technology that can be used for building feature-rich and visually appealing web applications for businesses. PHP is a server-side scripting language, and it is capable of rendering dynamic pages in real time</p>
                </div>
                <div className='col-12'>
                    <div className='Vehicle-text'>ZEND Framework 2:</div>
                    <p className='mt-4'>The Zend Framework 2 Certification is an industry-wide standard that recognizes the attainment of a professional level of expertise in using Zend Framework 2. Our PHP ZEND 2 application development experts help you to build a GDPR compliance integrated and scalable technology platform for you to run your business with higher effectiveness.</p>
                </div>
                <div className='col-12'>
                    <div className='Vehicle-text'>MySQL:</div>
                    <p className='mt-4'>Relational Database Management System which is an open source, freely available. SQL is the best option to go with because of its proven reliability, flexibility and ease of use and quick processing.</p>
                </div>
                <div className='col-12'>
                    <div className='Vehicle-text'>Tableau:</div>
                    <p className='mt-4'>Tableau is business intelligence software that allows anyone to easily connect to data, then visualize and create interactive, sharable dashboards. It’s easy enough that any Excel user can learn it, but powerful enough to satisfy even the most complex analytical problems.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The Portal helped the Client in designing and publishing Tableau dashboards at a commonplace while managing the access rights of the users effectively as per their Role, Involvement in Processes and Data Visibility needs.</p>
                </div>
            </div>
        </div>
    </>
  )
}
