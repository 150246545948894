import React, { useEffect } from "react";
import "../IOT/IOT.css";
import Autmotive from "../../assets/Automative.png";
import SmartCity from "../../assets/Smartcity.jpg";
import SmartHome from "../../assets/Smarthome.jpg";
import Industrial from "../../assets/Industrial.png";
import SmartHealth from "../../assets/Smarthealth.png";
import SmartEnergy from "../../assets/Iot_Smartenergy.jpg";
import workprocess from "../../assets/workprocess.svg";

export default function IOT() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-12 mt-5 py-4">
            <div className="contact-banner-heading p-lg-4  mt-5">
              <h1>We offer industrial IoT solutions and custom<br /> application development services.</h1>
              <p>
                Dahooks is a prominent provider of Internet of Things services and solutions. Dahooks' expertise and unique approach enable businesses to leverage the potential of IoT by designing cutting-edge applications for current hardware-connected devices.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12 my-auto order-2 order-lg-1">
              <div className="service-content">
                <h2>IoT Solutions for Automotive</h2>
                <p>We develop and provide Connected Car systems that incorporate Bluetooth-powered OBD-2 devices, GPS, geofencing, and IoT technology. These solutions allow us to collect real-time and continuous data about vehicles, their positions, and other pertinent information. This data is used for a variety of business purposes.</p>
                <p>IoT solutions for the automotive industry can be a perfect combination of onboard sensors, tailored mobile apps, cloud storage, and the technology required to retrieve data. It contributes to IoT applications and solutions such as:</p>
              </div>
              <div className="service-points">
                <ul className="two-col px-3">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <li>OBD-2 Based Vehicle Diagnostic Solution</li>
                      <li>Remote Vehicle Monitoring</li>
                      <li>Driving Behavior Monitoring and Safety</li>
                      <li>Smart Fleet Management System</li>
                      <li>Predictive Vehicle Maintenance</li>
                      <li>Smart Transportation and Logistics</li>
                      <li>School Bus Tracking</li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-12 order-1 order-lg-2">
              <div className="service-content">
                <img className="kl_img" src={Autmotive} alt="" width={"100%"} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center ">
            <img className="kl_img1" src={workprocess} alt="" />
          </div>
        </div>
      </div>
      <div className="background py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <img className="kl_img" src={SmartCity} alt="" width={"100%"} />
            </div>
            <div className="col-lg-6 col-12 my-auto">
              <div className="service-content">
                <h2>IoT Solutions for Retail</h2>
                <p>Use our state-of-the-art IoT technologies to improve your retail operations. Having worked in the IT industry for more than 12 years, we are experts at offering IT solutions designed specifically for retail businesses. Our experience is in optimizing supply chain management with cutting-edge IoT apps and solutions.</p>
                <p>Implementing our smart retail solutions might help you obtain a competitive advantage in the market. These solutions are intended to improve customer experiences, resulting in increased satisfaction and loyalty. Our innovative IoT technology can help you improve processes, increase productivity, and ultimately raise your retail business's performance.</p>
              </div>
              <div className="service-points">
                <ul className="two-col px-3">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <li>Smart Retail Supply Chain Management.</li>
                      <li>Smart Mirror for Virtual Try-Ons</li>
                      <li>Smart Shelves</li>
                      <li>Smart POS</li>
                      <li>Smart NFC based Payments</li>
                      <li>BLE based Proximity Marketing</li>
                      <li>Smart Delivery Management</li>
                      <li>Inventory & Warehouse Automation</li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center ">
            <img className="kl_img" src={workprocess} alt="" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12 my-auto order-2 order-lg-1">
              <div className="service-content">
                <h2>Smart Home Automation</h2>
                <p>We provide cutting-edge technological solutions for your home that will improve the quality and enjoyment of your life. We provide customized experiences in your local neighborhood using Internet of Things technologies. Our solutions for Smart Home Automation are made with our clients in mind and tailored to your specific needs. You can easily operate and keep an eye on a variety of home appliances with our Android and iOS apps, such as door contacts, switches, motion and light sensors, smart thermostats, interior security cameras, and smart locks.</p>
              </div>
              <div className="service-points">
                <ul className="two-col px-3">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <li>Management of Wastes</li>
                      <li>Parking Control</li>
                      <li>IoT sensors to measure and control the home environment</li>
                      <li>Utilize Customized Timetables</li>
                      <li>Energy Conservation & Enhancement</li>
                    </div>
                    <div className="col-lg-6 col-12">
                      <li>Finding Leaks in the Water</li>
                      <li>Management of Home Security</li>
                      <li>Use IoT-based mobile apps to control your home appliances.</li>
                      <li>Limit Access to Your Home</li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-12 order-1 order-lg-2">
              <div className="service-content">
                <img className="kl_img" src={SmartHome} alt="" width={"100%"} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center ">
            <img className="kl_img1" src={workprocess} alt="" />
          </div>
        </div>
      </div>
      <div className="background py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <img className="kl_img" src={Industrial} alt="" width={"100%"} />
            </div>
            <div className="col-lg-6 col-12 my-auto">
              <div className="service-content">
                <h2>Industrial IoT Solution</h2>
                <p>With our Industrial IoT apps, we streamline business procedures and increase production and enterprise productivity. Real-time transparency is ensured by these intelligent tools, which facilitate smooth communication between humans and machines. Our IoT apps also support effective supply chain management, warehouse management, asset tracking, and delivery systems.</p>
              </div>
              <div className="service-points ">
                <ul className="two-col px-3">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <li>Total Automation of the Factory</li>
                      <li>Remote observation and quality assurance</li>
                      <li>Monitor Production Tools Using Internet of Things Sensors</li>
                      <li>Clever Asset Monitoring Programs</li>
                      <li>Intelligent Storage and warehousing system</li>
                      <li>Solutions for Fleet Management</li>
                      <li>Unique Applications for Mobile and Web Analysis</li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center ">
            <img className="kl_img" src={workprocess} alt="" />
          </div>
        </div>
      </div>
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12 my-auto order-2 order-lg-1">
              <div className="service-content">
                <h2>Smart Healthcare Solution</h2>
                <p>We are glad to be a member of the healthcare industry, which has greatly profited from Internet of Things technologies. We offer personalised and intelligent healthcare solutions based on IoT and other technologies such as wearables, AR/VR, AI/ML, and custom iOS and Android app development.</p>
                <p>Our goal is to improve the efficiency and intelligence of healthcare. Our IoT-enabled healthcare applications are made to abide with HIPAA laws and offer a number of benefits to patients, healthcare providers, and professionals. These advantages include other useful features as well as easier access to EMR and EHR records.</p>
              </div>
              <div className="service-points">
                <ul className="two-col px-3">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <li>Remote monitoring of health</li>
                      <li>Tracking of drugs</li>
                      <li>Diagnose patients remotely</li>
                      <li>Solutions for Hospital Mobility</li>
                      <li>Medical IoT Device Apps</li>
                    </div>
                    <div className="col-lg-6 col-12">
                      <li>Telemetry for ambulances</li>
                      <li>hospital asset monitoring</li>
                      <li>AI-ML-Powered Medical Diagnostics</li>
                      <li>Health Insurance Companies and IoT</li>
                      <li>Astute Inventory Control</li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-12 order-1 order-lg-2">
              <div className="service-content">
                <img className="kl_img" src={SmartHealth} alt="" width={"100%"} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center ">
            <img className="kl_img1" src={workprocess} alt="" />
          </div>
        </div>
      </div>
      <div className="background py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <img className="kl_img" src={SmartEnergy} alt="" width={"100%"} />
            </div>
            <div className="col-lg-6 col-12 my-auto">
              <div className="service-content">
                <h2>Smart Energy Management</h2>
                <p>Businesses can reduce their energy expenses by up to 30% by utilizing our intelligent energy management solutions. Businesses that integrate IoT devices, AI-ML technology, and big data can obtain important insights about energy efficiency and consumption. With more predictive energy optimization, our IoT energy management solutions help companies reduce their carbon footprints and find waste sources.</p>
              </div>
              <div className="service-points">
                <ul className="two-col px-3">
                  <div className="row">
                    <div className="col-lg-6 col-12">
                      <li>Smart Metering Systems</li>
                      <li>Remote Energy Equipment Monitoring</li>
                      <li>Predictive Energy Optimization</li>
                      <li>Data Analytics</li>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="contact-banner-heading p-lg-4 ">
            <h1>IoT app applications utilizing AI and ML</h1>
            <p>By carefully integrating AI and ML technologies into your IoT applications, you may open up new possibilities.</p>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
            <div className=" bg-white p-3 custom-card">
              <h3>Interconnected Self-Driving Cars</h3>
              <div className="ctgry">
                <span className="py-1 px-2 disble-btn">Automotive</span>
                <span className="py-1 px-2 disble-btn">Transportation</span>
              </div>
              <p>We power computer vision, which enables autonomous vehicles to make decisions in a human-like manner, with machine learning and deep learning algorithms.</p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-12 mt-3 mt-md-0 d-flex justify-content-center">
            <div className=" bg-white p-3 custom-card">
              <h3>Accurate Agriculture & Reproduction</h3>
              <div className="ctgry">
                <span className="py-1 px-2 disble-btn">Agriculture</span>
              </div>
              <p>Precision farming detects pests, plant illnesses, and undernourished plants via artificial intelligence (AI) and Internet of Things (IoT) sensors.</p>
            </div>
          </div>
          <div className="mt-3 mt-xl-0 col-xl-4 col-md-6 col-12 d-flex justify-content-center">
            <div className=" bg-white p-3 custom-card">
              <h3>Conversation-activated Smart Homes</h3>
              <div className="ctgry">
                <span className="py-1 px-2 disble-btn">Real Estate</span>
              </div>
              <p>The development of automated voice homes, whose operation is managed by an app with improved security, has been facilitated by AI technology.</p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
            <div className=" bg-white p-3 mt-3 custom-card">
              <h3>Health Prognoses for Patients</h3>
              <div className="ctgry">
                <span className="py-1 px-2 disble-btn">Healthcare</span>
              </div>
              <p>With practical predictions based on patient data, ML-based apps can help physicians treat patients in advance and potentially save lives.</p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
            <div className=" bg-white p-3 mt-3 custom-card">
              <h3>Transparent Mirrors for Digital Try-On</h3>
              <div className="ctgry">
                <span className="py-1 px-2 disble-btn">Fashion</span>
                <span className="py-1 px-2 disble-btn">Retail</span>
              </div>
              <p>Smart mirrors use a combination of sensors, displays, and cameras with computer vision to suggest trendy outfits to customers.</p>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 col-12 d-flex justify-content-center">
            <div className=" bg-white p-3 mt-3 custom-card">
              <h3>Engine of Recommendations</h3>
              <div className="ctgry">
                <span className="py-1 px-2 disble-btn">Media</span>
                <span className="py-1 px-2 disble-btn">Shopping</span>
              </div>
              <p>It suggests similar products or services based on the user's past. Just like the suggestions when shopping on Amazon or watching Netflix.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}