import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import PHPB from '../../../assets/mirth-connect-best-hl7-interface-engine 1.jpg'

export default function MedicationAdministration() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Medication Administration Record Exchange Using Mirth HL7</strong></div>
                    <p className='mt-2 text-center'>The client is a web-based service organization that provides an integrated solution for documentation, collaboration, reporting and communication needs of healthcare service providers that supports individuals with developmental disabilities. They are the leader in Web-Based Developmental Disabilities Software for Documentation, Reporting, and Communication in HCBS, Residential, ICFs and other ID/DD Services.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>The Client wanted to design and develop a process to get information from physician’s order sheet, transform this information and insert this information into client defined databases after applying various client defined rules of validation and crosswalks. HL7 Standards were supposed to be followed in building the information exchange processes.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>Mirth Connect Engine, JavaScript, Java, HL7 Messages</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={PHPB} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>Dahooks Designed and Developed multiple HL7 based channels using Mirth Connect to achieve the needs. We developed transformation logic upon inbound ADT01 and RDE011 HL7 messages using Java, JavaScript to grab and insert refined quality data in client defined databases. Based on received messages we also generated daily reports after all processing and mail them to the respective client for further analysis of processed data in an automated manner.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>With the implementation of Mirth HL7 based customized channels, the tedious manual and paper-based works of message submission, coding, validation, tracking etc. became easier and got to higher accuracy and speed. A basic level of regular maintenance efforts was enough in managing the communication channels up-and-running.</p>
                </div>
            </div>
        </div>
    </>
  )
}
