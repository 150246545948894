import React from 'react'
import './Vehicle.css'
import Car2 from '../../../assets/car2.jpg'
import VehicleCar from '../../../assets/car.jpg'

export default function Vehicle() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>.NET Framework based Vehicle Speed Enforcing Application</strong></div>
                    <p className='mt-2 text-center'>The client is a USA based leading global business and IT consulting firm that develops and implements technology solutions. One of the customers of our client offers wide range of offshore speed monitoring service covering wide range of activities including Speed Monitoring of vehicle, Ticket Issuing, Integrations with License Plate Reader/Cameras devices.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>The Client wanted to design and develop an integrated, configurable, multi database support, secured and user friendly platform for effective monitoring of on road vehicles. The major functionalities proposed were Online Speed Alarm Generation, Ticket Issuing and Traffic Monitoring using multiple databases as backend.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>.NET Framework, JQuery, SQL Server, MySql, SQLlite</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={VehicleCar} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We Designed and Developed a multiple database based compliant system leveraging .NET Framework that helped users to see vehicle in a very secured platform. We developed three applications namely, Licence Data Puller Service which fetch number plate reads from third party, Speed Calculator Service which identifies the station of corresponding reads and find out if it is exit or entry read. After that it pair-ups the two reads and calculate the speed and raise alarm if it is over speeding. It also imposes various constraints like threshold on the alarm and stores alarms in database. There is a web application which shows alarms and present to user for ticket generation. This application also shows traffic patterns on the roads.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The SaaS based technology platform has helped concern authorities in managing the traffic with higher effectiveness while minimizing the no-shows and maximizing their revenue. It has not only helped in generating different alarms and ticket but also in managing traffic patterns on different time interval, tracking of hot listed vehicle and report to various concern authorities.</p>
                </div>
            </div>
        </div>
    </>
  )
}
