import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import SalesPro from '../../../assets/salesprocess.jpg'

export default function SalesP() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Sales Process Automation using SuiteCRM for Education Business</strong></div>
                    <p className='mt-2 text-center'>One of the largest Education Companies in India and the only company spread across the entire education ecosystem. From schools to skills; the group empowers over 22 million learners and educators across 34,500+ schools around the world.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>Client needed a better system to keep track of the different services provided by them to schools and other education institutions. As there is a hierarchical structure in their organization, they needed a better security of the personal data. This includes a larger customization of SuiteCRM to meet their business requirements.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>SuiteCRM</p>
                </div>
                <div className='col-lg-6 col-12 order-1 order-lg-2'>
                    <div><img src={SalesPro} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We helped in Sales Automation Process and designed a customized solution on SuiteCRM for them to effectively manage online and offline tutorial products, predictable service tracking offered to the schools with a high level of data personalization and security. It also featured customized Contract and Quote module so that Sales Person does not need to create those manually. The customized SuiteCRM based system also provided them a platform to maintain information about the competitors, schools, products and their respective business models, along with a tracker of the daily activities of their users. The Client was having multiple systems in place for other/different processes and a robust API layer was developed for seamless integration.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The deployment of this software made their organizational activities more transparent and increased their productivity. Different reports are working as a prefect platform to check status of different aspects of their activities. Now they are getting daily mails with daily status updates of their team members. They can easily keep track of activities of their whole team in just one click.</p>
                </div>
            </div>
        </div>
    </>
  )
}
