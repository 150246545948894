import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import PHPB from '../../../assets/NET Framework based.jpg'

export default function NetFrameworkBasedVehicle() {
    return (
        <>
            <div className='container pt-4'>
                <div className='row pt-5'>
                    <div className='col-12 mt-5 pt-5'>
                        <div className='mx-5 heading-text text-center my-3'><strong>.NET Framework based Vehicle Parking Management Application</strong></div>
                        <p className='mt-2 text-center'>The client is a USA based leading global business and IT consulting firm that develops and implements technology solutions. One of the customers of our client offers offshore software development services to Parking Management Services providers covering wide range of management activities including Parking Slot Management, Hot List management and Parking Ticket Issuing.</p>
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                        <div className='Vehicle-text mt-0'>Challenges:</div>
                        <p className='mt-4'>The Client wanted to design and develop an integrated, configurable, multi database support, secured and user friendly platform for effective management of Vehicle Parking. The major functionalities proposed were Parking Alarm Generation, Identification of Hot Listed and White Listed Vehicle, Parking Ticket Issuing using multiple databases (SQL Server, MySQL, SQLlite) and Multiple machine compatible (thick client or thin client).</p>
                        <div className='Vehicle-text'>Technology:</div>
                        <p className='mt-4'>.NET Frame Work, Telerik, AJAX, JQuery, SQL Server, My Sql, SQLlite</p>
                    </div>
                    <div className='col-lg-6 order-1 order-lg-2'>
                        <div><img src={PHPB} width={"100%"} /></div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Solution:</div>
                        <p className='mt-4'>We Designed and Developed a multiple database compliant system leveraging .NET Framework that helped users to see vehicles in a very secured platform. We developed Parking Alarm Management System, Hot List Management System, Traffic History Management Module, Parking Ticket Generation System for Concerned Authorities.</p>
                    </div>
                    <div className='col-12 mb-5'>
                        <div className='Vehicle-text'>Outcome:</div>
                        <p className='mt-4'>The SaaS based technology platform has helped concern authorities in managing the parking lots with higher effectiveness and maximizing their revenue. It has not only helped the in generating different alarms and tickets but also in managing parking lots more effectively.</p>
                    </div>
                </div>
            </div>
        </>
    )
}
