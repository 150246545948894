import React from 'react'
import '../tabs-sec/tabs.css'
import Android from '../../assets/android.png'
import IOS from '../../assets/ios.png'
import ReactImg from '../../assets/react.png'
import Flutter from '../../assets/flutter.png'
import Angular from '../../assets/angular.png'
import Type from '../../assets/type-script.png'
import HTML from '../../assets/html-5.jpg'
import Dynamo from '../../assets/dynmodb.png'
import Mssql from '../../assets/mssql.png'
import Firebase from '../../assets/firebase.png'
import Mongo from '../../assets/mongoDB.png'
import Mysql from '../../assets/mysqldb.png'
import Postgree from '../../assets/pssql.png'
import Dotnet from '../../assets/dotnet.png'
import PHP from '../../assets/php.jpg'
import NodeJs from '../../assets/nodejs.jpg'
import Python from '../../assets/python.jpg'
import Zend from '../../assets/zend.png'
import Wordpress from '../../assets/wordpress.png'
import Appium from '../../assets/appium.png'
import AWS from '../../assets/aws.png'
import GoogleCloud from '../../assets/google cloud.png'
import Gradle from '../../assets/gradle.png'
import Jekins from '../../assets/jenkins.png'
import Selenium from '../../assets/selenium.png'


export default function
    () {
    return (
        <>
            <div className="container px-lg-5">
                <div className='text-center py-md-5 py-4 heading-text'>Our Technologies ToolBox</div>
                <ul className="nav nav-pills mb-3 border-bottom border-2" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-primary fw-semibold active position-relative" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Mobile</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-primary fw-semibold position-relative" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Front End</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-primary fw-semibold position-relative" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Database</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-primary fw-semibold position-relative" id="pills-backend-tab" data-bs-toggle="pill" data-bs-target="#pills-backend" type="button" role="tab" aria-controls="pills-backend" aria-selected="false">Backend</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-primary fw-semibold position-relative" id="pills-CMS-tab" data-bs-toggle="pill" data-bs-target="#pills-CMS" type="button" role="tab" aria-controls="pills-CMS" aria-selected="false">CMS / CRM</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link text-primary fw-semibold position-relative" id="pills-Infra-tab" data-bs-toggle="pill" data-bs-target="#pills-Infra" type="button" role="tab" aria-controls="pills-Infra" aria-selected="false">Infra & Devops</button>
                    </li>
                </ul>
                <div className="tab-content py-md-5 mb-5 mb-md-0" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <div className='row'>
                            <div className=' col-2 mt-3 mt-md-0 offset-2 text-center'>
                                <img className='tab-img' src={Android} />
                                <div className='mt-2 img-text'>Android</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={IOS} />
                                <div className='mt-2 img-text'>IOS</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={ReactImg} />
                                <div className='mt-2 img-text'>React Native</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center mt-3 mt-md-0'>
                                <img className='tab-img' src={Flutter} />
                                <div className='mt-2 img-text'>Flutter</div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <div className='row'>
                            <div className=' col-2 mt-3 mt-md-0 offset-2 text-center'>
                                <img className='tab-img' src={Angular} />
                                <div className='mt-2 img-text'>Angular</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={ReactImg} />
                                <div className='mt-2 img-text'>React Js</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={Type} />
                                <div className='mt-2 img-text'>Type Script</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={HTML} />
                                <div className='mt-2 img-text'>HTML 5</div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <div className='row'>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={Dynamo} />
                                <div className='mt-2 img-text'>Dynamo DB</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={Mssql} />
                                <div className='mt-2 img-text'>MsSQL</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={Firebase} />
                                <div className='mt-2 img-text'>Firebase</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={Mongo} />
                                <div className='mt-2 img-text'>Mongo DB</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img1' src={Mysql} />
                                <div className='mt-2 img-text'>MySQL</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={Postgree} />
                                <div className='mt-2 img-text'>PostgreSQL</div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-backend" role="tabpanel" aria-labelledby="pills-backend-tab">
                        <div className='row'>
                            <div className=' col-2 mt-3 mt-md-0 offset-2 text-center'>
                                <img className='tab-img' src={Dotnet} />
                                <div className='mt-2 img-text'>Dotnet</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={PHP} />
                                <div className='mt-2 img-text'>PHP</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={NodeJs} />
                                <div className='mt-2 img-text'>Node Js</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={Python} />
                                <div className='mt-2 img-text'>Python</div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-CMS" role="tabpanel" aria-labelledby="pills-CMS-tab">
                        <div className='row'>
                            <div className=' col-2 mt-3 mt-md-0 text-center offset-4'>
                                <img className='tab-img' src={Zend} />
                                <div className='mt-2 img-text'>Zend</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={Wordpress} />
                                <div className='mt-2 img-text'>Wordpress</div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-Infra" role="tabpanel" aria-labelledby="pills-Infra-tab">
                        <div className='row'>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={Appium} />
                                <div className='mt-2 img-text'>Appium</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={AWS} />
                                <div className='mt-2 img-text'>AWS</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={GoogleCloud} />
                                <div className='mt-2 img-text'>Google Cloud</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={Gradle} />
                                <div className='mt-2 img-text'>Gradle</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={Jekins} />
                                <div className='mt-2 img-text'>Jekins</div>
                            </div>
                            <div className=' col-2 mt-3 mt-md-0 text-center'>
                                <img className='tab-img' src={Selenium} />
                                <div className='mt-2 img-text'>Selenium</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
