import React, { useEffect } from "react";
import Dicovery_Img from '../../assets/Discovery_Img.jpg'
import Dicovery_Img2 from '../../assets/Discovery_Img2.jpg'
import Understanding from '../../assets/understanding.png'
import Research from '../../assets/research.png'
import Implement from '../../assets/Implement.png'
import Discovery_Img3 from '../../assets/Discovery_Img3.jpg'
import { Helmet } from "react-helmet";

export default function Discovery() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <Helmet>
                <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Discovery Workshop - Dahooks" /><meta property="og:description" content="Discovery Workshop is the pre-development phase of a software development or mobile app development project that involves the client and the project team gathering and exchanging pertinent project information. " /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Discovery Workshop - Dahooks" /><meta name="twitter:description" content="Discovery Workshop is the pre-development phase of a software development or mobile app development project that involves the client and the project team gathering and exchanging pertinent project information. " /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
                <title>Discovery Workshop - Dahooks</title>
                <link rel="canonical" href="https://dahooks.com/Discovery" />
            </Helmet>
            <div className="container py-5">
                <div className="row py-5 mt-5">
                    <div className="col-5">
                        <div className="heading" style={{ fontSize: "40px" }}>
                            <strong>Discovery <br /> Workshop</strong>
                        </div>
                        <div className="para mt-3" style={{ fontSize: "18px" }}>
                            <strong>Forming concepts into solid solutions</strong>
                        </div>
                        <div className="para mt-3" style={{ fontSize: "18px" }}>
                            It all starts with a sincere notion. However, there must be explanations for why not every idea develops into what some could refer to as a "success." Dahooks places utmost attention on a specially created Discovery Workshop specifically for you in order to prevent such tragedies. Tell us more about it, please!
                        </div>
                    </div>
                    <div className="col-7">
                        <div className="img">
                            <img src={Dicovery_Img} width="100%" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container ">
                <div className="row">
                    <div className="col-6">
                        <div className="img">
                            <img src={Dicovery_Img2} width="100%" />
                        </div>
                    </div>
                    <div className="col-6 my-auto">
                        <div className="heading" style={{ fontSize: "40px", textAlign: "center" }}>
                            <strong>The Purpose</strong>
                        </div>
                        <div className="para mt-3" style={{ fontSize: "18px" }}>
                            <strong> "Discovery Workshop's objective is to prepare our clients to supply comprehensive information through brainstorming sessions prior to the start of the development cycle. This will enable us to use cutting-edge technologies to provide a solid, distinctive, and unconventional solution with gorgeous UI/UX design.</strong>
                        </div>
                        <div className="para mt-3" style={{ fontSize: "18px" }}>
                            The main goal is to comprehend client expectations and meet them with our expertise and quality, in addition to communicating project objectives.
                        </div>
                    </div>
                </div>
            </div>
            <div className="background">
                <div className="container py-5 mt-5">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading" style={{ fontSize: "40px", textAlign: "center" }}>
                                <strong>Forefronts of Discovery Workshop</strong>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 py-5">
                            <div className="img d-flex justify-content-center">
                                <img src={Understanding} width="60px" />
                            </div>
                            <div className="heading mt-3" style={{ fontSize: "28px", textAlign: "center" }}>
                                <strong>Understanding</strong>
                            </div>
                            <div className="heading mt-3" style={{ fontSize: "18px", textAlign: "center" }}>
                                Enabling our staff to fully comprehend the objectives, vision, and business of our clients.
                            </div>
                        </div>
                        <div className="col-4 py-5">
                            <div className="img d-flex justify-content-center">
                                <img src={Research} width="60px" />
                            </div>
                            <div className="heading mt-3" style={{ fontSize: "28px", textAlign: "center" }}>
                                <strong>Research</strong>
                            </div>
                            <div className="heading mt-3" style={{ fontSize: "18px", textAlign: "center" }}>
                                Enabling us to explore any topic about which we may have differing opinions and to draw our own conclusions.
                            </div>
                        </div>
                        <div className="col-4 py-5">
                            <div className="img d-flex justify-content-center">
                                <img src={Implement} width="60px" />
                            </div>
                            <div className="heading mt-3" style={{ fontSize: "28px", textAlign: "center" }}>
                                <strong>Implementation</strong>
                            </div>
                            <div className="heading mt-3" style={{ fontSize: "18px", textAlign: "center" }}>
                                Letting the development and UI/UX teams review comprehensive data to ensure faultless deliverables
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div className="row">
                    <div className="col-12">
                        <div className="heading" style={{ fontSize: "40px", textAlign: "center" }}>
                            <strong>letting the development and UI/UX teams review comprehensive data to ensure faultless deliverables</strong>
                        </div>
                        <div className="para mt-3" style={{ fontSize: "18px", textAlign: "center" }}>
                            To put it another way, the Discovery Workshop is the pre-development phase of a software development or mobile app development project that involves the client and the project team gathering and exchanging pertinent project information. Online conferences and in-person meetings could serve as the platform for exchanging knowledge.
                        </div>
                        <div className="img mt-5" >
                            <img src={Discovery_Img3} width="100%" />
                        </div>
                        <div className="para mt-3" style={{ fontSize: "18px", textAlign: "center" }}>
                            A carefully chosen session is a project start-up procedure that helps you refine your concepts, design your needs, and quickly advance your product from concept to advanced development stages.
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-6 ">
                        <div className="card p-3 ">
                            <div className="img p-3 ">
                                <img src={Understanding} width="60px" />
                            </div>
                            <div className="heading p-3 " style={{ fontSize: "18px" }}>
                                <strong>What may a client anticipate from these meetings?</strong>
                            </div>
                            <div className="heading p-3 " style={{ fontSize: "18px" }}>
                                Information on the project's scope, industry issues, strategic analysis, specialized targets, and core vision should be analyzed, identified, and presented.
                            </div>
                        </div>
                    </div>
                    <div className="col-6 ">
                        <div className="card p-3 ">
                            <div className="img p-3">
                                <img src={Understanding} width="60px" />
                            </div>
                            <div className="heading p-3" style={{ fontSize: "18px" }}>
                                <strong>What services may a project team provide?</strong>
                            </div>
                            <div className="heading p-3 " style={{ fontSize: "18px" }}>
                                Examine the topic and business process, spot bottlenecks and offer pertinent fixes, clear the clutter, and build a roadmap.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}