import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import PHPB from '../../../assets/custom healthcare crm.jpg'

export default function CustomHealthcareCRM() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Custom Healthcare CRM With Dental Plan Management Component</strong></div>
                    <p className='mt-2 text-center'>The Client offers a wide range of offshore healthcare application development services covering a wide range of health care activities including Practice Management, Electronic Medical Records, Insurance claims, Financial transactions and Integrations with numerous health care devices. They deliver high-quality offshore software for customized health care applications to health care providers, health care institutes, hospitals, doctors, physicians, clinics and health care entrepreneur communities.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>The Client needed an application for Dental Care to manage Dentist and patient interaction with certain features like plan coverage, enhanced policy covers for the family members, upgrade plans and ability to see the coverage details. They wanted a Doctor Dashboard to get a full overview of their practice, a complete Analytics where doctors can easily access monthly revenue, financed patient lists, missed payments and their new referrals. A Recurring Billing was another feature that the client needed in their application where automatic recurring billing for the premiums and financed procedures were done to the bank account. They were also looking up for Customer relationship management, a system to keep track of patient’s needs, follow-ups, compare private plans to choose the best. Email marketing campaigns were next to that client needed to send the customized campaign to the intended audience.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>PHP, Zend Framework, MySQL, Angular JS, Stripe Payment Gateway</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={PHPB} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We designed and developed a cloud-based Dentist & Patient relationship management system using ZEND Framework. We developed Patient/ Specialist Portal, Doctor Dashboard, Full Analytics, Recurring Billing integration with Stripe payment gateway, added Customer Relationship Management and Email Marketing Management functionalities.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The SaaS-based technology platform has helped the Doctors, Providers or the Clinics in managing the Doctor and Patient Communications with higher effectiveness and maximizing their revenue. It has helped the doctors to get a full overview of everything that is going on with their practices, easily access monthly revenue, financed patient lists, missed payments and their new referrals and patients to view their coverage, upgrade plans or add family members and how much coverage they have used and what they have left etc.</p>
                </div>
            </div>
        </div>
    </>
  )
}
