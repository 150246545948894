import React, { useState, useEffect } from 'react'
import '../contact-us/contact-us.css'
import ContactHero from '../../assets/office.jpg'
import '../customer/customer.css'
import { useLocation } from "react-router-dom";
import 'react-responsive-modal/styles.css';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";

const ContactUs = (props) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const pathname = useLocation().pathname;
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [errr, setErrr] = useState("")
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const onSumbit = () => {
        if (name == '' && email == "" && message == "") {
            setErrr("All field are required!!");
            setTimeout(() => {
                setErrr("");
            }, 3000);
            return false
        }
        const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!email.match(emailReg)) {
            setErrr("Please enter valid email!!");
            setTimeout(() => {
                setErrr("");
            }, 3000);
            return false
        }

        if (message?.length > 500) {
            setErrr("Messahe should be contain 500 charectors !!");
            setTimeout(() => {
                setErrr("");
            }, 3000);
            return false
        }

        axios.post("https://smtp.taction.in/api/api/v2/send-mail", {
            name: name,
            email: email,
            message: message
        }).then(res => {
            if (res && res?.status == 200) {
                toast.success('Your query is Submitted ', {
                    autoClose: 2000,
                });
                setTimeout(() => {
                    setOpen(false);
                    setEmail("");
                    setName("");
                    setMessage("")
                }, 2500);
            }

        }).catch((err) => {
            console.log(err)
        })
        console.log("Contact")
    }
    return (
        <>
            <Helmet>
                <meta property="og:locale" content="en_US" /><meta property="og:type" content="Page" /><meta property="og:title" content="Contact Us | Software Development Company | Dahooks" /><meta property="og:description" content="Contact Us for the best Software Development, Mobile App Development and Web Application Development Services. Reach out to us via contact us form and get a guaranteed response within 24 hours." /><meta property="og:url" content="https://dahooks.com/web-dev" /><meta property="og:site_name" content="DAHOOKS" /><meta property="og:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" /><meta property="og:image:width" content="1500" /><meta property="og:image:height" content="800" /><meta name="twitter:card" content="summary_large_image" /><meta name="twitter:site" content="@dahookstech" /><meta name="twitter:title" content="Contact Us | Software Development Company | Dahooks" /><meta name="twitter:description" content="Contact Us for the best Software Development, Mobile App Development and Web Application Development Services. Reach out to us via contact us form and get a guaranteed response within 24 hours." /><meta name="twitter:image" content="https://dahooks.com/static/media/Dahookslogo.22e49eeb708254417c39.png" />
                <title>Contact Us | Software Development Company | Dahooks</title>
                <link rel="canonical" href="https://dahooks.com/contact-us" />
            </Helmet>
            <ToastContainer style={{ zIndex: 900 }} />
            <div className='contact-us'>
                <div className='container-fluid mb-4 pt-5'>
                    <div className='row pt-5 mt-4'>
                        <div className=' col-12 mt-5 py-4 text-center'>
                            <div className='contact-banner-heading p-lg-4'>We’re just a Conversation away!</div>
                            <div className='p-lg-4 pt-lg-2'>
                                We create a digital masterpiece that yields results by fusing our global perspective with industry and technological understanding.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row background-clr'>
                        <div className='col-lg-6 col-12' style={{ overflow: 'hidden' }}>
                            <div><img src={ContactHero} /></div>
                        </div>
                        <div className='col-lg-6 col-12 my-auto'>
                            <div className='my-auto py-4'>
                                <div className='text-center'>
                                    <div className='state'>Schedule a Meeting with Our Experts</div>
                                    <div>Share a brief about your project and get a guaranteed response within 24 hours.</div>
                                </div>
                                <div className='mt-4 d-flex justify-content-center'>
                                    <form action="/action_page.php" style={{ width: "70%" }}>
                                        <label className="label-text" for="fname"><strong>Name</strong> <sup style={{ color: 'red' }}>*</sup></label>
                                        <input type="text" onChange={(e) => {
                                            setName(e.target.value)
                                        }} value={name} id="fname" name="firstname" placeholder="Enter Your Name.." style={{ borderTop: "none", borderLeft: "none", borderRight: "none", background: "#f5f5f5" }} />

                                        <label className="label-text mt-3" for="lname"><strong>Email</strong><sup style={{ color: 'red' }}>*</sup></label>
                                        <input type="text" onChange={(e) => {
                                            setEmail(e.target.value)
                                        }} value={email} id="lname" name="lastname" placeholder="Enter Your Email.." style={{ borderTop: "none", borderLeft: "none", borderRight: "none", background: "#f5f5f5" }} />
                                        <label className="label-text mt-3 mb-2" for="subject"><strong>Message</strong><sup style={{ color: 'red' }}>*</sup></label>
                                        <textarea onChange={(e) => {
                                            if (e.target.value?.length > 500) {
                                                setErrr("Messahe should be contain 500 charectors !!");
                                                setTimeout(() => {
                                                    setErrr("");
                                                }, 3000);
                                                return false
                                            }
                                            else {
                                                setMessage(e.target.value)
                                            }
                                        }} id="subject" value={message} name="subject" placeholder="Write something.." style={{ height: '100px', borderTop: "none", borderLeft: "none", borderRight: "none", background: "#f5f5f5" }}></textarea>
                                        <div className="text-center mt-3">
                                            <button onClick={onSumbit} type="button" className="btn btn-dark" style={{ width: '100%' }}>Submit</button>
                                        </div>
                                        <div style={{ height: 20, color: "red", fontSize: 12, marginTop: 5 }}>{errr ? errr : ""}</div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='text-center conatct-heading py-5'>Our Offices</div>
                    <div className='row mb-5'>
                        <div className='col-lg-6 col-12 text-center'>
                            <div className='state py-3'>India</div>
                            <div className='address'>A-block  Building no-83  Sector-63<br />Noida  (UP) -201301</div>
                            <div className='address'>+91-7827150289</div>
                        </div>
                        <div className='col-lg-6 col-12 text-center'>
                            <div className='state py-3'>USA</div>
                            <div className='address'>25420, Kyukendahl Road Suite  D800<br />Tomball, Texas United States</div>
                            <div className='address'>+1 302-219-0001</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ContactUs;