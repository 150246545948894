import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import PHPM from '../../../assets/PHP-based.jpg'

export default function PHPbased() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>PHP based Custom CRM for Sales and Inventory Management</strong></div>
                    <p className='mt-2 text-center'>The client is a USA based industry leader in marine wholesale and brokerage. They are into the selling, purchasing and trading of boats as a professional and reputable boat broker.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>The Client wanted to upgrade their application with new cutting edge features which improve the CRM usability and accessibility. With the increasing demand of their boat, it was difficult for them to manage and track the leads and their conversion rate along with maintaining inventory and boat details effectively. The system was also not well designed to support workflows, notification and user hierarchy.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>PHP, ZEND Framework, MySQL</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={PHPM} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We designed and developed a solution based on PHP/ MySQL using Zend Framework (ZF2). We modified a couple of existing modules like Lead, Inventory, Boat etc. appropriately and added the number of new modules to meet the increasing business needs. Further, we developed role-specific dashboards which improved the usability and accessibility of the application.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>The enhanced CRM platform drastically improved the usability and accessibility of the application with focused visibility of the appropriate information to the end users. The solution also helped the client in increasing the revenue and market accessibility.</p>
                </div>
            </div>
        </div>
    </>
  )
}
