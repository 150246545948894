import React from 'react'
import '../Vehicle Speed/Vehicle.css'
import PhotosS from '../../../assets/photos-sharing.jpg'

export default function PhotosSharing() {
  return (
    <>
        <div className='container pt-4'>
            <div className='row pt-5'>
                <div className='col-12 mt-5 pt-5'>
                    <div className='mx-5 heading-text text-center my-3'><strong>Photos Sharing and Rating Application</strong></div>
                    <p className='mt-2 text-center'>Startup based out of USA aiming to disrupt the way people connect with each other on the go sharing their memories on the fly with Images through the Application with their friends and followers.</p>
                </div>
            </div>
            <div className='row mt-5'>
                <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                    <div className='Vehicle-text mt-0'>Challenges:</div>
                    <p className='mt-4'>With a lot of Applications in the market with features of Image sharing and In-application conversations/interactions, there was a need to come up with a solution that is very engaging with excellent and seamless user experience wherein Application Users can share Images captured on the go with their Loved ones and their friends and followers can rate the same in a nice competitive manner to find out most loved and rated Images being shared. A real Fun and Play Application indeed.</p>

                    <div className='Vehicle-text'>Technology:</div>
                    <p className='mt-4'>iOS, Android, PHP ZEND Framework 2, HTML5, Bootstrap</p>
                </div>
                <div className='col-lg-6 order-1 order-lg-2'>
                    <div><img src={PhotosS} width={"100%"}/></div>
                </div>
            </div>
            <div className='row'>
                {/* <div className='col-6'>
                    <img src={Car2} width={"100%"}/>
                </div> */}
                <div className='col-12'>
                    <div className='Vehicle-text'>Solution:</div>
                    <p className='mt-4'>We designed and developed iOS and Android Applications to achieve the said objective, enabling the Users to capture Images on the go, share the same in real time, all this facilitated by high performance light weight Mobile applications powered by robust backend and efficient web services made in latest PHP framework to actually achieve real-time and lag free performance.</p>
                </div>
                <div className='col-12 mb-5'>
                    <div className='Vehicle-text'>Outcome:</div>
                    <p className='mt-4'>Awarded Application LIVE on Playstore and Appstore with active downloads every day.</p>
                </div>
            </div>
        </div>
    </>
  )
}
