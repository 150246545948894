import React, { useEffect } from 'react'
import '../our-clients/our-clients.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function CoreService() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
      };
      var settings1 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
      };
      var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
      };
  return (
    <>
    <div className='container pb-5'>
        <div className='row text-center'>
            <div className='heading-text pt-5'>Check out what our clients are saying about us</div>
            <div className='pt-2 pb-5'>Our clients help and strengthen our mission every day with their positive affirmations</div>
        </div>
        <div className='row d-none d-xl-block'>
            <Slider {...settings}>
            <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services  text-center" style={{width: "18rem"}}>
                        <div className="card-body">
                            <div className="card-title pt-3">What people say..</div>
                            <div className="card-text pt-2">“Taction Software’s flexibility has been unparalleled. We received great value from what we spent on the website.”</div>      
                            <div className='pt-3'>-Matt collins</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "18rem"}}>
                        <div className="card-body">
                            <div className="card-title pt-3">What people say..</div>
                            <div className="card-text pt-2">“Taction stood out as unique. Even though they were confident in their skills, they weren’t brash or forceful in terms of communication, which translated into huge benefits when it came to interacting with the technology groups of other clients.”</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "18rem"}}>
                        <div className="card-body">
                            <div className="card-title pt-3">What people say..</div>
                            <div className="card-text pt-2">“Taction Software is very good at setting timeframes for different tasks and remaining accurate to them. I shoot requests quite regularly and they have handled them professionally.”</div>      
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "18rem"}}>
                        <div className="card-body">
                            <div className="card-title pt-3">Clutch Verified Client Reviews</div>
                            <div className="card-text pt-2">“I always felt that they cared about the business more than they cared about their own pocketbooks.”</div>
                            <div>Clutch Verified Client</div>
                            <div className='pt-3'> -Project Manager, Consulting Firm</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "18rem"}}>
                        <div className="card-body">
                            <div className="card-title pt-3">Clutch Verified Client Reviews</div>
                            <div className="card-text pt-2">“I never felt that I was being rushed along.”</div>
                            <div >Clutch Verified Client</div>
                            <div className=' pt-3 '>- CEO, Nonprofit</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "18rem"}}>
                        <div className="card-body">
                            <div className="card-title pt-3">Clutch Verified Client Reviews</div>
                            <div className="card-text pt-2">“There aren’t many offshore firms with which I’ve had such a seamless experience.”</div>
                            <div >Clutch Verified Client</div>
                            <div className='pt-3'>- Matt Konig, Chief Operating Officer</div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
        <div className='row d-none  d-md-block d-xl-none'>
            <Slider {...settings1}>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "15rem"}}>
                        {/* <img src={Hire} className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                            <div className="card-title pt-3">What people say..</div>
                            <div className="card-text pt-2">“Taction Software’s flexibility has been unparalleled. We received great value from what we spent on the website.”</div>
                            <div className='pt-3'>-Matt collins</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "15rem"}}>
                        {/* <img src={Development} className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                            <div className="card-title pt-3">What people say..</div>
                            <div className="card-text pt-2">“Taction stood out as unique. Even though they were confident in their skills, they weren’t brash or forceful in terms of communication, which translated into huge benefits when it came to interacting with the technology groups of other clients.”</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "15rem"}}>
                        {/* <img src={Mobile} className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                            <div className="card-title pt-3">What people say..</div>
                            <div className="card-text pt-2">“Taction Software is very good at setting timeframes for different tasks and remaining accurate to them. I shoot requests quite regularly and they have handled them professionally.”</div>
                            <div className=''>Taction</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "15rem"}}>
                        {/* <img src={UiUx} className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                            <div className="card-title pt-3">Clutch Verified Client Reviews</div>
                            <div className="card-text pt-2">“I always felt that they cared about the business more than they cared about their own pocketbooks.”</div>
                            <div >Clutch Verified Client</div>
                            <div className='pt-3'> -Project Manager, Consulting Firm</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "15rem"}}>
                        {/* <img src={Hire} className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                            <div className="card-title pt-3">Clutch Verified Client Reviews</div>
                            <div className="card-text pt-2">“I never felt that I was being rushed along.”</div>
                            <div >Clutch Verified Client</div>
                            <div className=' pt-3 '>- CEO, Nonprofit</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "15rem"}}>
                        {/* <img src={Development} className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                            <div className="card-title pt-3">Clutch Verified Client Reviews</div>
                            <div className="card-text pt-2">“There aren’t many offshore firms with which I’ve had such a seamless experience.”</div>
                            <div >Clutch Verified Client</div>
                            <div className='pt-3'>- Matt Konig, Chief Operating Officer</div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
        <div className='d-block d-md-none d-lg-none'>
            <Slider {...settings2}>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "18rem"}}>
                        {/* <img src={Hire} className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                            <div className="card-title pt-3">What people say..</div>
                            <div className="card-text pt-2">“Taction Software’s flexibility has been unparalleled. We received great value from what we spent on the website.”</div>
                            <div className='pt-3'>-Matt collins</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "18rem"}}>
                        {/* <img src={Development} className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                            <div className="card-title pt-3">What people say..</div>
                            <div className="card-text pt-2">“Taction stood out as unique. Even though they were confident in their skills, they weren’t brash or forceful in terms of communication, which translated into huge benefits when it came to interacting with the technology groups of other clients.”</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "18rem"}}>
                        {/* <img src={Mobile} className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                            <div className="card-title pt-3">What people say..</div>
                            <div className="card-text pt-2">“Taction Software is very good at setting timeframes for different tasks and remaining accurate to them. I shoot requests quite regularly and they have handled them professionally.”</div>
                            <div className=''style={{paddingTop:"52px"}}>Taction</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "18rem"}}>
                        {/* <img src={UiUx} className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                            <div className="card-title pt-3">Clutch Verified Client Reviews</div>
                            <div className="card-text pt-2">“I always felt that they cared about the business more than they cared about their own pocketbooks.”</div>
                            <div>Clutch Verified Client</div>
                            <div className='pt-3'> -Project Manager, Consulting Firm</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "18rem"}}>
                        {/* <img src={Hire} className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                            <div className="card-title pt-3">Clutch Verified Client Reviews</div>
                            <div className="card-text pt-2">“I never felt that I was being rushed along.”</div>
                            <div >Clutch Verified Client</div>
                            <div className=' pt-3 '>- CEO, Nonprofit</div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-3 d-flex justify-content-center'>
                    <div className="card core-services text-center" style={{width: "18rem"}}>
                        {/* <img src={Development} className="card-img-top" alt="..."/> */}
                        <div className="card-body">
                            <div className="card-title pt-3">Clutch Verified Client Reviews</div>
                            <div className="card-text pt-2">“There aren’t many offshore firms with which I’ve had such a seamless experience.”</div>
                            <div >Clutch Verified Client</div>
                            <div className='pt-3'>- Matt Konig, Chief Operating Officer</div>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    </div>
    </>
  )
}