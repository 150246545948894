import React from 'react';
import { Link } from 'react-router-dom';
import DNI_inside from '../../../assets/DNI_inside.jpg'

export default function DNICaseStudy() {
    return (
        <>
            <div className='container pt-4'>
                <div className='row pt-5'>
                    <div className='col-12 mt-5 pt-5'>
                        <div className='mx-5 heading-text text-center my-3'><strong>DNI (Dietary and Nutrition Information) Platform for Dietitians</strong></div>
                        <p className='mt-2 text-center'>DNI is an innovative platform designed for dietitians to stay updated on the nutritional implications of medications, offering comprehensive, expertly curated information to enhance patient care.</p>
                    </div>
                </div>

                {/* Problem Statement */}
                <div className='row mt-5'>
                    <div className='col-lg-6 col-12 order-2 order-lg-1 mt-4 mt-lg-0'>
                        <div className='Vehicle-text mt-0'>Challenges:</div>
                        <p className='mt-4'>Dietitians face an overwhelming task of keeping up with the rapid changes in medications and their impact on nutrition. Access to reliable, updated, and relevant drug information is crucial for providing accurate dietary guidance and improving patient outcomes.</p>
                        <div className='Vehicle-text'>Technology:</div>
                        <p className='mt-4'>React JS, Node JS, MySQL</p>
                    </div>
                    <div className='col-lg-6 order-1 order-lg-2'>
                        <div><img src={DNI_inside} width={"100%"} alt="DNI" /></div>
                    </div>
                </div>

                {/* Solution Overview */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Solution:</div>
                        <p className='mt-4'>Taction Software developed the DNI platform, enabling dietitians to access expertly curated and updated information on medications and their nutritional effects. The platform simplifies the process of staying informed about drug-nutrition interactions, empowering dietitians to make better decisions in patient care.</p>
                    </div>
                </div>

                {/* Key Features and Functionalities */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Key Features and Functionalities:</div>
                        <div className='mt-4'>
                            The DNI platform offers the following key features to dietitians:
                            <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                                <li><strong>Comprehensive Database:</strong> A vast repository of drug formulations, dosages, and their nutritional implications.</li>
                                <li><strong>Peer-Reviewed Articles:</strong> Articles from leading journals on the latest research in medications and nutrition.</li>
                                <li><strong>Expert Insights:</strong> Summaries from leading dietitians simplifying complex research for easy consumption.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Outcome and Impact */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Outcome and Impact:</div>
                        <p className='mt-4'>
                            DNI's pilot program showed significant success in improving dietitians' accuracy and efficiency. 95% of participants reported enhanced understanding of medication interactions with nutrition, while 92% experienced increased efficiency in patient care. Additionally, 85% noted better collaboration with peers and other healthcare professionals, leading to improved patient outcomes.
                        </p>
                    </div>
                </div>

                {/* Evaluation and Impact */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='Vehicle-text'>Evaluation and Impact:</div>
                        <p className='mt-4'>
                            DNI has become a critical tool in the hands of dietitians, allowing them to provide better patient care by having access to reliable, real-time drug and nutrition data. The platform has helped reduce medication-related dietary errors, improve collaboration among healthcare professionals, and significantly boost the overall quality of patient nutrition plans.
                        </p>
                    </div>
                </div>

                {/* Conclusion */}
                <div className='row'>
                    <div className='col-12 mb-5'>
                        <div className='Vehicle-text'>Conclusion:</div>
                        <p className='mt-4'>
                            The DNI platform, developed by Taction Software, has revolutionized how dietitians access and utilize drug-related nutrition information. By offering comprehensive, peer-reviewed insights and real-time updates, DNI has significantly improved the efficiency, collaboration, and overall patient outcomes in the dietetics field.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
